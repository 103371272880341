import React, { Component } from "react"
import { Card, CardBody, Col, Row } from "reactstrap"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import NumberFormat from "../../components/NumberFormat"
import UICardViewBuyCartOrder from "./UICardViewBuyCartOrder"
import ModalAddressDeliveryFactory from "./ModalAddressDeliveryFactory"
import arrow_right from "../../assets/img/right-arrow.png"
import ticket from "../../assets/img/tickets.png"
import ic_location from "../../assets/img/address.png"
import { checkInStorageCart, createCartOrUpdate, getInStorageCart } from "logic/storageAPI"
import { CART_STORAGE_COUPOM_LOCAL, CART_STORAGE_SERVICE_LOCAL } from "logic/keyStorageAPI"

class UIBuyCartViewOrder extends Component {
    constructor(props) {
        super(props)
        this.state = {
            rows: [],
            coupomDiscount: null,
            oPenAddressEditFactoryModal: false,
            modal: false,
            rateDelivery: 0,
            discountValue: 0,
            valueTot: 0,
            valueSubTot: 0
        }

        this.handleSumValueItems = this.handleSumValueItems.bind(this)
        this.handleRemoveProd = this.handleRemoveProd.bind(this)
        this.toggleAddressEditfactorymodal.bind(this)

    }

    componentDidMount() {
        if (checkInStorageCart(CART_STORAGE_SERVICE_LOCAL.intent)) {
            this.handleSumValueItems()
        }

        if (checkInStorageCart(CART_STORAGE_COUPOM_LOCAL.intent)) {
            const { coupomDiscount } = getInStorageCart(CART_STORAGE_COUPOM_LOCAL.intent, true)
            this.setState({ coupomDiscount: coupomDiscount })
            this.handleSumValueItems()
        }

        window.addEventListener('storage', () => {
            if (checkInStorageCart(CART_STORAGE_COUPOM_LOCAL.intent)) {
                const { coupomDiscount } = getInStorageCart(CART_STORAGE_COUPOM_LOCAL.intent, true)
                this.setState({ coupomDiscount: coupomDiscount })
            }
            if (checkInStorageCart(CART_STORAGE_SERVICE_LOCAL.intent)) {
                this.handleSumValueItems()
            }
        })

    }

    toggleAddressEditfactorymodal = () => {
        this.setState(prevState => ({
            oPenAddressEditFactoryModal: !prevState.oPenAddressEditFactoryModal,
        }))
    }

    handleRemoveProd(idx1, idx2) {
        if (checkInStorageCart(CART_STORAGE_SERVICE_LOCAL.intent)) {
            const rows = getInStorageCart(CART_STORAGE_SERVICE_LOCAL.intent, true)
            rows.map((item, index) => {
                if (index === idx1) {
                    item.items.splice(idx2, 1)
                    if (!item.items.length > 0) {
                        rows.splice(index, 1)
                    }
                }
            })
            createCartOrUpdate(rows)
        }

    }

    handleSumValueItems() {
        if (checkInStorageCart(CART_STORAGE_SERVICE_LOCAL.intent)) {
            const rows = getInStorageCart(CART_STORAGE_SERVICE_LOCAL.intent, true)
            let valueTot = 0.0
            rows.map((item, index) => {
                item.items.forEach(element => {

                    valueTot += (parseInt(element.qtd) * parseFloat(element.price))

                    if (item.type === 1) {
                        if (element.edge.price > 0.0) {
                            valueTot += parseFloat(element.edge.price)
                        }
                    }

                })
                //valueTot *= item.qtd;

            })
            let discount = 0.0
            if (checkInStorageCart(CART_STORAGE_COUPOM_LOCAL.intent)) {
                const { coupomDiscount } = getInStorageCart(CART_STORAGE_COUPOM_LOCAL.intent, true)
                const { percentage } = coupomDiscount// this.props.coupomDiscount
                discount = (percentage / 100 * valueTot)
            }
            if (parseInt(this.props.typeDelivery) === 1) {
                this.setState({ valueTot: ((valueTot - discount) + this.props.fee_delivery.price), valueSubTot: valueTot, discountValue: discount })
            }
            else {
                this.setState({ valueTot: (valueTot - discount), valueSubTot: valueTot, discountValue: discount })
            }

            //this.props.fee_delivery.price

        }
    }

    render() {

        return (
            <Col sm={12}>
                <Row>
                    {/*<Col sm={12}>
                        <div className="text-start mb-1">
                            <a onClick={this.props.previousPage}>
                                <b>
                                    <h4 className="card-title font-bl-12-400h text-danger">
                                        <i className="mdi mdi-arrow-left mdi-16px"/> Voltar
                                    </h4>
                                </b>
                            </a>
                        </div>
                    </Col>*/}
                    <Col sm={12}>
                        <div className="text-center  mb-2">
                            <h4 className="card-title">Carrinho de Compra</h4>
                            <small>em Pizzaria Fontanella</small>
                        </div>
                    </Col>
                    {
                        /* this.props.deliveryAddress && parseInt(this.props.typeDelivery) === 1 &&
                         <Col sm={12}>
                             <AddressDeliveryDropdown
                                 modal={() => this.toggleAddressEditfactorymodal()}
                                 deliveryAddress={this.props.deliveryAddress} />
                         </Col>*/

                    }
                    {/*<Col sm={12} className="bg-gray-light mb-2">
                            <div className="text-start  mb-1">
                                <AddressDeliveryDropdown deliveryAddress={this.props.deliveryAddress}/>
                                <a onClick={() => this.toggleAddressEditfactorymodal()}>
                                    <h4 className="font-bl-13-700h text-start">
                                        <i className="mdi mdi-lead-pencil mdi-16px"/>
                                            Endereço de entrega
                                    </h4>
                                </a>
                                <small>{this.props.deliveryAddress.address}</small><br />
                                <small>Nº {this.props.deliveryAddress.number} - Compl.: {this.props.deliveryAddress.complement}</small>
                            </div>
                        </Col>*/}
                    {/*<Col sm={12}>
                        <div className="text-end  mb-2">
                            <small>Produtos ({this.props.rows.length})</small>
                        </div>
                    </Col>*/}
                    <Col sm={12}>
                        <Row>
                            {
                                this.props.rows.map((item, index) => {
                                    return (<UICardViewBuyCartOrder
                                        key={index}
                                        index={index}
                                        qtd={item.qtd}
                                        type={item.type}
                                        rows={item.items}
                                        groupName={item.groupName}
                                        handleRemoveProd={this.handleRemoveProd}
                                    />)
                                })
                            }
                        </Row>
                    </Col>
                </Row>

                <Card className=" bottom-0 end-0">
                    <div className="border-2 border-dark">
                        <a id="coupom-discount-card-cart" name="coupom-discount-card-cart" onClick={() => {
                            window.dataLayer.push({ event: 'coupom-discount-card-cart' });
                            this.props.nextPage()
                        }}>
                            <div className="border-2 card-horizontal">
                                <div className="img-square-wrapper">
                                    <img className="mt-4" src={ticket} alt=" " />
                                </div>
                                <CardBody>
                                    <p className="card-title">
                                        <small>Cupom de Desconto</small>
                                        {
                                            this.state.coupomDiscount &&
                                            <a onClick={() => {
                                                if (window.localStorage.hasOwnProperty('coupom')) {
                                                    window.localStorage.removeItem('coupom')
                                                    this.setState({ coupomDiscount: null })

                                                }
                                                this.handleSumValueItems()
                                            }}>

                                                <small className="font-bl-13-700h  text-danger px-3">
                                                    <i className="mdi mdi-trash-can mdi-16px" />
                                                    Remover
                                                </small>
                                            </a>
                                        }
                                    </p>
                                    <p className="card-text font-bl-12-400h">{this.state.coupomDiscount != null ? this.state.coupomDiscount.title : 'Clique aqui para inserir um cupom'}</p>
                                </CardBody>
                                <div className="img-square-wrapper">
                                    <img className="mt-4" src={arrow_right} alt=" " />
                                </div>
                            </div>
                        </a>
                    </div>

                    {
                        this.props.deliveryAddress && parseInt(this.props.typeDelivery) === 1 &&
                        <div className="border-2 border-dark">
                            <a id="delivery-address-card-cart" name="delivery-address-card-cart" onClick={() => {
                                window.dataLayer.push({ event: 'delivery-address-card-cart' });
                                this.props.nextPageSelected(2)
                            }}>
                                <div className="border-2 card-horizontal">
                                    <div className="img-square-wrapper">
                                        <img className="mt-4" src={ic_location} alt=" " />
                                    </div>
                                    <CardBody>
                                        <p className="card-title">
                                            <small>Endereço de entrega</small>

                                            <a onClick={() => {
                                                /* if (window.localStorage.hasOwnProperty('coupom')) {
                                                     window.localStorage.removeItem('coupom')
                                                     this.setState({ coupomDiscount: null })
 
                                                 }
                                                 this.handleSumValueItems()*/
                                            }}>

                                                <small className="font-bl-13-700h  text-danger px-3">
                                                    <i className="mdi mdi-trash-can mdi-16px" />
                                                    Remover
                                                </small>
                                            </a>

                                        </p>
                                        <p className="card-text font-bl-12-400h">{this.props.deliveryAddress != null ? this.props.deliveryAddress.address : 'Clique aqui para inserir um endereço'}</p>
                                    </CardBody>
                                    <div className="img-square-wrapper">
                                        <img className="mt-4" src={arrow_right} alt=" " />
                                    </div>
                                </div>
                            </a>
                        </div>
                    }

                    <Row className="bg-gray-light mb-1 mt-2">

                        {
                            this.state.coupomDiscount &&
                            <Col sm={12}>
                                <Row>
                                    <Col className="text-start">
                                        <p className="font-bl-13-700h text-start">Cupom Desconto</p>
                                    </Col>
                                    <Col className="text-end">
                                        <p className="font-bl-12-400h text-end ">{this.state.coupomDiscount.percentage}%</p>
                                    </Col>
                                </Row>
                            </Col>

                        }
                        <Col sm={12}>
                            <Row>
                                <Col className="text-start">
                                    <p className="font-bl-13-700h text-start">Subtotal</p>
                                </Col>
                                <Col className="text-end">
                                    <p className="font-bl-12-400h text-end ">
                                        {<NumberFormat value={this.state.valueSubTot} />}
                                    </p>
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={12}>
                            <Row>
                                <Col className="text-start">
                                    <p className="font-bl-13-700h text-start">Descontos</p>
                                </Col>
                                <Col className="text-end">
                                    <p className="font-bl-12-400h text-end ">
                                        {<NumberFormat value={this.state.discountValue} />}
                                    </p>
                                </Col>
                            </Row>
                        </Col>

                        {
                            parseInt(this.props.typeDelivery) === 1 &&
                            <Col sm={12}>
                                <Row>
                                    <Col className="text-start">
                                        <p className="font-bl-13-700h text-start">Taxa de entrega</p>
                                    </Col>
                                    <Col className="text-end">
                                        <p className="font-bl-12-400h text-end ">{<NumberFormat
                                            value={this.props.fee_delivery.price} />}</p>
                                    </Col>
                                </Row>
                            </Col>
                        }


                        <Col>
                            <p className="card-title display-4 text-start"><small>Total</small></p>
                        </Col>
                        <Col>
                            <p className="card-title display-4 text-end"><small>{<NumberFormat
                                value={this.state.valueTot} />}</small></p>
                        </Col>
                    </Row>

                    <a id={this.props.createOrder ? 'finalize-order' : 'inform-payment'}
                        name={this.props.createOrder ? 'finalize-order' : 'inform-payment'}
                        onClick={() => {
                            window.dataLayer.push({ event: this.props.createOrder ? 'finalize-order' : 'inform-payment' });
                            this.props.openPaymentoMethodsPage();
                            this.props.funcParamTotalization({
                                rateDelivery: this.state.rateDelivery,
                                discountValue: this.state.discountValue,
                                valueTot: this.state.valueTot,
                                valueSubTot: this.state.valueSubTot,
                            });
                        }}
                        className="btn btn-lg btn-block btn-danger ">
                        <h4 className="font-bl-13-700h text-center pt-1 text-light">{this.props.createOrder ? 'Finalizar Pedido' : 'Informar Pagamento'} </h4>
                    </a>
                </Card>

                <ModalAddressDeliveryFactory
                    params={{}}
                    data={this.props.deliveryAddress}
                    oPenAddressEditFactoryModal={this.state.oPenAddressEditFactoryModal}
                    toggleModal={this.toggleAddressEditfactorymodal}
                />
            </Col>
        )
    }
}

UIBuyCartViewOrder.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
    refresh: PropTypes.any,
    loading: PropTypes.any,
    getParamsModal: PropTypes.func,
    nextPageSelected: PropTypes.func,
    rows: PropTypes.array,
    previousPage: PropTypes.func,
    nextPage: PropTypes.func,
    openPaymentoMethodsPage: PropTypes.func,
    deliveryAddress: PropTypes.array,
    fee_delivery: PropTypes.any,
    typeDelivery: PropTypes.array,
    coupomDiscount: PropTypes.object,
    createOrder: PropTypes.bool,
    funcParamTotalization: PropTypes.func


}
const mapStateToProps = state => {
    return {}
}
export default withTranslation()(withRouter(connect(mapStateToProps, {})(
    (UIBuyCartViewOrder)
)))
