import React from "react"
import { Button, Col, Input, Modal, Row } from "reactstrap"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { createOrUpdateOrder, apiFindProductsByCategoryAndSizeError, findProductsByCategoryAndSize } from "../../../store/actions"
import NumberFormat from "../../../components/NumberFormat"
import PerfectScrollbar from "react-perfect-scrollbar"
import "react-perfect-scrollbar/dist/css/styles.css"
import UILoadingAnim from "../../Mobile/Components/UILoadingAnim"
import toast from "react-hot-toast"
import { Stack } from "react-bootstrap"
import ImageURI from "components/ImageURI"
import slugify from 'react-slugify';
import { checkInStorageCart, createCart, createCartOrUpdate, createCartWithData, getInStorageCart, getInStorageCartNot64 } from "logic/storageAPI"
import { ACTION_ADD_OR_UPDATE_PRODUCT_ORDER, CART_STORAGE_SERVICE_CACHE_API, CART_STORAGE_SERVICE_LOCAL } from "logic/keyStorageAPI"

/**
 * Modal para montagem do pedido, pizzas.
 */
class ModalContentType1 extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            qtd: 1,
            observation: '',
            refresh: false,
            valueTot: 0.0,
            product_selected: false,
            edge_selected: false,
            pasta_selected: false,

        }

        this.handleSub = this.handleSub.bind(this)
        this.handleAdd = this.handleAdd.bind(this)
        this.handleRadioButtomProducts = this.handleRadioButtomProducts.bind(this)
        this.handleRadioButtomEdges = this.handleRadioButtomEdges.bind(this)
        this.handleRadioButtomPastas = this.handleRadioButtomPastas.bind(this)
        this.handleFields = this.handleFields.bind(this)
        this.handleSumValueItems = this.handleSumValueItems.bind(this)
        this.handleAddToCart = this.handleAddToCart.bind(this)
        // this.handleValidatorFields = this.handleValidatorFields(this)

    }

    componentDidMount() {
        this.props.findProductsByCategoryAndSize({
            size_id: this.props.paramsBody.id,
            type: this.props.paramsBody.prod_type,
            category_id: this.props.paramsBody.idGroup
        })

    }

    handleValidatorFields() {
        const { product_selected, edge_selected, pasta_selected } = this.state;
        let validator = true;

        if (!product_selected) {
            toast.error('Escolha o sabor')
            validator = false
        }
        if (!edge_selected) {
            toast.error('Escolha o tipo borda')
            validator = false
        }
        if (!pasta_selected) {
            toast.error('Escolha o tipo de massa')
            validator = false
        }
        return validator
    }

    handleAddToCart() {
        if (this.handleValidatorFields()) {

            const { edges, products, pastas } = this.props
            let cart = (checkInStorageCart(CART_STORAGE_SERVICE_LOCAL.intent) ? getInStorageCart(CART_STORAGE_SERVICE_LOCAL.intent, true) : [])

            let cart_obg = {}
            if (cart.length > 0) {
                cart.forEach(group => {
                    if (parseInt(group.idGroup) === parseInt(this.props.paramsBody.idGroup)) {
                        cart_obg = group
                    }
                })
            }
            if (Object.keys(cart_obg).length === 0) {
                cart_obg = {
                    id: this.props.paramsBody.id,
                    idGroup: this.props.paramsBody.idGroup,
                    prod_type: this.props.paramsBody.prod_type,
                    groupName: this.props.paramsBody.nameGroup,
                    qtd: this.state.qtd,
                    type: 1,
                    items: [],
                    observation: this.state.observation
                }
            }

            let size_choosed = {
                id: this.props.paramsBody.id,
                name: this.props.paramsBody.name,
                price: this.props.paramsBody.price
            }
            products.forEach(prod => {
                if (prod.isSelected && prod.price > 0.0) {

                    let edge_selected = {}
                    edges.forEach(edge => {
                        if (edge.isSelected) {
                            edge_selected = {
                                id: edge.id,
                                name: edge.name,
                                price: edge.price
                            }


                        }
                    })

                    let pasta_selected = {}
                    pastas.forEach(pasta => {
                        if (pasta.isSelected) {
                            pasta_selected = {
                                id: pasta.id,
                                name: pasta.name,
                                price: pasta.price
                            }
                        }
                    })

                    cart_obg.items.push({
                        id: prod.id,
                        name: this.props.paramsBody.title,
                        description: `${this.state.qtd}x ${prod.name}`,
                        qtd: this.state.qtd,
                        prod_type: this.props.paramsBody.prod_type,
                        price: prod.price,
                        edge: edge_selected,
                        pasta: pasta_selected,
                        size: size_choosed
                        //total: (parseInt(product.qtd) * parseFloat(product.price))
                    })

                }
            })

            cart_obg.qtd = this.state.qtd
            let searchable_group_cart = false
            if (cart.length > 0) {
                cart.map((group, index) => {
                    if (parseInt(group.idGroup) === parseInt(this.props.paramsBody.idGroup)) {
                        cart[index] = cart_obg
                        searchable_group_cart = true
                        return
                    }
                })

            }
            if (!searchable_group_cart) {
                cart.push(cart_obg)
            }

            // atualiza o carrinho na sessão do servidor.
            if (checkInStorageCart(CART_STORAGE_SERVICE_CACHE_API.intent)) {
                createCartWithData(this.props, {
                    ACTION_FACTORY: ACTION_ADD_OR_UPDATE_PRODUCT_ORDER.intent,
                    key: getInStorageCartNot64(CART_STORAGE_SERVICE_CACHE_API.intent),
                    product: cart_obg
                })
            }

            createCartOrUpdate(cart)
            this.props.toggleModal()
        }
    }

    handleSumValueItems() {
        const { edges, products, pastas } = this.props
        const { qtd } = this.state


        let valueTot = 0.0
        products.forEach(element => {
            if (element.isSelected && element.price > 0.0) {
                valueTot += parseFloat(element.price)
            }
        })
        edges.forEach(element => {
            if (element.isSelected && element.price > 0.0) {
                valueTot += parseFloat(element.price)
            }
        })

        pastas.forEach(element => {
            if (element.isSelected && element.price > 0.0) {
                valueTot += parseFloat(element.price)
            }
        })


        valueTot *= qtd

        this.setState({ valueTot: valueTot, refresh: true })

    }

    handleFields(event) {

        const { name, value } = event.target

        this.setState({ observation: value })
    }

    handleAdd(e, type) {

        this.setState({ qtd: (this.state.qtd + 1), refresh: true })
        //this.handleSumValueItems();

    }

    handleSub(e, type) {

        if (this.state.qtd > 1) {
            this.setState({ qtd: (this.state.qtd - 1), refresh: true })
        } else {
            this.setState({ qtd: 1, refresh: true })
        }
        //   this.handleSumValueItems();
    }

    handleRadioButtomProducts(event) {
        this.props.products.forEach(element => {
            element.isSelected = false
        })

        this.props.products[parseInt(event.target.value)]['isSelected'] = !this.props.products[parseInt(event.target.value)]['isSelected']
        this.handleSumValueItems()
        this.setState({ refresh: true, product_selected: !this.state.product_selected })

    }

    handleRadioButtomEdges(event) {
        this.props.edges.forEach(element => {
            element.isSelected = false
        })

        this.props.edges[parseInt(event.target.value)]['isSelected'] = !this.props.edges[parseInt(event.target.value)]['isSelected']
        // this.handleSumValueItems();
        this.setState({ refresh: true, edge_selected: !this.state.edge_selected })
    }

    handleRadioButtomPastas(event) {
        this.props.pastas.forEach(element => {
            element.isSelected = false
        })

        this.props.pastas[parseInt(event.target.value)]['isSelected'] = !this.props.pastas[parseInt(event.target.value)]['isSelected']
        //this.handleSumValueItems();
        this.setState({ refresh: true, pasta_selected: !this.state.pasta_selected })
    }

    render() {
        const { valueTot, refresh } = this.state
        if (refresh) {
            this.handleSumValueItems()
            this.setState({ refresh: false })
        }


        return (
            <>
                {
                    this.props.loading
                        ?
                        <div style={{
                            height: '700px !important',
                            marginBottom: '400px'

                        }}>
                            <UILoadingAnim
                                url_anim={7} />
                        </div>
                        :
                        <>
                            <PerfectScrollbar style={{ height: this.props.size_height }}>
                                <div className="mx-3">
                                    {/* <div className="img-square-wrapper mb-2 text-center">
                                        <img className="rounded thumbnail img-responsive img-card-desc"
                                             src={this.props.paramsBody.url_image ? this.props.paramsBody.url_image : PlaceHolder}
                                             alt=" "/>
                                    </div>*/}
                                    {
                                        (typeof this.props.paramsBody.description !== 'undefined') &&
                                        <>

                                            <h5 className=" text-start card-title text-muted">{this.props.paramsBody.nameGroup}</h5>
                                            <h5 className=" text-start card-title font-got-blk-14-400h text-dark mt-2">{this.props.paramsBody.title}</h5>
                                            <small>{this.props.paramsBody.description}</small> <br />
                                        </>
                                    }
                                    {/*<span className="text-dark">{<NumberFormat value={this.props.params.price}/>}</span>*/}
                                </div>

                                {
                                    this.props.products && this.props.products.length > 0 &&
                                    <section id="products">
                                        <Row className="bg-gray-light mt-2">
                                            <Col sm={6}>
                                                <h4 className="card-title font-bl-13-700h">Escolha um sabor</h4>
                                                <small>Escolha 1 Opção</small>
                                            </Col>
                                            <Col sm={6} className="text-end pr-2">
                                                <span className="badge bg-dark mt-2 px-2">OBRIGATÓRIO</span>
                                            </Col>
                                        </Row>
                                        <div className="modal-body">

                                            <Row key={1} onChange={this.handleRadioButtomProducts}>
                                                {

                                                    this.props.products.map((item, index) => {
                                                        return (
                                                            <>
                                                                <Col key={index}>
                                                                    <Stack direction="horizontal" gap={3}>
                                                                        <ImageURI
                                                                            style_div="img-square-wrapper"
                                                                            style_img="img_prod_list_type_1 p-2 rounded"
                                                                            url={item.url_image} alt={item.name} />
                                                                        <div className="w-75">
                                                                            <Stack gap={3}>
                                                                                <div><h4 className=" text-start font-got-lt-16-400h fw-bold">{item.name}</h4></div>
                                                                                <div><span className=" text-start font-got-lt-11-300h fw-bold">{item.description}</span></div>
                                                                                <div><p className="card-text text-start font-got-blk-14-400h text-success fw-bold">
                                                                                    {
                                                                                        item.price > 0.0 &&
                                                                                        <>
                                                                                            +
                                                                                            <NumberFormat value={item.price} />
                                                                                        </>
                                                                                    }
                                                                                    {
                                                                                        item.price == 0.0 &&
                                                                                        <p className="card-text text-start font-got-blk-14-400h  text-success">
                                                                                            Grátis</p>
                                                                                    }
                                                                                </p></div>
                                                                            </Stack>
                                                                        </div>
                                                                        <div className="text-end w-25 p-5">
                                                                            <input
                                                                                className={item.image != null && item.image.length !== 0 ? "form-check-input radio_custom " : "form-check-input radio_custom_2"}
                                                                                type="radio"
                                                                                id={slugify(item.name.toLowerCase())}
                                                                                name={slugify(item.name.toLowerCase())}
                                                                                value={index}
                                                                                onChange={() => {
                                                                                    document.querySelector('[id="pastas"]').scrollIntoView()
                                                                                }}

                                                                            />
                                                                        </div>

                                                                    </Stack>
                                                                </Col>
                                                                <Col sm={12}>
                                                                    <hr />
                                                                </Col>
                                                            </>
                                                        )
                                                    })

                                                }

                                            </Row>

                                        </div>
                                    </section>
                                }




                                {
                                    this.props.pastas && this.props.pastas.length > 0 &&
                                    <section id="pastas">
                                        <Row className="bg-gray-light mt-2">
                                            <Col sm={6}>
                                                <h4 className="card-title font-bl-13-700h">Escolha o Tipo de Massa</h4>
                                                <small>Escolha 1 Opção</small>
                                            </Col>
                                            <Col sm={6} className="text-end pr-2">
                                                <span className="badge bg-dark mt-2 px-2">OBRIGATÓRIO</span>
                                            </Col>
                                        </Row>

                                        <div className="modal-body">

                                            <Row key={2} onChange={this.handleRadioButtomPastas}>
                                                {

                                                    this.props.pastas.map((item, index) => {
                                                        return (
                                                            <Row key={index}>
                                                                <Col>
                                                                    <h4 className=" text-start font-got-lt-14-300h fw-bold">{item.name}</h4>
                                                                    {
                                                                        item.price > 0.0 &&
                                                                        <p className="card-text text-start font-got-blk-14-400h text-success">+ {
                                                                            <NumberFormat value={item.price} />}</p>
                                                                    }
                                                                    {
                                                                        item.price == 0.0 &&
                                                                        <p className="card-text text-start font-got-blk-14-400h  text-success">
                                                                            Grátis</p>
                                                                    }
                                                                </Col>
                                                                <Col className="text-end float-end">
                                                                    <input
                                                                        className="form-check-input radio_custom"
                                                                        type="radio"
                                                                        id={slugify("pasta ".concat(item.name.toLowerCase()))}
                                                                        name={slugify("pasta ".concat(item.name.toLowerCase()))}
                                                                        value={index}
                                                                        onChange={() => {
                                                                            document.querySelector('[id="edges"]').scrollIntoView()
                                                                        }}
                                                                    />
                                                                </Col>
                                                                <Col sm={12}>
                                                                    <hr />
                                                                </Col>
                                                            </Row>
                                                        )
                                                    })

                                                }

                                            </Row>

                                        </div>
                                    </section>
                                }
                                {
                                    this.props.edges && this.props.edges.length > 0 &&
                                    <section id="edges">
                                        <Row className="bg-gray-light mt-2">
                                            <Col sm={6}>
                                                <h4 className="card-title font-bl-13-700h">Escolha uma Borda</h4>
                                                <small>Escolha 1 Opção</small>
                                            </Col>
                                            <Col sm={6} className="text-end pr-2">
                                                <span className="badge bg-dark mt-2 px-2">OBRIGATÓRIO</span>
                                            </Col>
                                        </Row>

                                        <div className="modal-body">

                                            <Row key={2} onChange={this.handleRadioButtomEdges}>
                                                {

                                                    this.props.edges.map((item, index) => {
                                                        return (
                                                            <Row key={index}>
                                                                <Col>
                                                                    <h4 className=" text-start font-got-lt-14-300h fw-bold">{item.name}</h4>
                                                                    {
                                                                        item.price > 0.0 &&
                                                                        <p className="card-text text-start font-got-blk-14-400h text-success">+ {
                                                                            <NumberFormat value={item.price} />}</p>
                                                                    }
                                                                    {
                                                                        item.price == 0.0 &&
                                                                        <p className="card-text text-start font-got-blk-14-400h  text-success">
                                                                            Grátis</p>
                                                                    }
                                                                </Col>
                                                                <Col className="text-end float-end pr-2">
                                                                    <input
                                                                        className="form-check-input radio_custom"
                                                                        type="radio"
                                                                        id={slugify("edge ".concat(item.name.toLowerCase()))}
                                                                        name={slugify("edge ".concat(item.name.toLowerCase()))}
                                                                        value={index}
                                                                        onChange={() => {
                                                                            document.querySelector('[id="observation"]').scrollIntoView()
                                                                        }}
                                                                    />
                                                                </Col>
                                                                <Col sm={12}>
                                                                    <hr />
                                                                </Col>
                                                            </Row>
                                                        )
                                                    })

                                                }

                                            </Row>

                                        </div>
                                    </section>
                                }
                                <div className="modal-body">
                                    <Row>
                                        <Col sm={12}>
                                            <div className="form-group mg-bottom-bar">
                                                <label htmlFor=""
                                                    className="control-label">Alguma Observação?</label>
                                                <textarea className="form-control"
                                                    id="observation"
                                                    rows="3"
                                                    onChange={this.handleFields}
                                                    value={this.state.observation} />

                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </PerfectScrollbar>
                            <Row className="bottom-bar-options w-100">
                                <Col>
                                    <div
                                        className="mx-2   rounded border border-dark text-center">
                                        <a onClick={(e) => {
                                            this.handleSub(e)
                                            this.handleSumValueItems()
                                        }}
                                            className="p-2"><i className="bx bx-message-alt-minus"></i></a>
                                        <label
                                            className="p-1">{this.state.qtd}</label>
                                        <a onClick={(e) => {
                                            this.handleAdd(e)
                                            this.handleSumValueItems()
                                        }}
                                            className="p-2"><i className="bx bx-plus"></i></a>
                                    </div>
                                </Col>
                                <Col className="text-end">
                                    <button id="add-to-cart" name="add-to-cart" className="btn btn-lg  btn-dark  w-100" onClick={(e) => {
                                        window.dataLayer.push({ event: 'add-to-cart' });
                                        this.handleAddToCart()
                                    }}>
                                        <h4 className="text-center text-light card-title font-bl-13-700h">
                                            + ADD {<NumberFormat value={valueTot} />}
                                        </h4>
                                    </button>
                                </Col>
                            </Row>
                        </>
                }
            </>

        )

    }
}

ModalContentType1.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
    refresh: PropTypes.any,
    type: PropTypes.string,
    loading: PropTypes.any,
    toggleModal: PropTypes.func,
    paramsBody: PropTypes.any,
    row: PropTypes.any,
    edges: PropTypes.array,
    pastas: PropTypes.array,
    products: PropTypes.array,
    sizes: PropTypes.any,
    size_height: PropTypes.any,
    apiFindProductsByCategoryAndSizeError: PropTypes.func,
    findProductsByCategoryAndSize: PropTypes.func,
    createOrUpdateOrder: PropTypes.func,


}
const mapStateToProps = state => {
    const {
        loading,
        products,
        pastas,
        sizes,
        edges,
    } = state.ProductsCategorySize
    return {
        loading,
        products,
        pastas,
        sizes,
        edges,
    }
}
export default withTranslation()(withRouter(connect(mapStateToProps, {
    apiFindProductsByCategoryAndSizeError,
    findProductsByCategoryAndSize,
    createOrUpdateOrder
})(
    (ModalContentType1)
)))
