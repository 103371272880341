import React, { Component } from "react"
import { Card, CardBody, Col, Row } from "reactstrap";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import NumberFormat from "../../../../components/NumberFormat";
import { Button, Stack } from "react-bootstrap"
import ImageURI from "components/ImageURI";
import slugify from 'react-slugify';
import UIRibbonContainer from "../../../../components/ContainerPromo/Index"
import { layout_objects_keys_company } from "../../../../logic/storageAPI"

class UICardPromoImage extends Component {
    constructor(props) {
        super(props)
        this.state = {}

    }

    render() {
        const { key, item, one_card } = this.props
        return (
            <a
                id={slugify(item.name).toLowerCase()}
                name={slugify(item.name).toLowerCase()}
                onClick={() => {
                    this.props.history.push('promotion?q='.concat(item.slug)
                        .concat("&utm_source=plataform_pwa&utm_medium=plataformPWA&utm_campaign=pwa&etn_app=")
                        .concat(layout_objects_keys_company()))
                    window.dataLayer.push({ event: slugify('promotion'.concat(' ').concat(item.name).toLowerCase()) });

                }} >
                <Stack gap={2} className="bg-white border border-1  rounded-2 p-2 w-100">
                    <div className="w-100">
                        <h1 className="text-center card-title font-got-blk-11-300h ">{item.name}</h1>
                    </div>
                    <div>
                        <Stack direction="horizontal" gap={2}>
                            {
                                item.type == 1
                                    ?
                                    <Stack gap={2} className="p-2">
                                        {
                                            item.sizes.length > 0 &&
                                            item.sizes.map((size, index2) => {
                                                return (
                                                    <div key={index2} className="text-start w-100">
                                                        <Row>
                                                            <Col sm={12} className=" mt-n-2">
                                                                <span className="fw-bold">{size.name.toUpperCase()} ( {size.qtd_flavors} SABORES {size.qtd_pieces} PEDAÇOS)</span>

                                                                <Stack direction="horizontal" gap={2} className="text-center mt-n">
                                                                    <div>

                                                                        <p className={"text-start mt-2 card-text font-bl-18-300h  text-danger fw-bold"}>
                                                                            <small>
                                                                                <del>
                                                                                    {<NumberFormat value={size.old_price} />}
                                                                                </del>
                                                                            </small>
                                                                        </p>
                                                                    </div>

                                                                    <div >
                                                                        <p className={"text-start mt-2 card-text font-got-blk-20-400h text-success fw-bold"}>
                                                                            <small>
                                                                                {<NumberFormat value={size.new_price} />}
                                                                            </small>
                                                                        </p>
                                                                    </div>
                                                                </Stack>
                                                                <hr />

                                                            </Col>

                                                        </Row>

                                                    </div>
                                                )

                                            })
                                        }
                                    </Stack>
                                    :
                                    <Stack direction="horizontal" gap={2} className="text-center">
                                        <div className="w-50"><p className={"text-start mt-2 card-text font-bl-18-300h  text-danger fw-bold"}><small><del>{<NumberFormat value={item.old_price} />}</del></small></p></div>
                                        <div className="w-50"><p className={"text-start mt-2 card-text font-got-blk-20-400h  text-success fw-bold"}>{<NumberFormat value={item.new_price} />}</p></div>
                                    </Stack>
                            }
                            <ImageURI
                                style_div="img-square-wrapper"
                                style_img="m-2 rounded thumbnail img-responsive img-card-list"
                                url={item.url_image} alt={item.name} />
                        </Stack>
                    </div>
                </Stack>
            </a>
        )
    }
}

UICardPromoImage.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
    refresh: PropTypes.any,
    loading: PropTypes.any,
    key: PropTypes.any,
    one_card: PropTypes.bool,
    item: PropTypes.any,
    toggleproductsmodal: PropTypes.func,
    history: PropTypes.any,



}
const mapStateToProps = state => {
    return {}
}
export default withTranslation()(withRouter(connect(mapStateToProps, {


})(
    (UICardPromoImage)
)))
