import React from "react"
import { Button, Col, Input, Modal, Row } from "reactstrap"
import { Stack } from "react-bootstrap"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { createOrUpdateOrder, apiFindProductsByCategoryAndSizeError, findProductsByCategoryAndSize } from "../../../../store/actions"
import NumberFormat from "../../../../components/NumberFormat"
import "react-perfect-scrollbar/dist/css/styles.css"
import toast from "react-hot-toast"
import { checkInStorageCart, createCartOrUpdate, createCartWithData, getInStorageCart, getInStorageCartNot64 } from "logic/storageAPI"
import { ACTION_ADD_OR_UPDATE_PRODUCT_ORDER, CART_STORAGE_SERVICE_CACHE_API, CART_STORAGE_SERVICE_LOCAL } from "logic/keyStorageAPI"


/**
 * Modal para montagem do pedido, pizzas.
 */
class Index extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            qtd: 1,
            observation: '',
            refresh: false,
            valueTot: 0.0,
            size_selected: false,
            edge_selected: false,
            pasta_selected: false,

        }

        this.handleSub = this.handleSub.bind(this)
        this.handleAdd = this.handleAdd.bind(this)
        this.handleRadioButtomEdges = this.handleRadioButtomEdges.bind(this)
        this.handleRadioButtomPastas = this.handleRadioButtomPastas.bind(this)
        this.handleRadioButtomSizes = this.handleRadioButtomSizes.bind(this)
        this.handleFields = this.handleFields.bind(this)
        this.handleSumValueItems = this.handleSumValueItems.bind(this)
        this.handleAddToCart = this.handleAddToCart.bind(this)
        // this.handleValidatorFields = this.handleValidatorFields(this)

    }

    componentDidMount() {


    }

    handleValidatorFields() {
        const { size_selected, edge_selected, pasta_selected } = this.state;
        let validator = true;

        if (!size_selected) {
            toast.error('Escolha o tamanho')
            validator = false
        }
        if (!edge_selected) {
            toast.error('Escolha o tipo borda')
            validator = false
        }
        if (!pasta_selected) {
            toast.error('Escolha o tipo de massa')
            validator = false
        }

        return validator
    }

    handleAddToCart() {
        const { name_company, company_id, load_url_entity, utm_source, utm_medium, utm_campaign } = this.props
        const { edges, products, pastas, sizes } = this.props.row

        let cart = (checkInStorageCart(CART_STORAGE_SERVICE_LOCAL.intent) ? getInStorageCart(CART_STORAGE_SERVICE_LOCAL.intent, true) : [])

        let cart_obg = {}

        if (cart.length > 0) {
            cart.forEach(group => {
                if (parseInt(group.idGroup) === parseInt(this.props.row.category_id)) {
                    cart_obg = group
                }
            })
        }

        if (Object.keys(cart_obg).length === 0) {
            cart_obg = {
                id: this.props.row.id,
                idGroup: this.props.row.category_id,
                prod_type: this.props.row.category_prod.type,
                groupName: this.props.row.category_name,
                qtd: this.state.qtd,
                type: 1,
                items: [],
                observation: this.state.observation
            }
        }


        let edge_selected = {}
        edges.forEach(edge => {
            if (edge.isSelected) {
                edge_selected = {
                    id: edge.id,
                    name: edge.name,
                    price: edge.price
                }


            }
        })

        let pasta_selected = {}
        pastas.forEach(pasta => {
            if (pasta.isSelected) {
                pasta_selected = {
                    id: pasta.id,
                    name: pasta.name,
                    price: pasta.price
                }
            }
        })

        let size_selected = {}
        sizes.forEach(size => {
            if (size.isSelected) {
                size_selected = {
                    id: size.id,
                    name: size.name,
                    price: size.price
                }
            }
        })


        cart_obg.items.push({
            id: this.props.row.id,
            name: this.props.row.name,
            description: `${this.state.qtd}x ${this.props.row.name}`,
            qtd: this.state.qtd,
            prod_type: this.props.row.category_prod.type,
            price: size_selected.price,
            edge: edge_selected,
            pasta: pasta_selected,
            size: size_selected//size_choosed
            //total: (parseInt(product.qtd) * parseFloat(product.price))
        })


        cart_obg.qtd = this.state.qtd
        let searchable_group_cart = false
        if (cart.length > 0) {
            cart.map((group, index) => {
                if (parseInt(group.idGroup) === parseInt(this.props.row.category_id)) {
                    cart[index] = cart_obg
                    searchable_group_cart = true
                    return
                }
            })

        }
        if (!searchable_group_cart) {
            cart.push(cart_obg)
        }

        // atualiza o carrinho na sessão do servidor.
        if (checkInStorageCart(CART_STORAGE_SERVICE_CACHE_API.intent)) {
            createCartWithData(this.props, {
                ACTION_FACTORY: ACTION_ADD_OR_UPDATE_PRODUCT_ORDER.intent,
                key: getInStorageCartNot64(CART_STORAGE_SERVICE_CACHE_API.intent),
                product: cart_obg,
                company_id,
                utm_source,
                utm_medium,
                utm_campaign
            })
        }


        createCartOrUpdate(cart)
        toast.success('Adicionado com sucesso.')

    }

    handleSumValueItems() {
        const { edges, pastas, sizes } = this.props.row
        const { qtd } = this.state


        let valueTot = 0.0

        sizes.forEach(element => {
            if (element.isSelected && element.price > 0.0) {
                valueTot += parseFloat(element.price)
            }
        })

        edges.forEach(element => {
            if (element.isSelected && element.price > 0.0) {
                valueTot += parseFloat(element.price)
            }
        })

        pastas.forEach(element => {
            if (element.isSelected && element.price > 0.0) {
                valueTot += parseFloat(element.price)
            }
        })


        valueTot *= qtd

        this.setState({ valueTot: valueTot, refresh: true })

    }

    handleFields(event) {

        const { name, value } = event.target

        this.setState({ observation: value })
    }

    handleAdd(e, type) {

        this.setState({ qtd: (this.state.qtd + 1), refresh: true })
        this.handleSumValueItems();

    }

    handleSub(e, type) {

        if (this.state.qtd > 1) {
            this.setState({ qtd: (this.state.qtd - 1), refresh: true })
        } else {
            this.setState({ qtd: 1, refresh: true })
        }
        this.handleSumValueItems();
    }
    handleRadioButtomSizes(event) {
        this.props.row.sizes.forEach(element => {
            element.isSelected = false
        })


        this.props.row.sizes[parseInt(event.target.value)]['isSelected'] = !this.props.row.sizes[parseInt(event.target.value)]['isSelected']

        let size = this.props.row.sizes[parseInt(event.target.value)];

        this.setState({ size_choosed: size, size_selected: true })

        this.handleSumValueItems();
    }

    handleRadioButtomEdges(event) {
        this.props.row.edges.forEach(element => {
            element.isSelected = false
        })

        this.props.row.edges[parseInt(event.target.value)]['isSelected'] = !this.props.row.edges[parseInt(event.target.value)]['isSelected']

        this.setState({ refresh: true, edge_selected: !this.state.edge_selected })
        this.handleSumValueItems();
    }

    handleRadioButtomPastas(event) {
        this.props.row.pastas.forEach(element => {
            element.isSelected = false
        })

        this.props.row.pastas[parseInt(event.target.value)]['isSelected'] = !this.props.row.pastas[parseInt(event.target.value)]['isSelected']

        this.setState({ refresh: true, pasta_selected: !this.state.pasta_selected })
        this.handleSumValueItems();
    }

    render() {
        const { valueTot, refresh } = this.state
        if (refresh) {
            this.handleSumValueItems()
            this.setState({ refresh: false })
        }
        return (
            <div className="bg-white round-top-left-right12">
                {
                    this.props.row &&
                    <>
                        <div className="c_ribbon  c_ribbon--red mt-align-float">
                            <small>-{parseInt(this.props.row.perc_disc)}%</small>
                            <br className="mb-2" />
                            <small >OFF</small>
                        </div>
                        <div className="p-3">
                            <Stack direction="horizontal" gap={2}>
                                <div className="w-75">
                                    <h5 className=" text-start card-title text-muted">{this.props.row.category_name}</h5>
                                    <h5 className=" text-start card-title font-got-blk-14-400h text-dark mt-2">{this.props.row.name}</h5>
                                    <p><small>{this.props.row.description}</small><br />
                                        {
                                            this.props.row.model_menu_id === 2 &&
                                            <small>{this.props.row.category_description}</small>

                                        }
                                    </p>
                                </div>
                                <div className="w-25 text-end">
                                    <div onClick={async (event) => {
                                        this.props.sharedArticle(event)
                                    }}>
                                        <box-icon type="solid" size="sm" name="share-alt" color="red"></box-icon>

                                    </div>

                                </div>
                            </Stack>
                        </div>

                        {
                            this.props.row.sizes && this.props.row.sizes.length > 0 &&
                            <section id="sizes">
                                <Row className="bg-gray-light mt-2">
                                    <Col sm={6}>
                                        <h4 className="card-title font-bl-13-700h">Escolha o Tamanho</h4>
                                        <small>Escolha 1 Opção</small>
                                    </Col>
                                    <Col sm={6} className="text-end pr-2">
                                        <span className="badge bg-dark mt-2 px-2">OBRIGATÓRIO</span>
                                    </Col>
                                </Row>

                                <div className="modal-body">

                                    <Row key={2} onChange={this.handleRadioButtomSizes}>
                                        {

                                            this.props.row.sizes.map((item, index) => {
                                                return (
                                                    <Row key={index}>
                                                        <Col className="p-4">
                                                            <h4 className=" text-start font-got-lt-14-300h fw-bold">{item.name} ( {item.qtd_pieces} PEDAÇOS)</h4>
                                                            {
                                                                item.price > 0.0 &&
                                                                <Stack direction="horizontal" gap={2} className="text-center mt-n">
                                                                    <div>

                                                                        <p className={"text-start mt-2 card-text font-bl-18-300h  text-danger fw-bold"}>
                                                                            <small>
                                                                                <del>
                                                                                    {<NumberFormat value={item.old_price} />}
                                                                                </del>
                                                                            </small>
                                                                        </p>
                                                                    </div>

                                                                    <div >
                                                                        <p className={"text-start mt-2 card-text font-got-blk-20-400h text-success fw-bold"}>
                                                                            <small>
                                                                                {<NumberFormat value={item.new_price} />}
                                                                            </small>
                                                                        </p>
                                                                    </div>
                                                                </Stack>

                                                            }
                                                            {
                                                                item.price == 0.0 &&
                                                                <p className="card-text text-start font-got-blk-14-400h  text-success">
                                                                    Grátis</p>
                                                            }
                                                        </Col>
                                                        <Col className="text-end float-end pr-2">
                                                            <input
                                                                className="form-check-input radio_custom mt-4"
                                                                type="radio"
                                                                name="size"
                                                                id="size1"
                                                                value={index}
                                                                onChange={() => {
                                                                    document.querySelector('[id="pastas"]').scrollIntoView()
                                                                }}
                                                            />
                                                        </Col>
                                                        <Col sm={12}>
                                                            <hr />
                                                        </Col>
                                                    </Row>
                                                )
                                            })

                                        }

                                    </Row>

                                </div>
                            </section>
                        }
                        {
                            this.props.row.pastas && this.props.row.pastas.length > 0 &&
                            <section id="pastas">
                                <Row className="bg-gray-light mt-2">
                                    <Col sm={6}>
                                        <h4 className="card-title font-bl-13-700h">Escolha o Tipo de Massa</h4>
                                        <small>Escolha 1 Opção</small>
                                    </Col>
                                    <Col sm={6} className="text-end pr-2">
                                        <span className="badge bg-dark mt-2 px-2">OBRIGATÓRIO</span>
                                    </Col>
                                </Row>

                                <div className="modal-body">

                                    <Row key={2} onChange={this.handleRadioButtomPastas}>
                                        {

                                            this.props.row.pastas.map((item, index) => {
                                                return (
                                                    <Row key={index}>
                                                        <Col className="p-4">
                                                            <h4 className=" text-start font-got-lt-14-300h fw-bold">{item.name}</h4>
                                                            {
                                                                item.price > 0.0 &&
                                                                <p className="card-text text-start font-got-blk-14-400h  text-success">+ {
                                                                    <NumberFormat value={item.price} />}</p>
                                                            }
                                                            {
                                                                item.price == 0.0 &&
                                                                <p className="card-text text-start font-got-blk-14-400h  text-success">
                                                                    Grátis</p>
                                                            }
                                                        </Col>
                                                        <Col className="text-end float-end pr-2">
                                                            <input
                                                                className="form-check-input radio_custom mt-4"
                                                                type="radio"
                                                                name="pasta"
                                                                id="pasta1"
                                                                value={index}
                                                                onChange={() => {
                                                                    document.querySelector('[id="edges"]').scrollIntoView()
                                                                }}
                                                            />
                                                        </Col>
                                                        <Col sm={12}>
                                                            <hr />
                                                        </Col>
                                                    </Row>
                                                )
                                            })

                                        }

                                    </Row>

                                </div>
                            </section>
                        }
                        {
                            this.props.row.edges && this.props.row.edges.length > 0 &&
                            <section id="edges">
                                <Row className="bg-gray-light mt-2">
                                    <Col sm={6}>
                                        <h4 className="card-title font-bl-13-700h">Escolha uma Borda</h4>
                                        <small>Escolha 1 Opção</small>
                                    </Col>
                                    <Col sm={6} className="text-end pr-2">
                                        <span className="badge bg-dark mt-2 px-2">OBRIGATÓRIO</span>
                                    </Col>
                                </Row>

                                <div className="modal-body">

                                    <Row key={2} onChange={this.handleRadioButtomEdges}>
                                        {

                                            this.props.row.edges.map((item, index) => {
                                                return (
                                                    <Row key={index} >
                                                        <Col className="p-4">
                                                            <h4 className=" text-start font-got-lt-14-300h fw-bold">{item.name}</h4>
                                                            {
                                                                item.price > 0.0 &&
                                                                <p className="card-text text-start font-got-blk-14-400h  text-success">+ {
                                                                    <NumberFormat value={item.price} />}</p>
                                                            }
                                                            {
                                                                item.price == 0.0 &&
                                                                <p className="card-text text-start font-got-blk-14-400h  text-success">
                                                                    Grátis</p>
                                                            }
                                                        </Col>
                                                        <Col className="text-end float-end pr-2">
                                                            <input
                                                                className="form-check-input radio_custom mt-4"
                                                                type="radio"
                                                                name="edge"
                                                                id="edge1"
                                                                value={index}
                                                                onChange={() => {
                                                                    document.querySelector('[id="observation"]').scrollIntoView()
                                                                }}
                                                            />
                                                        </Col>
                                                        <Col sm={12}>
                                                            <hr />
                                                        </Col>
                                                    </Row>
                                                )
                                            })

                                        }

                                    </Row>

                                </div>
                            </section>
                        }
                        <div className="modal-body p-3">
                            <Row>
                                <Col sm={12}>
                                    <div className="form-group mg-bottom-bar">
                                        <label htmlFor=""
                                            className="control-label">Alguma Observação?</label>
                                        <textarea className="form-control"
                                            id="observation"
                                            rows="3"
                                            onChange={this.handleFields}
                                            value={this.state.observation} />

                                    </div>
                                </Col>
                            </Row>
                        </div>

                        <Row className="bottom-bar-options w-100">
                            <Col>
                                <div
                                    className="mx-2   rounded border border-dark text-center">
                                    <a onClick={(e) => {
                                        this.handleSub(e)
                                        //this.handleSumValueItems()
                                    }}
                                        className="p-2"><i className="bx bx-message-alt-minus"></i></a>
                                    <label
                                        className="p-1">{this.state.qtd}</label>
                                    <a onClick={(e) => {
                                        this.handleAdd(e)
                                        // this.handleSumValueItems()
                                    }}
                                        className="p-2"><i className="bx bx-plus"></i></a>
                                </div>
                            </Col>
                            <Col className="text-end">
                                <button className="btn btn-lg  btn-dark  w-100" onClick={(e) => {
                                    if (this.handleValidatorFields()) {
                                        this.handleAddToCart()
                                    }

                                }}>
                                    <h4 className="text-center text-light card-title font-bl-13-700h">
                                        + ADD {<NumberFormat value={valueTot} />}
                                    </h4>
                                </button>
                            </Col>
                        </Row>
                    </>
                }
            </div>

        )

    }
}

Index.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
    refresh: PropTypes.any,
    type: PropTypes.string,
    loading: PropTypes.any,
    toggleModal: PropTypes.func,
    paramsBody: PropTypes.any,
    row: PropTypes.any,
    is_promotion: PropTypes.bool,
    edges: PropTypes.array,
    pastas: PropTypes.array,
    products: PropTypes.array,
    sizes: PropTypes.any,
    size_height: PropTypes.any,
    apiFindProductsByCategoryAndSizeError: PropTypes.func,
    sharedArticle: PropTypes.func,
    createOrUpdateOrder: PropTypes.func,

    name_company: PropTypes.any,
    company_id: PropTypes.any,
    load_url_entity: PropTypes.any,
    utm_source: PropTypes.any,
    utm_medium: PropTypes.any,
    utm_campaign: PropTypes.any,


}
const mapStateToProps = state => {

    return {}
}
export default withTranslation()(withRouter(connect(mapStateToProps, {
    apiFindProductsByCategoryAndSizeError,
    findProductsByCategoryAndSize,
    createOrUpdateOrder
})(
    (Index)
)))
