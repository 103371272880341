import React, { Component } from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import SEO from "components/SEO/index"
import BodyAndBarApp from "../../../../components/BodyAndBarApp/Index"

class Index extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            cache: true
        }
    }


    render() {
        const { result } = this.props

        return (
            <BodyAndBarApp
                visibleCart={false}
                icon_back={"mdi-arrow-left"}
                title="Política de Devolução"
            >
                <div className="pr-4 px-4">

                    <h1 className="mb-3 text-center">Política de Devolução</h1>
                    <p><strong className="text-center">Cardápio Digital</strong></p>
                    <p><strong>Pizzaria Fontanella</strong></p>
                    <p><strong>CNPJ: 43.612.624/0001-72</strong></p>
                    <p><strong>Telefone: (19) 99799-1650 / (19) 3500-3907</strong></p>
                    <p><strong>E-mail: <a href="mailto:sac@pizzariafontanella.com.br" target="_new">sac@pizzariafontanella.com.br</a></strong></p>
                    <p><strong>Última Atualização: 21/10/2023 00:32</strong></p>

                    <p>Na Pizzaria Fontanella, nossa principal preocupação é a sua satisfação com nossas pizzas deliciosas. No entanto, entendemos que podem surgir situações em que você precise devolver um pedido. Aqui está nossa política de devolução:</p>

                    <p><strong>Devoluções e Reembolsos</strong></p>
                    <p>Se você não estiver satisfeito com o seu pedido por qualquer motivo, entre em contato conosco dentro de até 1 hora após a entrega. Teremos o prazer de trabalhar com você para resolver qualquer problema. Oferecemos as seguintes opções:</p>
                    <ul>
                        <li>Substituição do seu pedido</li>
                        <li>Reembolso total do valor do pedido</li>
                    </ul>

                    <p><strong>Como Iniciar uma Devolução</strong></p>
                    <p>Para iniciar o processo de devolução, entre em contato com nossa equipe de atendimento ao cliente pelo telefone (19) 99799-1650 / (19) 3500-3907 ou envie um e-mail para sac@pizzariafontanella.com.br. Nossa equipe fornecerá orientações específicas sobre como proceder com a devolução.</p>

                    <p><strong>Condições de Devolução</strong></p>
                    <p>Para ser elegível para devolução e reembolso, o pedido deve atender aos seguintes critérios:</p>
                    <ul>
                        <li>O pedido deve estar em sua embalagem original</li>
                        <li>Produtos devolvidos não devem mostrar sinais de consumo</li>
                        <li>A Solicitação deve estar no periodo estipulado nessa politica</li>
                    </ul>

                    <p>Nós nos reservamos o direito de recusar devoluções que não atendam a esses critérios.</p>

                    <p>A Pizzaria Fontanella se orgulha de proporcionar uma experiência gastronômica excepcional e atendimento de qualidade aos nossos clientes. Agradecemos por escolher a Pizzaria Fontanella.</p>

                </div>
            </BodyAndBarApp>
        )
    }
}

Index.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
    loading: PropTypes.bool,
    error: PropTypes.any,
    message: PropTypes.any,
    result: PropTypes.object,
    apiFindHomeProfileCompanyError: PropTypes.func,
    findHomeProfileCompany: PropTypes.func,
    pageSelector: PropTypes.func


}
const mapStateToProps = state => {

    return {}
}
export default withTranslation()(withRouter(connect(mapStateToProps, {
})(
    (Index)
)))
