export const GEO_REVERSE_COORDINATES = "GEO_REVERSE_COORDINATES"
export const GEO_REVERSE_COORDINATES_SUCCESS = "GEO_REVERSE_COORDINATES_SUCCESS"

export const GEO_DISTANCE_COORDINATES = "GEO_DISTANCE_COORDINATES"
export const GEO_DISTANCE_COORDINATES_SUCCESS = "GEO_DISTANCE_COORDINATES_SUCCESS"

export const SEARCH_ADDRESS = "SEARCH_ADDRESS"
export const SEARCH_ADDRESS_SUCCESS = "SEARCH_ADDRESS_SUCCESS"

export const API_ERROR = "PAGINATE_COUNTRY_API_ERROR"
