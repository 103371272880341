import React, { Component } from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import SEO from "components/SEO/index"
import BodyAndBarApp from "../../../../../components/BodyAndBarApp/Index"

class Index extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            cache: true
        }
    }


    render() {
        const { result } = this.props

        return (
            <BodyAndBarApp
                visibleCart={false}
                icon_back={"mdi-arrow-left"}
                title="Termos de Segurança"
            >
                <div className="pr-4 px-4">

                    <h1 className="mb-3 text-center">Termos de Segurança</h1>
                    <p><strong className="text-center">Cardápio Digital</strong></p>
                    <p><strong>Pizzaria Fontanella</strong></p>
                    <p><strong>CNPJ: 43.612.624/0001-72</strong></p>
                    <p><strong>Telefone: (19) 99799-1650 / (19) 3500-3907</strong></p>
                    <p><strong>E-mail: <a href="mailto:sac@pizzariafontanella.com.br" target="_new">sac@pizzariafontanella.com.br</a></strong></p>
                    <p><strong>Última Atualização: 08/01/2024 10:36</strong></p>

                    <p>Esta política de de Uso para Chat descreve os termos e condições relacionados aos serviços de automação para os canais de atendimento. Ao iniciar um atendimento através dos canais de comunicaçao, você concorda com os termos e condições descritos nesta política.</p>

                    <p><strong>1. Proteção de Dados:</strong></p>

                    <p>Comprometemo-nos a proteger proativamente os dados do cliente, implementando práticas avançadas de segurança.</p>

                    <p><strong>2. Criptografia:</strong></p>

                    <p>Utilizamos criptografia para proteger dados durante a transmissão e armazenamento, garantindo a integridade das informações.</p>

                    <p><strong>3. Acesso Restrito:</strong></p>

                    <p>O acesso a informações sensíveis é restrito a pessoal autorizado, com controles rigorosos para garantir a privacidade.</p>

                    <p><strong>4. Monitoramento e Auditoria:</strong></p>

                    <p>Realizamos monitoramento constante e auditorias para garantir a conformidade e a eficácia contínua das práticas de segurança.</p>

                    <p><strong>5. Notificação de Violação:</strong></p>

                    <p>Em caso de violação de dados, comprometemo-nos a notificar os clientes de maneira oportuna e transparente, fornecendo informações relevantes.</p>

                    <p>Nos reservamos o direito de atualizar esta política periodicamente. As alterações serão publicadas em nosso cardápio digital.</p>
                </div>
            </BodyAndBarApp>
        )
    }
}

Index.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
    loading: PropTypes.bool,
    error: PropTypes.any,
    message: PropTypes.any,
    result: PropTypes.object,
    apiFindHomeProfileCompanyError: PropTypes.func,
    findHomeProfileCompany: PropTypes.func,
    pageSelector: PropTypes.func


}
const mapStateToProps = state => {

    return {}
}
export default withTranslation()(withRouter(connect(mapStateToProps, {
})(
    (Index)
)))
