import React, { Component } from "react"
import PropTypes from "prop-types"
import { Col, Row, Stack,Carousel } from "react-bootstrap";
import ImageURI from "components/ImageURI";
import { get_name_company, get_qrcode_app, get_url_app_in_applestore, get_url_app_in_playstore } from "logic/storageAPI";
import { ScrollMenu } from "react-horizontal-scrolling-menu"

import Banner_01 from "./../../assets/img/ic_banner_facade.jpg"
class Index extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }


    render() {
        return (
            <Carousel slide={true}>
                <Carousel.Item>
                    <ImageURI
                        style_div="img-square-wrapper rounded-2 "
                        style_img="rounded-2  img-fluid "
                        url={Banner_01}
                        alt={get_name_company()} />


                </Carousel.Item>
                <Carousel.Item>
                    <ImageURI
                        style_div="img-square-wrapper rounded-2 "
                        style_img="rounded-2  img-fluid "
                        url={Banner_01}
                        alt={get_name_company()} />


                </Carousel.Item>
            </Carousel>


        )
    }
}

Index.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    image: PropTypes.string,
    app_id: PropTypes.string,
}

export default Index