import React, { Component } from "react"
import { Card, CardBody, CardText, CardTitle, Col, Row } from "reactstrap"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import ReactDrawer from 'react-drawer'
import UICardEmptyCart from "./UICardEmptyCart"
import InputMask from "react-input-mask"
import UILoadingAnim from "../Mobile/Components/UILoadingAnim"

class UIStatusOrderProgress extends Component {
    constructor(props) {
        super(props)
        this.state = {}



    }

    componentDidMount() {

    }


    render() {
        //console.log('error order ', this.props.error)
        //console.log('error order ', this.props.message)
        return (
            <React.Fragment  className="bg-white">

                <Row>
                    <Col sm={12}>
                        <div className="text-center  mb-4">
                            <h4 className="card-title">Pedido andamento</h4>
                            <small>em Pizzaria Fontanella</small>
                        </div>
                    </Col>
                    <Col sm={12}>
                        <div style={{
                            height: '700px !important',
                            marginBottom: '400px'

                        }}>
                            <UILoadingAnim
                                url_anim="https://assets2.lottiefiles.com/packages/lf20_z3jdCF.json" />
                        </div>
                    </Col>


                </Row>

            </React.Fragment>

        )
    }
}

UIStatusOrderProgress.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
    loading: PropTypes.any,
    


}
const mapStateToProps = state => {
    return {}
}
export default withTranslation()(withRouter(connect(mapStateToProps, {

})(
    (UIStatusOrderProgress)
)))
