import React, { Component } from "react"
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Ribbon, RibbonContainer } from "react-ribbons";
import "../../assets/custom-ribbon.css"


/**
 * https://emanuelescarabattoli.github.io/react-ribbons/index.html
 */
class Index extends Component {
    constructor(props) {
        super(props)
        this.state = {}

    }

    render() {
        const { key, title, children, one_card } = this.props

        return (
            <RibbonContainer key={key} className={one_card === true ? "bg-white m-2 bx-w-one-card border border-1  rounded-2 p-2" : "bg-white m-2 bx-w border border-1  rounded-2 p-2"}>
                <Ribbon
                    side="left"
                    type="corner"
                    size="normal"
                    backgroundColor="#cc0000"
                    color="#ccffff"
                    fontFamily="sans"
                    withStripes={true}
                >
                    {title}
                </Ribbon>
                {children}
                <div className="bx-card-promo-footer"><span className="text-light p-2 fw-bold text-uppercase">Clique e confira</span></div>

            </RibbonContainer>
        )
    }
}

Index.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
    refresh: PropTypes.any,
    loading: PropTypes.any,
    key: PropTypes.any,
    item: PropTypes.any,
    title: PropTypes.any,
    one_card: PropTypes.any,
    children: PropTypes.any,



}
const mapStateToProps = state => {
    return {}
}
export default withTranslation()(withRouter(connect(mapStateToProps, {


})(
    (Index)
)))
