import React, { Component } from "react"
import PropTypes from "prop-types"
import { Col, Row, Stack } from "react-bootstrap";
import ImageURI from "components/ImageURI";
import { get_img_logo_company, get_name_company, get_qrcode_app, get_url_app_in_applestore, get_url_app_in_playstore } from "logic/storageAPI";
import ic_logo_light from "../../assets/img/ic_logo_light.png"
class Index extends Component {

    constructor(props) {
        super(props);
        this.state = {
            options_menu: [
                {
                    id: 1,
                    title: "Quem Somos",
                    options: [
                        {
                            id: 1,
                            name: 'Nossa história',
                            path: '/'
                        },
                        {
                            id: 2,
                            name: 'Investidores',
                            path: '/'
                        },
                        {
                            id: 3,
                            name: 'Patrocinadores',
                            path: '/'
                        },
                    ]
                },
                {
                    id: 2,
                    title: "Atendimento ao cliente",
                    options: [
                        {
                            id: 1,
                            name: 'Fale conosco',
                            path: '/'
                        },
                        {
                            id: 2,
                            name: 'Pesquisa de satisfação',
                            path: '/'
                        },
                    ]
                },
                {
                    id: 3,
                    title: "Termos e Políticas",
                    options: [
                        {
                            id: 1,
                            name: 'Políticas de Uso',
                            path: '/policies'
                        },
                        {
                            id: 2,
                            name: 'Política de privacidade',
                            path: '/policy-privacy'
                        },
                        {
                            id: 3,
                            name: 'Termos de atendimento',
                            path: '/chat-terms'
                        },
                    ]
                },

            ]

        }
    }


    render() {
        return (
            <div className="p-5">

                <section className="box-sec-two box-sec-one">

                    {
                        this.state.options_menu.map((item, index) => {
                            return (

                                <div key={index} className="box-sec-inner-one">
                                    <h3 className="box-footer-title ">{item.title}</h3>
                                    <ul className="box-footer-ul">
                                        {
                                            item.options.map((option, index2) => {
                                                return (
                                                    <li key={index2} className="box-footer-ul-font">
                                                        <a className=" text-start" title={option.name} href={option.path}>{option.name}</a>
                                                    </li>
                                                )
                                            })
                                        }

                                    </ul>
                                </div>

                            )
                        })
                    }

                </section>
                <hr />
                <section>
                    <Stack gap={2} direction="horizontal">
                        <div>

                            <ImageURI
                                style_div="img-square-wrapper rounded-2"
                                style_img="float-end rounded-2 img_logo_footer img-fluid"
                                url={ic_logo_light}
                                alt={get_name_company()} />
                        </div>
                        <div className="p-5">
                            <p className="text-white">Copyright © @2020 PIZZARIA FONTANELLA LTDA. – CNPJ : 43.612.624/0001-72 - RUA LUIZ TANK / HÉLIO SAMPAIO CAMARGO Nº: 417 – JARDIM PIZA – LIMEIRA-SP. Todos os direitos reservados.</p>
                        </div>
                    </Stack>
                </section>
                <hr />
                <section>
                    <p className="text-white">
                        Os preços apresentados já incluem impostos. Imagens meramente ilustrativas. Promoções, combos e preços podem variar de acordo com a sua localização. Se ocorrer qualquer divergência nos valores dos produtos ou promoções, o preço válido é o apresentado no carrinho de compras. Preços e condições de pagamento exclusivos para compras via internet. As promoções são válidas enquanto durarem os estoques. A {get_name_company()} se reserva o direito de alterar e/ou remover itens ou promoções do cardápio sem aviso prévio. O horário de funcionamento e de entrega varia de acordo com a sua localização e unidade escolhida. Digite o seu CEP para descobrir se fazemos entregas para a sua região.
                    </p>

                </section>

            </div>
        )
    }
}

Index.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    image: PropTypes.string,
    app_id: PropTypes.string,
}

export default Index