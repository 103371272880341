import React, { Component } from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import SEO from "components/SEO/index"
import BodyAndBarApp from "../../../../components/BodyAndBarApp/Index"

class Index extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            cache: true
        }
    }


    render() {
        const { result } = this.props

        return (
            <BodyAndBarApp
                visibleCart={false}
                icon_back={"mdi-arrow-left"}
                title="Política de Entrega"
            >
                <div className="pr-4 px-4">

                    <h1 className="mb-3 text-center">Política de Entrega</h1>
                    <p><strong className="text-center">Cardápio Digital</strong></p>
                    <p><strong>Pizzaria Fontanella</strong></p>
                    <p><strong>CNPJ: 43.612.624/0001-72</strong></p>
                    <p><strong>Telefone: (19) 99799-1650 / (19) 3500-3907</strong></p>
                    <p><strong>E-mail: <a href="mailto:sac@pizzariafontanella.com.br" target="_new">sac@pizzariafontanella.com.br</a></strong></p>
                    <p><strong>Última Atualização: 21/10/2023 00:32</strong></p>

                    <p>Esta política de entrega descreve os termos e condições relacionados aos serviços de entrega prestados pelo nosso cardápio digital. Ao fazer um pedido de entrega, você concorda com os termos e condições descritos nesta política.</p>

                    <p><strong>1. Área de Entrega</strong></p>

                    <p>Informamos a área de entrega coberta pelos nossos serviços. Certifique-se de que seu endereço esteja dentro da área de entrega antes de fazer um pedido.</p>

                    <p><strong>2. Taxas de Entrega</strong></p>

                    <p>As taxas de entrega são aplicáveis e podem variar com base na distância e no valor do pedido. As taxas exatas serão informadas durante o processo de pedido.</p>

                    <p><strong>3. Tempo de Entrega</strong></p>

                    <p>Estimamos um tempo de entrega aproximado durante o processo de pedido. No entanto, esse tempo pode variar devido a circunstâncias, como tráfego e volume de pedidos.</p>

                    <p><strong>4. Condições do Pedido</strong></p>

                    <p>Verifique seu pedido cuidadosamente antes de finalizá-lo. Após o pedido ser aceito, não podemos garantir alterações. Certifique-se de fornecer informações de contato precisas para facilitar a entrega.</p>

                    <p><strong>5. Recusa de Entrega</strong></p>

                    <p>Reservamo-nos o direito de recusar a entrega se considerarmos que as condições de entrega não são adequadas ou seguras.</p>

                    <p><strong>6. Reembolsos e Problemas de Entrega</strong></p>

                    <p>Em caso de problemas com a entrega, entre em contato conosco imediatamente para que possamos resolver a situação. Consulte nossa política de reembolso para obter informações sobre reembolsos.</p>

                    <p><strong>7. Alterações na Política de Entrega</strong></p>

                    <p>Nos reservamos o direito de atualizar esta política de entrega periodicamente. As alterações serão publicadas em nosso cardápio digital.</p>
                </div>
            </BodyAndBarApp>
        )
    }
}

Index.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
    loading: PropTypes.bool,
    error: PropTypes.any,
    message: PropTypes.any,
    result: PropTypes.object,
    apiFindHomeProfileCompanyError: PropTypes.func,
    findHomeProfileCompany: PropTypes.func,
    pageSelector: PropTypes.func


}
const mapStateToProps = state => {

    return {}
}
export default withTranslation()(withRouter(connect(mapStateToProps, {
})(
    (Index)
)))
