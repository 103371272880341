import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"
import {NotificationManager} from "react-notifications";


const findProfileCompanyAPI = data => get(url.GET_PROFILE_COMPANIES.concat('/').concat(data))
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })



export {
    findProfileCompanyAPI
}
