import { takeEvery, put, call, takeLatest } from "redux-saga/effects"

import {
    GET_PROMOTION_PRODUCT_BY_SLUG,
    GET_PROMOTIONS_PRODUCTS_BY_SECTION_CATEGORY,
} from "./actionTypes"


import {
    getPromotionProductBySlugSuccess,
    getPromotionsProductsBySectionCategorySuccess,
    apiGetPromotionsProductsError
} from "./actions"


import {
    findPromotionProductBySlugAPI,
    findPromotionsProductsBySectionCategoryAPI
} from "../../helpers/promotion_product_helper"


function* getPromotionProductBySlug({ payload: { data } }) {

    try {

        const response = yield call(findPromotionProductBySlugAPI, data)
        yield put(getPromotionProductBySlugSuccess(response))

    } catch (error) {
        yield put(apiGetPromotionsProductsError(error))
    }
}

function* getPromotionsProductsBySectionCategory({ payload: { data } }) {

    try {

        const response = yield call(findPromotionsProductsBySectionCategoryAPI, data)
        yield put(getPromotionsProductsBySectionCategorySuccess(response))

    } catch (error) {
        yield put(apiGetPromotionsProductsError(error))
    }
}

function* PromotionProductSaga() {
    yield takeEvery(GET_PROMOTION_PRODUCT_BY_SLUG, getPromotionProductBySlug)
    yield takeEvery(GET_PROMOTIONS_PRODUCTS_BY_SECTION_CATEGORY, getPromotionsProductsBySectionCategory)
}

export default PromotionProductSaga
