import React, { Component } from "react"
import PropTypes from "prop-types"
import { Stack } from "react-bootstrap";
import ImageURI from "components/ImageURI";
import { check_open_company, get_img_logo_company, get_name_company } from "logic/storageAPI";
import { ScrollMenu } from "react-horizontal-scrolling-menu"



class Index extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }


    render() {
        const { showing_search_view, rows_data } = this.props

        return (
            <nav id="navbar_top"
                className={!showing_search_view ? "navbar navbar-expand-lg navbar-dark w-100 " : "navbar navbar-expand-lg navbar-dark  box-top-info-company-fixes-top"}>

                <div className={!showing_search_view ? "box-top-info-company border border-1" : ""}>
                    {
                        showing_search_view
                            ?
                            <div className="p-2">
                                <Stack gap={2}>
                                    <div className="w-100">
                                        <span className="font-got-blk-20-400h">{get_name_company()}</span>
                                        <p>
                                            {
                                                check_open_company() === 0
                                                    ?
                                                    <span
                                                        className="badge rounded-pill bg-danger mt-2">Estamos Fechados </span>
                                                    :
                                                    <span
                                                        className="badge rounded-pill bg-success mt-2">Estamos Abertos </span>
                                            }
                                        </p>
                                    </div>
                                    <div>
                                        
                                        <ScrollMenu>

                                            {
                                                rows_data && rows_data.length > 0 &&
                                                rows_data.map((item, index) => (

                                                    item.itens.length > 0 &&
                                                    <div key={index} >
                                                        <div key={index}
                                                            className="item-grid-size">
                                                            <button id={item.slug} name={item.slug}
                                                                onClick={() => {
                                                                    document.querySelector(`[id=${item.slug}]`).scrollIntoView()
                                                                }}
                                                                className="font-got-blk-13-400h  text-center app-categ-btn-susp">{item.name.toUpperCase()}
                                                            </button>
                                                        </div>
                                                    </div>


                                                ))}

                                        </ScrollMenu>

                                    </div>

                                </Stack>
                            </div>
                            :

                            <Stack gap={2} direction="horizontal">
                                <div>
                                    <ImageURI
                                        style_div="img-square-wrapper rounded-2"
                                        style_img="float-end rounded-2 img-home-logo img-fluid"
                                        url={get_img_logo_company()}
                                        alt={get_name_company()} />
                                </div>
                                <div>
                                    <Stack gap={2}>
                                        <div className="w-100">
                                            <span className="font-got-blk-20-400h">{get_name_company()}</span>
                                            <p>
                                                {
                                                    check_open_company() === 0
                                                        ?
                                                        <span
                                                            className="badge rounded-pill bg-danger mt-2">Estamos Fechados </span>
                                                        :
                                                        <span
                                                            className="badge rounded-pill bg-success mt-2">Estamos Abertos </span>
                                                }
                                            </p>
                                        </div>
                                        <div>

                                            <p>(Estamos  em desenvolvimento!)</p>

                                        </div>

                                    </Stack>
                                </div>
                            </Stack>
                    }

                </div>
            </nav>
        )
    }
}

Index.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    image: PropTypes.string,
    app_id: PropTypes.string,
    showing_search_view: PropTypes.any,
    rows_data: PropTypes.any,
}

export default Index