import { takeEvery, put, call, takeLatest } from "redux-saga/effects"
import { NotificationManager } from "react-notifications";
import toast from 'react-hot-toast';
import {
    CREATE_OR_UPDATE_ORDER,
    GET_ORDER,
} from "./actionTypes"


import {
    createOrUpdateOrderSuccess,
    getOrderSuccess,
    apiCreateOrUpdateOrderError
} from "./actions"


import {
    postCreateOrderCartSession,
    getOrderCartSession
} from "../../helpers/orders_helper"
import { create_preference } from "logic/storageAPI";
import { CART_STORAGE_SERVICE_CACHE_API } from "logic/keyStorageAPI";


function* getOrder({ payload: { params } }) {
    try {

        const response = yield call(getOrderCartSession, params)

        yield put(getOrderSuccess(response))

    } catch (error) {
        yield put(apiCreateOrUpdateOrderError(error))
    }
}

function* createOrUpdateOrder({ payload: { params } }) {
    try {

        const response = yield call(postCreateOrderCartSession, params)
        
        const { error, message, key } = response
        if (error == false && key != undefined) {
            create_preference(key, CART_STORAGE_SERVICE_CACHE_API.intent)

        }
        yield put(createOrUpdateOrderSuccess({ error, message, key }))



    } catch (error) {
        yield put(apiCreateOrUpdateOrderError(error))
    }
}


function* createOrEditOrdersSaga() {
    yield takeEvery(CREATE_OR_UPDATE_ORDER, createOrUpdateOrder)
    yield takeEvery(GET_ORDER, getOrder)
}

export default createOrEditOrdersSaga
