import React from "react";
import { Redirect } from "react-router-dom";

// Menu

import Progress from "../pages/OrderProgress/Index"
import Merchant from "../pages/Mobile/Merchant/index"
import Promotion from "../pages/Mobile/Promotion/index"
//import News from "./../pages/Mobile/HomeNews/UIHomeNews"
import Cart from "./../pages/Mobile/Cart/index"
import PolicyReturn from "./../pages/Mobile/Policy/Return/Index"
import PolicyPrivacy from "./../pages/Mobile/Policy/Privacy/Index"
import PolicyUse from "./../pages/Mobile/Policy/Use/Index"
import PolicyDelivery from "./../pages/Mobile/Policy/Delivery/Index"
import PolicyPayment from "./../pages/Mobile/Policy/Payment/Index"
import Policies from "./../pages/Mobile/Policy/options/Index"

import ChatTermUsePolicies from "./../pages/Mobile/Policy/Chat/use/Index"
import ChatTermSecurityPolicies from "./../pages/Mobile/Policy/Chat/security/Index"
import ChatTermAcceptablePolicies from "./../pages/Mobile/Policy/Chat/acceptable-use/Index"
import ChatTerms from "./../pages/Mobile/Policy/Chat/options/Index"

import CatalogContacts from "./../pages/Mobile/Policy/Contact/Index"

import Splash from "../pages/Mobile/Splash/index"
//import Linker from "./../pages/Linker/Index"

//import LP from "./../pages/LandingPage/Index"


const authProtectedRoutes = [

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  // { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];

const publicRoutes = [ 

  //{ path: "/lp", exact: true, component: LP },
  { path: "/app", exact: true, component: Splash },
  { path: "/contacts", component: CatalogContacts },
  { path: "/policies", component: Policies },
  { path: "/policy-delivery", component: PolicyDelivery },
  { path: "/policy-payment", component: PolicyPayment },
  { path: "/policy-use", component: PolicyUse },
  { path: "/policy-privacy", component: PolicyPrivacy },
  { path: "/return-policy", component: PolicyReturn },
  { path: "/chat-terms", component: ChatTerms },
  { path: "/chat-use-policy", component: ChatTermUsePolicies },
  { path: "/chat-security-policy", component: ChatTermSecurityPolicies },
  { path: "/chat-acceptable-policy", component: ChatTermAcceptablePolicies },
  { path: "/cart", component: Cart }, 
  { path: "/merchant", component: Merchant },
  { path: "/promotion", component: Promotion },
  //{ path: "/news", component: News },
  //  { path: "/app", component: Menu },
  { path: "/progress/:entity", component: Progress },
  { path: "/", exact: true, component: () => <Redirect to="/app" /> }
];

export { authProtectedRoutes, publicRoutes };
