import React, { Component } from "react"
import { Card, CardBody, CardText, CardTitle, Col, Row } from "reactstrap"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import UILoadingAnim from "../Mobile/Components/UILoadingAnim"
import MobileDetect from 'mobile-detect';

class Index extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error: false,
            message: '',
            cod: ''
        }



    }

    componentDidMount() {
        const { entity } = this.props.match.params
        let fields = atob(entity)
        const { error, msg, cod } = JSON.parse(fields)
        this.setState({ error, message: msg, cod })

    }
    mobDetect() {
        let md = new MobileDetect(window.navigator.userAgent);
        console.log(md.os());

        switch (md.os()) {
            case 'iOS':
                window.location.replace('https://apps.apple.com/br/app/pizzaria-fontanella/id1667427461');
                break;
            case 'AndroidOS':
                window.location.replace('https://play.google.com/store/apps/details?id=br.com.pizzariafontanella.app');
                break;
            default:
                window.location.replace('https://cardapio.pizzariafontanella.com.br/');
                break;

        }
    }


    render() {
        //console.log('error order ', this.props.error)
        //console.log('error order ', this.props.message)
        const { error, message, cod } = this.state
        return (
            <React.Fragment className="bg-white p-2">

                <Row className="p-3">
                    <Col sm={12}>
                        <div className="text-center  mb-3 mt-3">
                            <h4 className="card-title">Pedido andamento</h4>
                            <small>em Pizzaria Fontanella</small>
                        </div>
                    </Col>
                    <Col sm={12} className="m-2">
                        <div style={{
                            height: '400px !important',
                            margin: '400px'

                        }}>
                            <UILoadingAnim
                                url_anim={5} />
                        </div>
                    </Col>
                    <Col sm={12}>
                        <div className="text-center  mb-4">
                            <h2 className="card-subtitle mb-2">Seu pedido é #{cod}</h2>
                            <h5 className="card-title">{message}</h5>
                            <a onClick={() => { this.mobDetect() }}
                                className="btn btn-lg btn-block btn-danger btn-size mt-4">
                                <h4 className="font-bl-13-700h text-center pt-1 text-light">Baixar aplicativo</h4>
                            </a>
                        </div>
                    </Col>

                </Row>

            </React.Fragment>

        )
    }
}

Index.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
    loading: PropTypes.any,
    match: PropTypes.any,



}
const mapStateToProps = state => {
    return {}
}
export default withTranslation()(withRouter(connect(mapStateToProps, {

})(
    (Index)
)))
