import React from "react"
import {Button, Col, Input, Modal} from "reactstrap"
import PropTypes from "prop-types"
import {withTranslation} from "react-i18next"
import {withRouter} from "react-router-dom"
import {connect} from "react-redux"
import ModalContentType1 from "./ModalContentType1"
import ModalContentType2 from "./ModalContentType2"
import NumberFormat from "../../components/NumberFormat"
import {Row} from "react-bootstrap"
import Autocomplete from "../../components/Autocomplete"
import {GET_GEO_SEARCH_ADDRESS} from "../../helpers/url_helper"
import {reverseCoordinates, searchAddress} from "../../store/search/location/actions"
import {GoogleApiWrapper, InfoWindow, Map, Marker} from "google-maps-react"
import {google_maps} from "../../config"

const LoadingContainer = () => <div>Loading...</div>

/**
 * Modal para editar endereço de entrega.
 */
class ModalAddressDeliveryFactory extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            coordinates: {lat: -22.5625776, lng: -47.3862292},
            makerTitle: 'Pizzaria Fontanella',
            postal_code: '',
            address: "",
            number: '',
            complement: ''
        }

        this.handleFields = this.handleFields.bind(this)
        this.handleAutocomplete = this.handleAutocomplete.bind(this)
    }

    componentDidMount() {
        if(this.props.data){
            const {address, number, complement} = this.props.data
            this.setState({address, number, complement})
        }

    }

    handleFields(event) {

        const {name, value} = event.target

        let tempState = {}
        tempState[name] = value

        this.setState(tempState)

    }

    handleAutocomplete(_data) {

        if (_data) {
            const {value, name, coordinates, postal_code} = _data
            
            this.setState({coordinates,postal_code, makerTitle: 'Seu Endereço'})

            let newState = {}

            newState[name] = value

            this.setState(newState)
        }
    }

    render() {

        return <Modal
            isOpen={this.props.oPenAddressEditFactoryModal}
            role="dialog"
            autoFocus={true}
            data-toggle="modal"
            centered
            size={100}
            fullscreen="lg"
            toggle={this.props.toggleModal}
        >
            <div className="modal-content">
                <div className="modal-header border-bottom-0">
                    <h4 className="font-got-lt-14-300h text-start">
                        <i className="mdi mdi-lead-pencil mdi-16px mx-1"/>
                        Editar endereço de entrega
                    </h4>
                    <button type="button" className="btn-close" onClick={this.props.toggleModal} data-bs-dismiss="modal"
                            aria-label="Close"></button>

                </div>
                <div className="modal-body">
                    <Row>
                        <Col sm={12}>
                            <div className="text-center  mb-4">
                                <h4 className="card-title">Nos informe em qual endereço iremos fazer a entrega.</h4>
                            </div>
                        </Col>
                        <Col sm={12}>
                            <p className="card-text font-bl-12-400h">É muito importante que todas informações
                                preenchidas sejam verdadeiras, para segurança durante a entrega de sua encomenda.</p>
                        </Col>

                        <Col sm={12} className="mt-4">
                            <Autocomplete
                                onSourceSelect={this.handleAutocomplete}
                                inputProps={{
                                    id: 'address',
                                    label: this.props.t("Complete Address"),
                                    placeholder: this.props.t("Complete Address to search"),
                                    value: this.state.address,
                                    url: GET_GEO_SEARCH_ADDRESS
                                }}/>
                        </Col>
                        <Col sm={4} className="mt-2">
                            <div className="form-group">
                                <label htmlFor="number" className="control-label">Nº</label>
                                <input className="form-control" type="text" id="number" name="number"
                                       onChange={this.handleFields} value={this.state.number}/>
                            </div>
                        </Col>
                        <Col className="mt-2">
                            <div className="form-group">
                                <label htmlFor="complement"
                                       className="control-label">{this.props.t("Complement")}</label>
                                <input className="form-control" type="text" id="complement" name="complement"
                                       onChange={this.handleFields} value={this.state.complement}/>
                            </div>
                        </Col>
                        <Col sm={12} className="d-grid gap-2 mt-3">
                            <button type="submit" className="btn btn-sm btn-block btn-dark mt-1"
                                    onClick={() => {
                                        window.localStorage.setItem("addressPickupDelivery", btoa(JSON.stringify({
                                            deliveryAddress: {
                                                number: this.state.number,
                                                complement: this.state.complement,
                                                coordinates: this.state.coordinates,
                                                address: this.state.address,
                                                postal_code: this.state.postal_code
                                            }
                                        })))
                                        window.dispatchEvent(new Event("storage"))
                                        this.props.toggleModal()
                                    }}>
                                <h4 className="font-bl-13-700h text-center pt-1 text-light">Atualizar</h4>
                            </button>
                        </Col>
                    </Row>
                </div>
                {/*<Row>
                    <Col sm={8}><div className="modal-body">
                        <Row>
                            <Col sm={12}>
                                <div className="text-center  mb-4">
                                    <h4 className="card-title">Nos informe em qual endereço iremos fazer a entrega.</h4>
                                </div>
                            </Col>
                            <Col sm={12}>
                                <p className="card-text font-bl-12-400h">É muito importante que todas informações preenchidas, são verdadeiras, para segurança durante a entrega de sua encomenda.</p>
                            </Col>

                            <Col sm={12} className="mt-4">
                                <Autocomplete
                                    onSourceSelect={this.handleAutocomplete}
                                    inputProps={{
                                        id: 'address',
                                        label: this.props.t("Complete Address"),
                                        placeholder: this.props.t("Complete Address to search"),
                                        value: this.state.address,
                                        url: GET_GEO_SEARCH_ADDRESS
                                    }}/>
                            </Col>
                            <Col sm={4} className="mt-2">
                                <div className="form-group">
                                    <label htmlFor="number" className="control-label">Nº</label>
                                    <input className="form-control"   type="text"  id="number" name="number" onChange={this.handleFields} value={this.state.number}/>
                                </div>
                            </Col>
                            <Col className="mt-2">
                                <div className="form-group">
                                    <label htmlFor="complement" className="control-label">{this.props.t("Complement")}</label>
                                    <input className="form-control"  type="text"  id="complement" name="complement" onChange={this.handleFields} value={this.state.complement}/>
                                </div>
                            </Col>
                        </Row>
                    </div></Col>
                    <Col sm={4}>
                        <Col sm={12} className="mt-3">
                            <div id="gmaps-makers" className="gmaps"
                                 style={{position: 'relative'}}>
                                <Map
                                    google={this.props.google}
                                    style={{width: '100%', height: 300}}
                                    zoom={14}
                                    loaded={true}
                                    center={this.state.coordinates}
                                    initialCenter={this.state.coordinates}
                                >
                                    <Marker
                                        position={this.state.coordinates}
                                        name={this.state.makerTitle}
                                    />
                                </Map>
                            </div>
                        </Col>
                    </Col>
                </Row>*/}


            </div>
        </Modal>
    }
}

ModalAddressDeliveryFactory.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
    refresh: PropTypes.any,
    type: PropTypes.string,
    loading: PropTypes.any,
    toggleModal: PropTypes.func,
    oPenAddressEditFactoryModal: PropTypes.bool,
    params: PropTypes.any,

    google: PropTypes.object,
    loading_map: PropTypes.any,
    coordinates: PropTypes.object,
    address: PropTypes.any,
    id: PropTypes.number,
    lat: PropTypes.any,
    lng: PropTypes.any,
    getParamsModal: PropTypes.func,
    rows: PropTypes.array,
    funcParamResponsible: PropTypes.func,
    previousPage: PropTypes.func,
    searchAddress: PropTypes.func,
    reverseCoordinates: PropTypes.func,
    funcParamAddress: PropTypes.func,
    data: PropTypes.object

}
const mapStateToProps = state => {
    const {loading_map, address, coordinates} = state.GeoLocation
    return {loading_map, address, coordinates}
}
export default withTranslation()(withRouter(connect(mapStateToProps, {
    reverseCoordinates,
    searchAddress,
})(
    GoogleApiWrapper({
        apiKey: google_maps.token,
        LoadingContainer: LoadingContainer,
        v: "3",
    })(ModalAddressDeliveryFactory)
)))
