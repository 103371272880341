import {
    GET_PROMOTION_PRODUCT_BY_SLUG,
    GET_PROMOTION_PRODUCT_BY_SLUG_SUCCESS,
    GET_PROMOTIONS_PRODUCTS_BY_SECTION_CATEGORY,
    GET_PROMOTIONS_PRODUCTS_BY_SECTION_CATEGORY_SUCCESS,
    GET_PROMOTION_PRODUCTS_API_ERROR
} from "./actionTypes"


const initialState = {
    loading: false,
    error: false,
    message: '',

    row: null,
    id: 0,
}

const promotionsProducts = (state = initialState, action) => {

    switch (action.type) {
        case GET_PROMOTION_PRODUCT_BY_SLUG:
        case GET_PROMOTIONS_PRODUCTS_BY_SECTION_CATEGORY:
            state = {
                ...state,
                loading: true
            }
            break
        case GET_PROMOTION_PRODUCT_BY_SLUG_SUCCESS:
        console.log('promo slug ', action.payload.result)    
        state = {
                ...state,
                message: action.payload.message,
                error: action.payload.error,
                row: action.payload.result,
                loading: false
            }
            break
        case GET_PROMOTIONS_PRODUCTS_BY_SECTION_CATEGORY_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                error: action.payload.error,
                rows: action.payload.results,
                loading: false
            }
            break
        case GET_PROMOTION_PRODUCTS_API_ERROR:
            state = { ...state, error: action.payload, loading: true }
            break
        default:
            state = { ...state }
            break
    }

    return state
}
export default promotionsProducts
