import { takeEvery, put, call, takeLatest } from "redux-saga/effects"
import { NotificationManager } from "react-notifications";
import toast from 'react-hot-toast';
import {
    CREATE_ORDERS,
    SEARCH_FEE_DELIVERY
} from "./actionTypes"


import {
    createOrderSuccess,
    searchFeeDeliverySuccess,
    apiCreateOrEditOrderError
} from "./actions"


import {
    postCreateOrder,
    getFeeDeliveryByRadius
} from "../../helpers/orders_helper"
import { clearAllCartCacheStorageLocal } from "logic/storageAPI";


function* searchFeedDelivery({ payload: { params } }) {
    try {

        const response = yield call(getFeeDeliveryByRadius, params)

        const { error, message, result } = response;
       /* if (error == false) {
            window.localStorage.setItem('tab_fee_delivery', btoa(JSON.stringify(result)))
            window.dispatchEvent(new Event("storage"))
        }*/

        yield put(searchFeeDeliverySuccess(response))

    } catch (error) {
        yield put(apiCreateOrEditOrderError(error))
    }
}

function* createOrders({ payload: { params, history } }) {
    try {

        const response = yield call(postCreateOrder, params)
        const { message, cod, error } = response;

        yield put(createOrderSuccess({ message, cod, error }))
        toast.success('Pedido realizado com sucesso!')
        clearAllCartCacheStorageLocal()
        let token = btoa(JSON.stringify({ msg: message, cod: cod, error: error }))
        history.push(`/progress/${token}`)
        // NotificationManager.info(message, 'Aviso');
        //



    } catch (error) {
        yield put(apiCreateOrEditOrderError(error))
    }
}


function* createOrEditOrdersSaga() {
    yield takeEvery(CREATE_ORDERS, createOrders)
    yield takeEvery(SEARCH_FEE_DELIVERY, searchFeedDelivery)
}

export default createOrEditOrdersSaga
