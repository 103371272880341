import React, { Component } from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import SEO from "components/SEO/index"
import BodyAndBarApp from "../../../../../components/BodyAndBarApp/Index"

class Index extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            cache: true
        }
    }


    render() {
        const { result } = this.props

        return (
            <BodyAndBarApp
                visibleCart={false}
                icon_back={"mdi-arrow-left"}
                title="Políticas de Uso Aceitável"
            >
                <div className="pr-4 px-4">

                    <h1 className="mb-3 text-center">Políticas de Uso Aceitável</h1>
                    <p><strong className="text-center">Cardápio Digital</strong></p>
                    <p><strong>Pizzaria Fontanella</strong></p>
                    <p><strong>CNPJ: 43.612.624/0001-72</strong></p>
                    <p><strong>Telefone: (19) 99799-1650 / (19) 3500-3907</strong></p>
                    <p><strong>E-mail: <a href="mailto:sac@pizzariafontanella.com.br" target="_new">sac@pizzariafontanella.com.br</a></strong></p>
                    <p><strong>Última Atualização: 08/01/2024 10:36</strong></p>

                    <p>Esta política de de Uso para Chat descreve os termos e condições relacionados aos serviços de automação para os canais de atendimento. Ao iniciar um atendimento através dos canais de comunicaçao, você concorda com os termos e condições descritos nesta política.</p>

                    <p><strong>1. Comportamento Aceitável:</strong></p>

                    <p>Esperamos que os clientes interajam de maneira respeitosa e cortês durante as conversas no chat, evitando comportamentos prejudiciais ou abusivos.</p>

                    <p><strong>2. Conteúdo Aceitável:</strong></p>

                    <p>Proibimos a inclusão de conteúdo ofensivo, discriminatório ou ilegal nas interações, garantindo um ambiente seguro para todos.</p>

                    <p><strong>3. Uso Responsável:</strong></p>

                    <p>Os recursos fornecidos, incluindo o chat, devem ser utilizados de maneira responsável, evitando qualquer forma de uso indevido.</p>

                    <p><strong>5. Sanções por Violação:</strong></p>

                    <p>Comprometemo-nos a seguir todas as leis e regulamentos aplicáveis durante interações e coleta de dados.</p>

                    <p><strong>5. Notificação de Violação:</strong></p>

                    <p>Reservamo-nos o direito de aplicar sanções, incluindo restrição de acesso, em caso de violação das políticas de uso aceitável.</p>

                    <p>Nos reservamos o direito de atualizar esta política periodicamente. As alterações serão publicadas em nosso cardápio digital.</p>
                </div>
            </BodyAndBarApp>
        )
    }
}

Index.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
    loading: PropTypes.bool,
    error: PropTypes.any,
    message: PropTypes.any,
    result: PropTypes.object,
    apiFindHomeProfileCompanyError: PropTypes.func,
    findHomeProfileCompany: PropTypes.func,
    pageSelector: PropTypes.func


}
const mapStateToProps = state => {

    return {}
}
export default withTranslation()(withRouter(connect(mapStateToProps, {
})(
    (Index)
)))
