import React, { Component } from "react"
import { Card, CardBody, Col, Row } from "reactstrap";
import Stack from 'react-bootstrap/Stack';
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import NumberFormat from "../../components/NumberFormat";
import ImageURI from "../../components/ImageURI";
import slugify from 'react-slugify';


class UICardViewComplementSingle extends Component {
    constructor(props) {
        super(props)
        this.state = {}

    }

    render() {

        return (
            <Col>
                <Stack direction="horizontal" gap={3}>
                    <div>
                        <Stack gap={3}>
                            <div><h4 className=" text-start font-got-lt-16-400h fw-bold">{this.props.name}</h4></div>
                            <div><span className=" text-start font-got-lt-11-300h fw-bold">{this.props.description}</span></div>
                            <div><p className="card-text text-start font-got-blk-14-400h fw-bold text-success">

                                {
                                    this.props.price > 0.0 &&
                                    <>
                                        + <NumberFormat value={this.props.price} />
                                    </>
                                }

                                {
                                    this.props.price == 0.0 &&
                                    <p className="card-text text-start font-got-blk-14-400h  text-success">
                                        Grátis</p>
                                }
                            </p></div>
                        </Stack>
                    </div>

                    <div>
                        <Stack direction="horizontal" gap={2}>


                            <ImageURI
                                style_div="img-square-wrapper"
                                style_img="img_prod_list_type_1 p-2 rounded"
                                url={item.url_image} alt={item.name} />

                            {/*<div><img className="img_prod_list_type_1 p-2 rounded-2" src={item.url_image} alt={item.name} /> </div>*/}

                            <div>
                                <input
                                    className="form-check-input radio_custom"
                                    type="radio"
                                    id={slugify(item.name.toLowerCase())}
                                    name={slugify(item.name.toLowerCase())}
                                    value={this.props.index}
                                    onChange={() => {
                                        document.querySelector('[id="observation"]').scrollIntoView()
                                    }}
                                />
                            </div>
                        </Stack>
                    </div>
                </Stack>
            </Col>

        )
    }
}

UICardViewComplementSingle.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
    refresh: PropTypes.any,
    loading: PropTypes.any,
    name: PropTypes.any,
    description: PropTypes.any,
    price: PropTypes.any,
    index: PropTypes.number


}
const mapStateToProps = state => {
    return {}
}
export default withTranslation()(withRouter(connect(mapStateToProps, {


})(
    (UICardViewComplementSingle)
)))
