import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"
import { NotificationManager } from "react-notifications";


const findPromotionProductBySlugAPI = data => get(url.GET_PROMOTION_PRODUCT_BY_SLUG.concat('/').concat(data))
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const findPromotionsProductsBySectionCategoryAPI = data => get(url.GET_PROMOTIONS_PRODUCTS_BY_SECTION_CATEGORY.concat('/').concat(data))
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

export {
    findPromotionProductBySlugAPI,
    findPromotionsProductsBySectionCategoryAPI
}
