import {
    GEO_REVERSE_COORDINATES,
    GEO_REVERSE_COORDINATES_SUCCESS,
    GEO_DISTANCE_COORDINATES,
    GEO_DISTANCE_COORDINATES_SUCCESS,
    SEARCH_ADDRESS,
    SEARCH_ADDRESS_SUCCESS,
    API_ERROR,
} from "./actionTypes"

export const distanceCoordinates = params => {
    return {
        type: GEO_DISTANCE_COORDINATES,
        payload: {params},
    }
}

export const distanceCoordinatesSuccess = params => {
    return {
        type: GEO_DISTANCE_COORDINATES_SUCCESS,
        payload: {params},
    }
}

export const reverseCoordinates = params => {
    return {
        type: GEO_REVERSE_COORDINATES,
        payload: {params},
    }
}

export const reverseCoordinatesSuccess = params => {
    return {
        type: GEO_REVERSE_COORDINATES_SUCCESS,
        payload: {params},
    }
}

export const searchAddress = params => {
    return {
        type: SEARCH_ADDRESS,
        payload: {params},
    }
}

export const searchAddressSuccess = params => {
    return {
        type: SEARCH_ADDRESS_SUCCESS,
        payload: params,
    }
}

export const apiLocationError = error => {
    return {
        type: API_ERROR,
        payload: error,
    }
}

