import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"
import Catalog from "./catalog/home/reducer"
import ProfileCompany from "./company/reducer"
import ProductsCategory from "./catalog/productsByCategory/reducer"
import ProductsCategorySize from "./catalog/productsByCategorySize/reducer"
import PaymentMethods from "./catalog/paymentMethods/reducer"
import CouponsDiscounts from "./catalog/coupomDiscounts/reducer"
import OrderCart from "./order/reducer"
import GeoLocation from "./search/location/reducer"

import Promotion from "./promotion/reducer"

import CartSessionAPI from "./cartSession/reducer"


const rootReducer = combineReducers({
  // public
  Layout,
  ProfileCompany,
  GeoLocation,
  Catalog,
  ProductsCategory,
  ProductsCategorySize,
  PaymentMethods,
  CouponsDiscounts,
  OrderCart,
  CartSessionAPI,
  Promotion
})

export default rootReducer
