import React, { Component } from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { Link, withRouter } from "react-router-dom"
import { connect } from "react-redux"
import BodyAndBarApp from "../../../../components/BodyAndBarApp/Index"
import { Card, CardBody, CardTitle } from "reactstrap"

class Index extends Component {
    constructor(props) {
        super(props)
        this.state = {
            menus: [
                {
                    id: 1,
                    title: "Política de Uso",
                    path: 'policy-use'
                },
                {
                    id: 2,
                    title: "Política e Termos de uso (Chat)",
                    path: 'chat-terms'
                },
                {
                    id: 3,
                    title: "Política de Privacidade",
                    path: 'policy-privacy'
                },
                {
                    id: 4,
                    title: "Política de Devolução",
                    path: 'return-policy'
                },
                {
                    id: 5,
                    title: "Política de Pagamento",
                    path: 'policy-payment'
                },
                {
                    id: 6,
                    title: "Política de Entrega",
                    path: 'policy-delivery'
                },
            ]
        }
    }


    render() {
        const { result } = this.props

        return (
            <BodyAndBarApp
                visibleCart={false}
                icon_back={"mdi-arrow-left"}
                title="Nossas Políticas"
            >
                <div className="p-4 mt-5">

                    {
                        this.state.menus.map((item, index) => {
                            return (
                                <Link key={item.id} to={item.path}>
                                    <Card className="ronded border-2 p-2">
                                        <CardBody>
                                            <CardTitle>{item.title}</CardTitle>
                                        </CardBody>
                                    </Card>
                                </Link>
                            )
                        })
                    }
                </div>
            </BodyAndBarApp >
        )
    }
}

Index.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
    loading: PropTypes.bool,
    error: PropTypes.any,
    message: PropTypes.any,
    result: PropTypes.object,
    apiFindHomeProfileCompanyError: PropTypes.func,
    findHomeProfileCompany: PropTypes.func,
    pageSelector: PropTypes.func,
    history: PropTypes.object,


}
const mapStateToProps = state => {

    return {}
}
export default withTranslation()(withRouter(connect(mapStateToProps, {

})(
    (Index)
)))
