import React, { Component } from "react"
import PropTypes from "prop-types"
import { Stack } from "react-bootstrap";



class Index extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }


    render() {
        return (
            <div className="box-top-info-company-inner">
                <Stack gap={2}>
                    <div>
                        <Stack gap={2} direction="horizontal">
                            <div className="app-box-ind avatar-title bg-white  rounded-circle text-primary h3">
                                <i className="mdi mdi-gift"></i>
                            </div>
                            <div>
                                <span className="card-title"> Programa de fidelidade</span>
                            </div>
                        </Stack>
                    </div>
                    <div>
                        <span>
                            A cada R$ 1,00 em compras você ganha 1 ponto que pode ser trocado por prêmios.
                            Os novos clientes ganham automaticamente 10 pontos.
                        </span>
                    </div>
                </Stack>
            </div>
        )
    }
}

Index.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    image: PropTypes.string,
    app_id: PropTypes.string,
}

export default Index