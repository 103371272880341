import React, {Component} from "react"
import {Card, CardBody, Col, Row} from "reactstrap"
import PropTypes from "prop-types"
import {withTranslation} from "react-i18next"
import {withRouter} from "react-router-dom"
import {connect} from "react-redux"
import NumberFormat from "../../components/NumberFormat"

class UICardViewProductCart extends Component {
    constructor(props) {
        super(props)
        this.state = {}

    }

    render() {

        return (
            <Col sm={12} key={this.props.index} >
                <Row>
                    <Col sm={12}>
                        <h2 className="font-bl-13-700h text-start ">{this.props.name}</h2>
                        <Row>
                            <Col><h2 className="font-bl-12-400h text-start  ">{this.props.description}</h2></Col>
                            <Col>
                                <h2 className="font-bl-12-400h text-end  pr-2 text-success">
                                    <strong>{<NumberFormat value={this.props.price}/>}</strong>
                                </h2>
                            </Col>
                        </Row>

                        {
                            <Row>
                                {
                                    this.props.complements &&
                                    this.props.complements.map((item, index) => {
                                        return (<>

                                            <Col>
                                                <h2 className="font-bl-12-400h text-start float-start">{item.description}</h2>
                                            </Col>
                                            <Col>
                                                <h2 className="font-bl-12-400h text-end float-end pr-2 text-success">
                                                    {item.price === 0 ? 'Grátis' : <NumberFormat value={item.price}/>}
                                                </h2>
                                            </Col>

                                        </>)
                                    })
                                }
                            </Row>

                        }
                        {
                            /*
                            <h2 className="font-bl-13-700h text-start">{this.props.name}</h2>
                        <h2 className="font-bl-12-400h text-start">{this.props.description}</h2>
                            */
                            this.props.type === 1 && this.props.edges &&
                            <Row>
                                <Col><h2 className="font-bl-12-400h text-start float-start">+ Borda {this.props.edges.name}</h2>
                                </Col>
                                <Col><h2
                                    className="font-bl-12-400h text-end float-end pr-2 text-success">{this.props.edges.price === 0 ? 'Grátis' :
                                    <NumberFormat value={this.props.edges.price}/>}</h2></Col>
                            </Row>

                        }
                        {
                            this.props.type === 1 && this.props.pastas &&
                            <Row>
                                <Col><h2 className="font-bl-12-400h text-start float-start">+ Massa {this.props.pastas.name}</h2>
                                </Col>
                                <Col><h2
                                    className="font-bl-12-400h text-end float-end pr-2 text-success">{this.props.pastas.price === 0 ? 'Grátis' :
                                    <NumberFormat value={this.props.edges.price}/>}</h2></Col>
                            </Row>

                            /*
                            <Col sm={4}>
                                <h2 className="font-bl-12-400h text-end text-danger pr-2">
                                    <strong>{<NumberFormat  value={this.props.price}/>}</strong>
                                </h2>
                            </Col>
                             */

                        }
                    </Col>

                    <Col sm={12}>
                        <a onClick={() => this.props.remove(this.props.indexGroup, this.props.index)}>

                            <h4 className="font-bl-13-700h text-start text-danger mt-1">
                                <i className="mdi mdi-trash-can mdi-16px"/>
                                Remover
                            </h4>
                        </a>
                    </Col>
                </Row>
                <hr/>
            </Col>
        )
    }
}

UICardViewProductCart.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
    refresh: PropTypes.any,
    loading: PropTypes.any,
    index: PropTypes.any,
    indexGroup: PropTypes.any,
    name: PropTypes.any,
    price: PropTypes.any,
    type: PropTypes.any,
    description: PropTypes.any,
    edges: PropTypes.array,
    pastas: PropTypes.array,
    complements: PropTypes.array,
    remove: PropTypes.func


}
const mapStateToProps = state => {
    return {}
}
export default withTranslation()(withRouter(connect(mapStateToProps, {})(
    (UICardViewProductCart)
)))
