import React, { Component } from "react"
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { ScrollMenu } from "react-horizontal-scrolling-menu"
import UICardPromoImage from './UICardPromoImage'
import { Col } from "react-bootstrap";

class Index extends Component {
    constructor(props) {
        super(props)
        this.state = {}

    }

    render() {
        const { rows } = this.props
        return (
            <Col sm={12}>
                <ScrollMenu>

                    {

                        rows && rows.length > 0 &&
                        rows.map((item, index) => {

                            return <UICardPromoImage one_card={rows.length == 1 ? true : false} key={index} item={item} />

                        })

                    }

                </ScrollMenu>
            </Col>

        )
    }
}

Index.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
    refresh: PropTypes.any,
    loading: PropTypes.any,
    rows: PropTypes.any,


}
const mapStateToProps = state => {
    return {}
}
export default withTranslation()(withRouter(connect(mapStateToProps, {


})(
    (Index)
)))
