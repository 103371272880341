import React from "react";
import { Button, Input, Modal } from "reactstrap";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import NumberFormat from "../../components/NumberFormat";
import ModalContentType3 from "pages/Mobile/Components/ModalContentType3";
import ModalContentType2 from "pages/Mobile/Components/ModalContentType2";
import ModalContentType1 from "pages/Mobile/Components/ModalContentType1";


/**
 * Modal para montagem do pedido.
 */
class Index extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}

    }

    render() {

        return <Modal
            isOpen={this.props.oPenProductsFactoryModal}
            role="dialog"
            autoFocus={true}
            data-toggle="modal"
            
            
            toggle={this.props.toggleModal}
        >
            <div className="modal-content-desk">
                <div className="modal-header border-bottom-0">
                    <span className=" text-center font-got-lt-14-300h">{this.props.params.title}</span>

                    <button type="button" className="btn-close" onClick={this.props.toggleModal} data-bs-dismiss="modal"
                        aria-label="Close"></button>

                </div>

                {
                    this.props.params.type == 1 &&
                        this.props.params.prod_type === 1
                        ?
                        <ModalContentType3
                            key={1}
                            size_height={window.innerHeight}
                            paramsBody={this.props.params}
                            toggleModal={this.props.toggleModal}
                        />
                        :
                        this.props.params.type == 2
                            ?
                            <ModalContentType2
                                key={2}
                                size_height={window.innerHeight}
                                paramsBody={this.props.params}
                                toggleModal={this.props.toggleModal}
                            />
                            :
                            <ModalContentType1
                                key={1}
                                size_height={window.innerHeight}
                                paramsBody={this.props.params}
                                toggleModal={this.props.toggleModal}
                            />
                }


            </div>
        </Modal>
    }
}

Index.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
    refresh: PropTypes.any,
    type: PropTypes.string,
    loading: PropTypes.any,
    toggleModal: PropTypes.func,
    oPenProductsFactoryModal: PropTypes.bool,
    params: PropTypes.any


}
const mapStateToProps = state => {
    return {}
}
export default withTranslation()(withRouter(connect(mapStateToProps, {})(
    (Index)
)))
