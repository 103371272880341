import React, { Component } from "react"
import { Card, CardBody, Col, Row } from "reactstrap";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import NumberFormat from "../../components/NumberFormat";
import Stack from 'react-bootstrap/Stack';
import ImageURI from "components/ImageURI";
import slugify from 'react-slugify';

// finalizar implementação
class UICardViewComplementMultiple extends Component {
    constructor(props) {
        super(props)
        this.state = {}

    }


    render() {

        return (
            <>
                <Col>
                    <Stack direction="horizontal" gap={3} >
                        <ImageURI
                            style_div="img-square-wrapper text-center mb-3"
                            style_img="img_prod_list_type_1 p-2 rounded thumbnail img-responsive m-2"
                            url={this.props.url_image}
                            alt={this.props.name} />
                        {/*<div><img className="img_prod_list_type_1 p-2 rounded thumbnail img-responsive m-2" src={this.props.url_image} alt={this.props.name} /></div>*/}
                        <div className="w-75 text-start">
                            <Stack gap={3} className="text-start">
                                <div ><h4 className=" text-start font-got-lt-16-400h fw-bold">{this.props.name}</h4></div>
                                <div><span className=" text-start font-got-lt-11-300h fw-bold">{this.props.description}</span></div>
                                <div><p className="card-text text-start font-got-blk-14-400h text-success fw-bold text-success">+ {this.props.price > 0.0 ? <NumberFormat value={this.props.price} /> : 'Grátis'}</p></div>
                            </Stack>
                        </div>
                        <div >
                            <div
                                className="mx-2 rounded border border-dark text-center pt-2 mp-select-content">
                                <a
                                    id={slugify("add ".concat(this.props.name.toLowerCase()))}
                                    name={slugify("add ".concat(this.props.name.toLowerCase()))}
                                    onClick={(e) => {
                                        this.props.sub(this.props.index1, this.props.index2)
                                    }}
                                    className="p-2"><i className="bx bx-message-alt-minus"></i></a>
                                <label
                                    className="p-1">{this.props.qtd}</label>
                                <a
                                    id={slugify("sub ".concat(this.props.name.toLowerCase()))}
                                    name={slugify("sub ".concat(this.props.name.toLowerCase()))}

                                    onClick={(e) => {
                                        this.props.add(this.props.index1, this.props.index2)
                                    }}
                                    className="p-2"><i className="bx bx-plus"></i></a>
                            </div>
                        </div>
                    </Stack>
                </Col>
                <Col sm={12}>
                    <hr />
                </Col>
            </>
        )
    }
}

UICardViewComplementMultiple.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
    refresh: PropTypes.any,
    loading: PropTypes.any,
    name: PropTypes.any,
    description: PropTypes.any,
    url_image: PropTypes.any,
    price: PropTypes.any,
    index1: PropTypes.any,
    index2: PropTypes.any,
    disable: PropTypes.bool,
    qtd: PropTypes.number,
    add: PropTypes.func,
    sub: PropTypes.func


}
const mapStateToProps = state => {
    return {}
}
export default withTranslation()(withRouter(connect(mapStateToProps, {


})(
    (UICardViewComplementMultiple)
)))
