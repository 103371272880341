import moment from "moment"
import {
    PROFILE_STORAGE_SERVICE_LOCAL,
    CART_STORAGE_SERVICE_LOCAL,
    CART_STORAGE_ADDRESS_PICKUP_DELIVERY_LOCAL,
    CART_STORAGE_TYPE_PICKUP_DELIVERY_LOCAL,
    CART_STORAGE_RESPONSIBLE_LOCAL,
    CART_STORAGE_COUPOM_LOCAL,
    CART_STORAGE_SERVICE_CACHE_API,
    CART_STORAGE_SERVICE_CACHE_API_TIME_LAST_UPDATE
} from "../logic/keyStorageAPI"


export function get_slug_app_company() {
    return 'pizzaria-fontanella-ltda'
}

export function get_id_app_company() {
    let data = getInStorageCart(PROFILE_STORAGE_SERVICE_LOCAL.intent, true)
    return data.id
}

export function get_img_front_cover_company() {
    let data = getInStorageCart(PROFILE_STORAGE_SERVICE_LOCAL.intent, true)
    return data.url_front_cover

}

export function get_img_logo_company() {
    let data = getInStorageCart(PROFILE_STORAGE_SERVICE_LOCAL.intent, true)
    return data.url_image
}

export function get_name_company() {
    let data = getInStorageCart(PROFILE_STORAGE_SERVICE_LOCAL.intent, true)
    return data.name
}

export function get_qrcode_app() {
    return ""
}

export function get_url_app_in_playstore() {
    return ""
}

export function get_url_app_in_applestore() {
    return ""
}

export function check_open_company() {
    let data = getInStorageCart(PROFILE_STORAGE_SERVICE_LOCAL.intent, true)
    return data.open
}

export function get_rows_menus_company() {
    let data = getInStorageCart(PROFILE_STORAGE_SERVICE_LOCAL.intent, true)
    return data.menus
}

export function get_address_company() {
    const { lat, lng } = getInStorageCart(PROFILE_STORAGE_SERVICE_LOCAL.intent, true)
    return { lat, lng }
}

export function layout_objects_keys_company() {
    let data = getInStorageCart(PROFILE_STORAGE_SERVICE_LOCAL.intent, true)
    return btoa(JSON.stringify({ name_company: data.name, company_id: data.id, load_url_entity: true }));
}

export function create_preference(data, key) {
    window.localStorage.setItem(key, data)
    window.dispatchEvent(new Event("storage"))
}

export function update_session_local_last() {
    const date_time_last_update = moment().format("YYYY-MM-DD HH:mm:ss");
    window.localStorage.setItem(CART_STORAGE_SERVICE_CACHE_API_TIME_LAST_UPDATE.intent, date_time_last_update)
}

export function createCart(props, action) {
    update_session_local_last()
    props.createOrUpdateOrder({
        ACTION_FACTORY: action,
        company_id: get_id_app_company(),
        channel_id: 8
    })

}
export function createCartWithCompany(props, action, company_id) {
    update_session_local_last()
    props.createOrUpdateOrder({
        ACTION_FACTORY: action,
        company_id: company_id,
        channel_id: 8
    })

}
export function createCartWithData(props, data) {
    update_session_local_last()
    props.createOrUpdateOrder(data)

}

/**
 * responsável em criar ou atualizar carrinho de compra
 * na sessão local do navegador.
 * @param {*} data 
 */
export function createCartOrUpdate(data) {
    try {
        let cart_prods_json = JSON.stringify(data)
        let cart_prods_64 = window.btoa(unescape(encodeURIComponent(cart_prods_json)))
        window.localStorage.setItem(CART_STORAGE_SERVICE_LOCAL.intent, cart_prods_64)
        update_session_local_last()
        window.dispatchEvent(new Event("storage"))
    } catch (ex) {
        console.log('error add to local ', ex)
    }
}

/**
 * Responsável em armazenar o endereço local, de acordo com o informado.
 * @param {*} data 
 */
export function createOrUpdatePickupDelivery(data) {
    try {
        let address_json = JSON.stringify(data)
        let address_64 = btoa(address_json)
        window.localStorage.setItem(CART_STORAGE_ADDRESS_PICKUP_DELIVERY_LOCAL.intent, address_64)
        //window.dispatchEvent(new Event("storage"))
    } catch (ex) { }
}

/**
 * Responsável em definir o tipo entrega ou retirada.
 * @param {*} data 
 */
export function createOrUpdateTypeDelivery(data) {
    try {
        let type_delivery_json = JSON.stringify(data)
        let type_delivery_64 = btoa(type_delivery_json)
        window.localStorage.setItem(CART_STORAGE_TYPE_PICKUP_DELIVERY_LOCAL.intent, type_delivery_64)
        //  window.dispatchEvent(new Event("storage"));
    } catch (ex) { }
}

/**
 * Responsável em definir o perfil do customer responsável pela ordem
 * @param {*} data 
 */
export function createOrUpdateResponsible(data) {
    let responsible_json = JSON.stringify(data)
    let responsible_64 = btoa(responsible_json)
    window.localStorage.setItem(CART_STORAGE_RESPONSIBLE_LOCAL.intent, responsible_64)
    //window.dispatchEvent(new Event("storage"))

}

/**
 * Responsável em manter atualizado os cupons aplicados.
 * @param {*} data 
 */
export function createOrUpdateCoupom(data) {
    let coupom_json = JSON.stringify(data)
    let coupom_64 = btoa(coupom_json)
    window.localStorage.setItem(CART_STORAGE_COUPOM_LOCAL.intent, coupom_64)
    // window.dispatchEvent(new Event("storage"))

}

/**
 * Responsável em verificar a existencia de uma amarzenamento local
 * através da chave.
 * @param {*} key 
 * @returns 
 * 
 * caso a checagem seja da storage cart api, verifico a data do ultimo update.
 */
export function checkInStorageCart(key) {
    if (key === CART_STORAGE_SERVICE_CACHE_API.intent) {
        if (window.localStorage.hasOwnProperty(CART_STORAGE_SERVICE_CACHE_API_TIME_LAST_UPDATE.intent)) {
            let last_update = getInStorageCart(CART_STORAGE_SERVICE_CACHE_API_TIME_LAST_UPDATE.intent, false);
            const date_last = moment(last_update).format("YYYY-MM-DD");
            const time_last = moment(last_update).format("HH:mm:ss");
            const diff_minuts = moment().diff(moment(last_update), 'minutes');

            if (date_last === moment().format("YYYY-MM-DD")) {
                if (diff_minuts >= 10) {
                    return false
                }
            }

        } else {
            clearAllCartCacheStorageLocal()
            return false;
        }
    }

    return window.localStorage.hasOwnProperty(key)
}
export function clearAllCartCacheStorageLocal() {
    window.localStorage.removeItem('CART_STORAGE_SERVICE_LOCAL')
    window.localStorage.removeItem('CART_STORAGE_RESPONSIBLE_LOCAL')
    window.localStorage.removeItem('CART_STORAGE_TYPE_PICKUP_DELIVERY_LOCAL')
    window.localStorage.removeItem('CART_STORAGE_ADDRESS_PICKUP_DELIVERY_LOCAL')
    window.localStorage.removeItem('CART_STORAGE_SERVICE_CACHE_API')
    window.localStorage.removeItem('CART_STORAGE_SERVICE_CACHE_API_TIME_LAST_UPDATE')
    window.localStorage.removeItem('cart')
    window.localStorage.removeItem('typeDelivery')
    window.localStorage.removeItem('addressPickupDelivery')
    window.localStorage.removeItem('coupom')
    window.localStorage.removeItem('pr_key')
    window.dispatchEvent(new Event("storage"))
}
export function getInStorageCart(key, decode) {
    try {
        if (decode) {
            var item =  window.localStorage.getItem(key);
            return JSON.parse(atob(item))
        }
        var item =  window.localStorage.getItem(key);
        return item
    } catch (ex) {
        console.log("Error Parser ", ex)
    }
}
export function getInStorageCartNot64(key) {
    return window.localStorage.getItem(key)
}