import {
    FIND_PRODUCTS_BY_ID_CATEGORY_AND_SIZE,
    FIND_PRODUCTS_BY_ID_CATEGORY_AND_SIZE_SUCCESS,
    FIND_PRODUCTS_BY_ID_CATEGORY_AND_SIZE_API_ERROR,
} from "./actionTypes"


const initialState = {
    loading: true,
    loading_form: false,
    error: false,
    message: '',

    products_1: [],
    products_2: [],
    products: [],
    sizes: [],
    edges: [],
    pastas: [],
    id: 0,
}

const productsCategorySize = (state = initialState, action) => {

    switch (action.type) {
        case FIND_PRODUCTS_BY_ID_CATEGORY_AND_SIZE:
            state = {
                ...state,
                loading: true
            }
            break
        case FIND_PRODUCTS_BY_ID_CATEGORY_AND_SIZE_SUCCESS:
            if (parseInt(action.payload.data.type_list) === 1) {
                state = {
                    ...state,
                    message: action.payload.message,
                    error: action.payload.error,
                   // rows: action.payload.data,
                    products_1: action.payload.data.products_1,
                    products_2: action.payload.data.products_2,
                    sizes: action.payload.data.sizes,
                    edges: action.payload.data.edges,
                    pastas: action.payload.data.pastas,
                    loading: false
                }
            } else {
                state = {
                    ...state,
                    message: action.payload.message,
                    error: action.payload.error,
                   // rows: action.payload.data,
                    products: action.payload.data.products,
                    sizes: action.payload.data.sizes,
                    edges: action.payload.data.edges,
                    pastas: action.payload.data.pastas,
                    loading: false
                }
            }
            
            break
        case FIND_PRODUCTS_BY_ID_CATEGORY_AND_SIZE_API_ERROR:
            state = {...state, error: action.payload, loading: false}
            break
        default:
            state = {...state}
            break
    }

    return state
}
export default productsCategorySize
