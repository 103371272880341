import React, { Component } from "react"
import PropTypes from "prop-types"
import { Helmet } from 'react-helmet-async';



class Index extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }


    render() {
        const { title, description, name, type, image, app_id } = this.props
        return (
            <Helmet>
                { /* Standard metadata tags */}
                <title>{title}</title>
                <meta name='description' content={description} />
                { /* End standard metadata tags */}
                { /* Facebook tags */}
                <meta property="og:type" content={type} />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />

                {
                    this.props.image &&
                    <meta property="og:image" content={image} />

                }
                {
                    this.props.app_id &&
                    <meta property="fb:app_id" content={app_id} />

                }
                { /* End Facebook tags */}
                { /* Twitter tags */}
                <meta name="twitter:creator" content={name} />
                <meta name="twitter:card" content={type} />
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={description} />
                { /* End Twitter tags */}
                <meta name="robots" content="noindex" />
            </Helmet>
        )
    }
}

Index.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    image: PropTypes.string,
    app_id: PropTypes.string,
}

export default Index