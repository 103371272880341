import React, { Component } from "react"
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ic_lojas from "../../../assets/img/ic_lojas.png"
import { Player } from "@lottiefiles/react-lottie-player"
import ld_tracking_delivery_01 from '../../../assets/ld_tracking_delivery_01.json';
import ld_tracking_delivery_02 from '../../../assets/ld_tracking_delivery_02.json';
import ld_qrcode from '../../../assets/ld_qrcode.json';
import ld_pulse from '../../../assets/ld_pulse_tracking.json';
import ld_payment_methods from '../../../assets/ld_payment_methods.json';
import ld_order_success from '../../../assets/ld_order_sucess.json';
import ld_dots from '../../../assets/ld_dots.json';
import ld_coupom_discount from '../../../assets/ld_coupom_discount.json';
import ld_compositor_chief_pizza from '../../../assets/ld_compositor_chief_pizza.json';
import ld_pizza_man from '../../../assets/ld_pizza_man.json';


class UILoadingAnim extends Component {
    constructor(props) {
        super(props)
        this.state = {
            anim_list: [
                {
                    id: 1,
                    file: ld_tracking_delivery_01
                },
                {
                    id: 2,
                    file: ld_tracking_delivery_02
                },
                {
                    id: 3,
                    file: ld_qrcode
                },
                {
                    id: 4,
                    file: ld_pulse
                },
                {
                    id: 5,
                    file: ld_payment_methods
                },
                {
                    id: 6,
                    file: ld_order_success
                },
                {
                    id: 7,
                    file: ld_coupom_discount
                },
                {
                    id: 8,
                    file: ld_compositor_chief_pizza
                },
                {
                    id: 9,
                    file: ld_dots
                },
                {
                    id: 10,
                    file: ld_pizza_man
                },
            ]
        }

    }

    render() {

        return (
            <div className="text-light" style={{
                height: 200,
                width: 500,
                position: 'absolute',
                top: this.props.opening ? '40%' : '30%',
                right: 0,
                left: 0,
                margin: 'auto',
                display: 'block',
                backgroundColor: '#FFFFFF  !important',
                transform: 'translate(0, -50%)',

            }}>
                <Player
                    src={this.state.anim_list[this.props.url_anim].file}
                    className="player"
                    loop
                    autoplay
                />
            </div>
        )
    }
}

UILoadingAnim.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
    refresh: PropTypes.any,
    url_anim: PropTypes.any,
    opening: PropTypes.any,


}
const mapStateToProps = state => {
    return {}
}
export default withTranslation()(withRouter(connect(mapStateToProps, {})(
    (UILoadingAnim)
)))
