import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import { NotificationContainer } from "react-notifications";
import toast, { Toaster } from 'react-hot-toast';
import 'react-notifications/lib/notifications.css';
import { BrowserRouter } from "react-router-dom"
import { HelmetProvider } from 'react-helmet-async';
import "./i18n"

import "./assets/custom-theme-menu.css";
import "./assets/font-theme-menu.css";
import "./assets/custom-theme-menu-mobile.css";

import { Provider } from "react-redux"

import store from "./store"
const helmetContext = {};
const app = (
  <HelmetProvider context={helmetContext}>
    <Provider store={store}>
      <BrowserRouter>
        <App />
        <NotificationContainer />
        <Toaster
          position="bottom-center"
          reverseOrder={false}
        />
      </BrowserRouter>
    </Provider>
  </HelmetProvider>
)

ReactDOM.render(app, document.getElementById("root"))
serviceWorker.unregister()
