import React, { Component } from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import SEO from "components/SEO/index"
import BodyAndBarApp from "../../../../components/BodyAndBarApp/Index"

class Index extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            cache: true
        }
    }


    render() {
        const { result } = this.props

        return (
            <BodyAndBarApp
                visibleCart={false}
                icon_back={"mdi-arrow-left"}
                title="Política de Uso"
            >
                <div className="pr-4 px-4">

                    <h1 className="mb-3 text-center">Política de Uso</h1>
                    <p><strong className="text-center">Cardápio Digital</strong></p>
                    <p><strong>Pizzaria Fontanella</strong></p>
                    <p><strong>CNPJ: 43.612.624/0001-72</strong></p>
                    <p><strong>Telefone: (19) 99799-1650 / (19) 3500-3907</strong></p>
                    <p><strong>E-mail: <a href="mailto:sac@pizzariafontanella.com.br" target="_new">sac@pizzariafontanella.com.br</a></strong></p>
                    <p><strong>Última Atualização: 21/10/2023 00:32</strong></p>

                    <p>Esta política de uso descreve os termos e condições para o uso do nosso cardápio digital. Ao acessar ou usar nosso cardápio digital, você concorda com os termos e condições descritos nesta política. Se você não concorda com esses termos, por favor, não utilize o cardápio digital.</p>

                    <p><strong>1. Uso Autorizado</strong></p>

                    <p>Você concorda em usar nosso cardápio digital apenas para fins legais e autorizados. Você não pode usar o cardápio digital para atividades ilegais ou não autorizadas.</p>

                    <p><strong>2. Conteúdo do Cardápio</strong></p>

                    <p>O conteúdo apresentado em nosso cardápio digital é protegido por direitos autorais e outras leis de propriedade intelectual. Você concorda em não copiar, modificar, distribuir ou reproduzir o conteúdo sem autorização.</p>

                    <p><strong>3. Privacidade do Usuário</strong></p>

                    <p>Respeitamos a privacidade dos usuários. Você concorda em cumprir nossa política de privacidade, que descreve como coletamos, usamos e protegemos informações pessoais.</p>

                    <p><strong>4. Segurança</strong></p>

                    <p>Você concorda em não violar ou tentar violar a segurança do cardápio digital, incluindo acesso não autorizado a informações ou sistemas.</p>

                    <p><strong>5. Alterações na Política de Uso</strong></p>

                    <p>Nos reservamos o direito de atualizar esta política de uso periodicamente. As alterações serão publicadas em nosso cardápio digital.</p>

                    <p>Se você tiver alguma dúvida ou preocupação sobre esta política de uso, entre em contato conosco pelo e-mail [Inserir Endereço de E-mail de Contato].</p>
                </div>
            </BodyAndBarApp>
        )
    }
}

Index.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
    loading: PropTypes.bool,
    error: PropTypes.any,
    message: PropTypes.any,
    result: PropTypes.object,
    apiFindHomeProfileCompanyError: PropTypes.func,
    findHomeProfileCompany: PropTypes.func,
    pageSelector: PropTypes.func


}
const mapStateToProps = state => {

    return {}
}
export default withTranslation()(withRouter(connect(mapStateToProps, {
})(
    (Index)
)))
