import React from "react"
import { Button, Col, Input, Modal, Row } from "reactstrap"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { createOrUpdateOrder, apiFindProductsByCategoryError, findProductsByCategory } from "../../../store/actions"
import PerfectScrollbar from "react-perfect-scrollbar"
import NumberFormat from "../../../components/NumberFormat"
import UICardViewComplementSingle from "../../Menu/UICardViewComplementSingle"
import UICardViewComplementMultiple from "../../Menu/UICardViewComplementMultiple"
import UILoadingAnim from "../../Mobile/Components/UILoadingAnim"
import toast from "react-hot-toast"
import ImageURI from "components/ImageURI"
import slugify from 'react-slugify';
import { checkInStorageCart, createCartOrUpdate, createCartWithData, getInStorageCart, getInStorageCartNot64 } from "logic/storageAPI"
import { ACTION_ADD_OR_UPDATE_PRODUCT_ORDER, CART_STORAGE_SERVICE_CACHE_API, CART_STORAGE_SERVICE_LOCAL } from "logic/keyStorageAPI"
import { Stack } from "react-bootstrap"

/**
 * Modal para montagem do pedido lanches pizza que possui diversos complementos.
 */
class ModalContentType2 extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            observation: '',
            valueTot: 0.0,
            qtd: 1,
            refresh: false,
            countSelected: 0,
            product_selected: false,
            complement_selected: false
        }

        this.handleSub = this.handleSub.bind(this)
        this.handleAdd = this.handleAdd.bind(this)
        this.sub = this.sub.bind(this)
        this.add = this.add.bind(this)

        this.handleRadioButtomProducts = this.handleRadioButtomProducts.bind(this)
        this.handleSumValueItems = this.handleSumValueItems.bind(this)
        this.handleAddToCart = this.handleAddToCart.bind(this)
        this.handleFields = this.handleFields.bind(this)

    }

    componentDidMount() {
        this.props.findProductsByCategory(this.props.paramsBody.id)
    }
    handleValidatorFields() {
        const { product_selected, complement_selected, countSelected } = this.state;
        let validator = true;

        /* if (!product_selected) {
             toast.error('Informe a quantidade')
             validator = false
         } else
         if (countSelected > 0) {
             if (!complement_selected) {
                 toast.error('Escolha um produto')
                 validator = false
             }
         }*/
        if (this.state.valueTot === 0.0 || this.state.valueTot < 0.0) {
            toast.error('Escolha um produto/Complemento')
            validator = false
        }


        return validator
    }
    async handleAddToCart() {
        if (this.handleValidatorFields()) {
            const { complements, id, name, description, type } = this.props.row
            //let cart = (window.localStorage.getItem("cart") ? JSON.parse(atob(window.localStorage.getItem("cart"))) : [])
            let cart = (checkInStorageCart(CART_STORAGE_SERVICE_LOCAL.intent) ? getInStorageCart(CART_STORAGE_SERVICE_LOCAL.intent, true) : [])

            //console.log("line 75 ", name)
            let cart_obg = {}
            if (cart.length > 0) {
                await cart.forEach(group => {
                    if (parseInt(group.idGroup) === parseInt(this.props.paramsBody.idGroup)) {
                        cart_obg = group
                    }
                })
            }
            //console.log("cart cart_obg ", cart_obg)
            if (Object.keys(cart_obg).length === 0) {

                cart_obg = {
                    id: this.props.paramsBody.id,
                    idGroup: this.props.paramsBody.idGroup,
                    prod_type: this.props.paramsBody.prod_type,
                    groupName: this.props.paramsBody.nameGroup,
                    price: parseInt(this.props.paramsBody.price_type) === 0 ? this.props.paramsBody.price : 0.0,
                    qtd: this.state.qtd,
                    type: 2,
                    items: [],
                    /* items: [{
                         id: id,
                         name: name,
                         description: description,// this.props.paramsBody.description,
                         qtd: this.state.qtd,
                         price: this.props.paramsBody.price,
                         //total: (parseInt(product.qtd) * parseFloat(product.price))
                         complements: [],
                     }],*/
                    observation: this.state.observation
                }

            }

            let complement_product_selected = []
            if (complements.length > 0) { // se existir complementos
                await complements.forEach(complement => {

                    complement_product_selected = []
                    complement.products.forEach(product => {

                        if (cart_obg.items.length > 0) { // se existir itens dentro do grupo, então atualize
                            cart_obg.items.map((item, index0) => {

                                if (parseInt(item.id) === parseInt(id)) {
                                    item.qtd = (item.qtd + this.state.qtd)
                                }

                                //  console.log('PROD Select ', product.isSelected)
                                if (product.isSelected === true || parseInt(product.qtd) > 0) {
                                    //   console.log('PROD Select ', product.name)
                                    //   console.log('PROD Select ', (cart_obg.items[index0].complements.length > 0))

                                    if (cart_obg.items[index0].complements.length > 0) {
                                        cart_obg.items[index0].complements.map((complement, index) => {

                                            if (parseInt(complement.id) === parseInt(product.id)) {
                                                cart_obg.items[index0].complements[index].qtd = cart_obg.items[0].complements[index].qtd + 1
                                            } else {

                                                if (parseInt(this.props.paramsBody.price_type) === 1) {
                                                    //  console.log('adiconou line 137 ')
                                                    cart_obg.items.push({
                                                        id: product.id,
                                                        name: product.name,
                                                        prod_type: this.props.paramsBody.prod_type,
                                                        qtd: product.qtd,
                                                        price: product.price,
                                                        complements: [],
                                                    })

                                                } else {
                                                    //  console.log('adiconou 148 ')
                                                    complement_product_selected.push({
                                                        id: product.id,
                                                        name: product.name,
                                                        prod_type: this.props.paramsBody.prod_type,
                                                        description: `${product.qtd}x ${product.name}`,
                                                        qtd: product.qtd,
                                                        price: product.price,
                                                        complements: [],
                                                    })
                                                }
                                                /* cart_obg.items[index0].complements.push({
                                                     id: product.id,
                                                     name: product.name,
                                                     qtd: product.qtd,
                                                     price: product.price
                                                 })*/
                                            }
                                        })
                                    } else {
                                        
                                        complement_product_selected.push({
                                            id: product.id,
                                            name: product.name,
                                            prod_type: this.props.paramsBody.prod_type,
                                            description: `${product.qtd}x ${product.name}`,
                                            qtd: product.qtd,
                                            price: product.price,
                                            complements: [],
                                        })
                
                                    }
                                    // console.log('complement_product_selected ', complement_product_selected)
                                }
                            })
                        } else {
                            if (product.isSelected === true && parseInt(product.qtd) > 0
                            ) {

                                complement_product_selected.push({
                                    id: product.id,
                                    name: product.name,
                                    prod_type: this.props.paramsBody.prod_type,
                                    description: `${product.qtd}x ${product.name}`,
                                    qtd: product.qtd,
                                    price: product.price,
                                    complements: [],
                                })

                            }
                        }

                        ///antes era aqui dentro

                    })


                    //cart_obg.items.push({complement_product_selected})

                })

            } else {
                // console.log('Linha 224')
                cart_obg.items.push({
                    id: id,
                    name: name,
                    prod_type: this.props.paramsBody.prod_type,
                    description: `${this.state.qtd}x ${name}`,
                    qtd: this.state.qtd,
                    price: parseInt(this.props.paramsBody.price_type) === 0 ? this.props.paramsBody.price : 0.0,
                    complements: [],
                    //total: (parseInt(product.qtd) * parseFloat(product.price))
                })
            }

            // inicio implementação teste
            if (parseInt(this.props.paramsBody.price_type) === 0) {
                cart_obg.items.push({
                    id: id,
                    name: name,
                    prod_type: this.props.paramsBody.prod_type,
                    description: `${this.state.qtd}x ${name}`,
                    qtd: this.state.qtd,
                    price: parseInt(this.props.paramsBody.price_type) === 0 ? this.props.paramsBody.price : 0.0,
                    complements: complement_product_selected,
                    //total: (parseInt(product.qtd) * parseFloat(product.price))
                })
            }
            if (parseInt(this.props.paramsBody.price_type) === 1) {
                //console.log('line 251')
                if (complement_product_selected.length > 0) {
                    //   console.log('line 253')

                    for (var i = 0; i <= complement_product_selected.length; i++) {
                        const index_cart_p = cart_obg.items.findIndex(e => parseInt(e.id) === parseInt(complement_product_selected[i].id));

                        if (index_cart_p > -1) {
                            //       console.log('line 259 ')

                            cart_obg.items[index_cart_p].qtd += complement_product_selected[i].qtd
                            cart_obg.items[index_cart_p].description = `${cart_obg.items[index_cart_p].qtd}x ${cart_obg.items[index_cart_p].name}`
                            complement_product_selected.splice(i, 1);
                            break;
                        } else {
                            // console.log('line 266')

                            cart_obg.items.push({
                                id: complement_product_selected[i].id,
                                name: complement_product_selected[i].name,
                                prod_type: complement_product_selected[i].prod_type,
                                description: complement_product_selected[i].description,
                                qtd: complement_product_selected[i].qtd,
                                price: complement_product_selected[i].price,
                                complements: [],
                                //total: (parseInt(product.qtd) * parseFloat(product.price))
                            })
                            complement_product_selected.splice(i, 1);
                            break;
                        }
                    }


                }
            }
            // fim implementação teste

            let searchable_group_cart = false
            if (cart.length > 0) {
                cart.map((group, index) => {
                    if (parseInt(group.idGroup) === parseInt(cart_obg.idGroup)) {
                        cart[index] = cart_obg
                        //console.log('CART GROUP ', cart[index])
                        searchable_group_cart = true
                        return
                    }
                })

            }
            if (!searchable_group_cart) {
                cart.push(cart_obg)
            }

            // atualiza o carrinho na sessão do servidor.
            if (checkInStorageCart(CART_STORAGE_SERVICE_CACHE_API.intent)) {
                createCartWithData(this.props, {
                    ACTION_FACTORY: ACTION_ADD_OR_UPDATE_PRODUCT_ORDER.intent,
                    key: getInStorageCartNot64(CART_STORAGE_SERVICE_CACHE_API.intent),
                    product: cart_obg
                })
            }
            createCartOrUpdate(cart)
            this.props.toggleModal()
        }
    }

    handleAdd(idx1, idx2) {
        this.props.row.complements.forEach(element => {
            element.products.forEach(element1 => {
                element1.isSelected = false

            })
        })

        this.props.row.complements[idx1].products[idx2]['qtd'] = parseInt(this.props.row.complements[idx1].products[idx2].qtd ? this.props.row.complements[idx1].products[idx2].qtd : 0) + 1
        this.props.row.complements[idx1].products[idx2]['isSelected'] = !this.props.row.complements[idx1].products[idx2]['isSelected']

        if (parseInt(this.props.row.complements[idx1].products[idx2]['mandatory']) === 1) {
            this.setState({ countSelected: (this.state.countSelected + 1), refresh: true, complement_selected: !this.state.complement_selected })

        } else {
            this.setState({ countSelected: (this.state.countSelected + 1), refresh: true })

        }

    }

    handleSub(idx1, idx2) {
        this.props.row.complements.forEach(element => {
            element.products.forEach(element1 => {
                element1.isSelected = false
            })
        })
        if (parseInt(this.props.row.complements[idx1].products[idx2].qtd) > 1) {
            this.props.row.complements[idx1].products[idx2].qtd = parseInt(this.props.row.complements[idx1].products[idx2].qtd) - 1
            if (this.state.countSelected > 1) {
                this.setState({ countSelected: (this.state.countSelected - 1), refresh: true, complement_selected: !this.state.complement_selected })
            }

        } else {
            this.props.row.complements[idx1].products[idx2].qtd = 0
            this.setState({ countSelected: 0, refresh: true, complement_selected: false })
        }
        this.props.row.complements[idx1].products[idx2]['isSelected'] = !this.props.row.complements[idx1].products[idx2]['isSelected']


    }

    handleFields(event) {
        const { name, value } = event.target
        this.setState({ observation: value })
    }

    handleRadioButtomProducts(event, idx1) {
        //    console.log('SELECIONOU TARGET ',  event.target.value)
        //  console.log('SELECIONOU IDEX  ', idx1)
        this.props.row.complements.forEach(element => {
            element.products.forEach(element1 => {
                element1.isSelected = false

            })
        })
        /* this.props.row.complements[idx1].products.forEach(element => {
             element.isSelected = false
             //element.qtd = 0
         })*/
        this.props.row.complements[idx1].products[parseInt(event.target.value)]['isSelected'] = !this.props.row.complements[idx1].products[parseInt(event.target.value)]['isSelected']
        this.props.row.complements[idx1].products[parseInt(event.target.value)]['qtd'] = 1

        // console.log('SELECIONOU PROD  ', this.props.row.complements[idx1].products[parseInt(event.target.value)])
        if (parseInt(this.props.row.complements[idx1].products[parseInt(event.target.value)]['mandatory']) === 1) {
            this.setState({ refresh: true, complement_selected: !this.state.complement_selected })
        } else {
            this.setState({ refresh: true })
        }


    }

    handleSumValueItems() {
        const { complements } = this.props.row
        let valueTot = parseInt(this.props.paramsBody.price_type) === 0 ? this.props.paramsBody.price : 0.0
        //console.log('row.price ', this.props.paramsBody.price)

        complements.forEach(element => {
            element.products.forEach(el => {
                //  console.log('max_select 1 ', element.max_select)
                if (parseInt(element.max_select) === 1) {
                    if (el.isSelected && parseFloat(el.price) > 0.0) {
                        valueTot += parseFloat(el.price)
                    }
                }
                if (parseInt(element.max_select) > 1) {
                    //console.log('max_select 2 ', element.max_select)
                    if (parseInt(el.qtd) > 0) {
                        if (parseFloat(el.price) > 0.0) {
                            valueTot += (parseFloat(el.price) * parseInt(el.qtd))
                        }
                    }
                }

            })

        })

        valueTot *= this.state.qtd

        //console.log('soma ', valueTot)
        this.setState({ valueTot: valueTot, refresh: true })
        this.forceUpdate()
    }

    add() {
        this.setState({ qtd: (this.state.qtd + 1), refresh: true, product_selected: !this.state.product_selected })
    }

    sub() {
        if (this.state.qtd > 0) {
            this.setState({ qtd: (this.state.qtd - 1), refresh: true })
        } else {
            this.setState({ qtd: 0, refresh: true, product_selected: false })
        }
    }

    render() {
        const { valueTot, refresh } = this.state
        const { row } = this.props

        if (refresh) {
            this.handleSumValueItems()
            this.setState({ refresh: false })
        }

        return (
            <>
                {
                    this.props.loading
                        ?
                        <div style={{
                            height: '700px !important',
                            marginBottom: '400px'

                        }}>
                            <UILoadingAnim
                                url_anim={7} />
                        </div>
                        :
                        <>
<Stack direction="horizontal" gap={2}>
                            <PerfectScrollbar style={{ height: this.props.size_height }}>
                                <div className="mx-3">
                                    <ImageURI
                                        style_div="img-square-wrapper text-center mb-3"
                                        style_img="rounded thumbnail img-responsive img-card-desc"
                                        url={this.props.paramsBody.url_image}
                                        alt="" />
                                    {/*<div className="img-square-wrapper text-center mb-3">
                                        <img className="rounded thumbnail img-responsive img-card-desc"
                                            src={this.props.paramsBody.url_image ? this.props.paramsBody.url_image : PlaceHolder}
                                            alt=" " />
                                    </div>*/}
                                    {

                                        (typeof this.props.paramsBody.nameGroup !== 'undefined') &&
                                        <>
                                            <h5 className=" text-start card-title text-muted">{this.props.paramsBody.nameGroup}</h5>
                                            <h5 className=" text-start card-title font-got-blk-14-400h text-dark mt-2">{row.name}</h5>
                                            <p><small>{row.description}</small></p>
                                            {
                                                parseInt(this.props.paramsBody.price_type) === 0 &&
                                                <p className="text-start mt-2 card-text font-bl-12-400h text-success mt-2">
                                                    <b>{
                                                        <NumberFormat value={this.props.paramsBody.price} />}</b></p>
                                            }


                                        </>
                                    }

                                    {/*<span className="text-dark">{<NumberFormat value={this.props.params.price}/>}</span>*/}
                                </div>
                                {
                                    row &&
                                    row.complements && row.complements.length > 0 &&
                                    row.complements.map((item, index1) => {

                                        return (
                                            <>
                                                <Row className="bg-gray-light mt-2">
                                                    <Col sm={6}>
                                                        <h4 className="card-title font-bl-13-700h">{item.name}</h4>
                                                        <small>Minimo {item.min_select} máximo {item.max_select}</small>
                                                    </Col>
                                                    <Col sm={6} className="text-end pr-2">
                                                        <span
                                                            className="badge bg-dark mt-2 px-2">{item.mandatory === 1 ? 'OBRIGATÓRIO' : 'OPCIONAL'}</span>
                                                    </Col>
                                                </Row>
                                                <div className="modal-body"
                                                    onChange={(e) => (parseInt(row.complements[index1].max_select) === 1 ? this.handleRadioButtomProducts(e, index1) : {})}>

                                                    <Row key={index1}>
                                                        {
                                                            item.products && item.products.length > 0 &&
                                                            item.products.map((itemProd, index2, item) => {
                                                                return (
                                                                    <>
                                                                        {//
                                                                            parseInt(row.complements[index1].max_select) === 1 ?
                                                                                <UICardViewComplementSingle
                                                                                    key={index2}
                                                                                    name={itemProd.name}
                                                                                    description={itemProd.description}
                                                                                    price={itemProd.price}
                                                                                    index={index2}
                                                                                />

                                                                                :

                                                                                <UICardViewComplementMultiple
                                                                                    key={index2}
                                                                                    name={itemProd.name}
                                                                                    description={itemProd.description}
                                                                                    price={itemProd.price}
                                                                                    url_image={itemProd.url_image}
                                                                                    index2={index2}
                                                                                    index1={index1}
                                                                                    disable={false}// implementar regra futuramente this.state.countSelected === parseInt(item.max_select)}
                                                                                    qtd={this.props.row.complements[index1].products[index2].qtd ? this.props.row.complements[index1].products[index2].qtd : 0}
                                                                                    add={this.handleAdd}
                                                                                    sub={this.handleSub}
                                                                                />
                                                                        }
                                                                    </>
                                                                )
                                                            })


                                                        }
                                                    </Row>

                                                </div>
                                            </>
                                        )
                                    })
                                }

                                <div className="modal-body">
                                    <Row>
                                        <Col sm={12}>
                                            <div className="form-group  mb-5 mg-bottom-bar">
                                                <label htmlFor=""
                                                    className="control-label">Alguma Observação?</label>
                                                <textarea className="form-control"
                                                    id="observation"
                                                    rows="3"
                                                    onChange={this.handleFields}
                                                    value={this.state.observation} />

                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </PerfectScrollbar>


                            <Row className="bottom-bar-options w-100">
                                <Col>
                                    <div
                                        className="rounded border border-dark text-center">
                                        <a onClick={this.sub}
                                            className="p-2"><i className="bx bx-message-alt-minus"></i></a>
                                        <label
                                            className="p-1">{this.state.qtd}</label>
                                        <a onClick={this.add}
                                            className="p-2"><i className="bx bx-plus"></i></a>
                                    </div>
                                </Col>
                                <Col>
                                    <button id="add-to-cart" name="add-to-cart" className="btn btn-lg  btn-dark w-100" onClick={async () => {
                                        window.dataLayer.push({ event: 'add-to-cart' });
                                        await this.handleAddToCart()
                                    }}>
                                        <h4 className="text-center text-light card-title font-bl-13-700h">
                                            + ADD {<NumberFormat value={valueTot} />}
                                        </h4>
                                    </button>
                                </Col>
                            </Row>
                            </Stack>
                        </>

                }
               
            </>
        )
    }
}

ModalContentType2.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
    refresh: PropTypes.any,
    type: PropTypes.string,
    loading: PropTypes.any,
    toggleModal: PropTypes.func,
    paramsBody: PropTypes.any,
    size_height: PropTypes.any,
    row: PropTypes.any,
    findProductsByCategory: PropTypes.func,
    apiFindProductsByCategoryError: PropTypes.func,
    createOrUpdateOrder: PropTypes.func,

}
const mapStateToProps = state => {
    const {
        loading,
        row
    } = state.ProductsCategory
    return {
        loading,
        row
    }
}
export default withTranslation()(withRouter(connect(mapStateToProps, {
    apiFindProductsByCategoryError,
    findProductsByCategory,
    createOrUpdateOrder
})(
    (ModalContentType2)
)))
