import {
    FIND_COUPOM_DISCOUNT,
    FIND_COUPOM_DISCOUNT_SUCCESS,
    FIND_COUPOM_DISCOUNT_API_ERROR,
} from "./actionTypes"


export const findDiscountCoupons = query => {
    return {
        type: FIND_COUPOM_DISCOUNT,
        payload: {query},
    }
}

export const findDiscountCouponsSuccess = data => {
    return {
        type: FIND_COUPOM_DISCOUNT_SUCCESS,
        payload: {data}
    }
}

export const apiFindDiscountCouponsError = error => {
    return {
        type: FIND_COUPOM_DISCOUNT_API_ERROR,
        payload: {error},
    }
}

