import { takeEvery, put, call, takeLatest } from "redux-saga/effects"

import {
    FIND_HOME_PROFILE_COMPANY
} from "./actionTypes"


import {
    findHomeProfileCompanySuccess,
    apiFindHomeProfileCompanyError
} from "./actions"


import {
    findProfileCompanyAPI
} from "../../helpers/profile_helper"
import { create_preference } from "logic/storageAPI"
import { PROFILE_STORAGE_SERVICE_LOCAL } from "logic/keyStorageAPI"

function* findHomeProfileCompany({ payload: { data } }) {

    try {

        const response = yield call(findProfileCompanyAPI, data)
        const { error, message } = response
        if (error == false) {
            create_preference(response.data, PROFILE_STORAGE_SERVICE_LOCAL.intent)

        }
         yield put(findHomeProfileCompanySuccess(response))


    } catch (error) {
        yield put(apiFindHomeProfileCompanyError(error))
    }
}


function* ProfileCompanySaga() {
    yield takeEvery(FIND_HOME_PROFILE_COMPANY, findHomeProfileCompany)
}

export default ProfileCompanySaga
