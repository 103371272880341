import { all, fork } from "redux-saga/effects"

//public
import LayoutSaga from "./layout/saga"
import HomeSaga from "./catalog/home/saga"
import ProductsCategorySaga from "./catalog/productsByCategory/saga"
import ProductsCategorySizeSaga from "./catalog/productsByCategorySize/saga"
import PaymentMethodsSaga from "./catalog/paymentMethods/saga"
import CouponsDiscountsSaga from "./catalog/coupomDiscounts/saga"
import OrderCartSaga from "./order/saga"
import LocationSaga from "./search/location/saga"
import ProfileCompanySaga from "./company/saga"
import CartSessionAPISaga from "./cartSession/saga"
import PromotionSaga from "./promotion/saga"


export default function* rootSaga() {
  yield all([

    //public
    fork(LayoutSaga),
    fork(HomeSaga),
    fork(ProfileCompanySaga),
    fork(LocationSaga),
    fork(OrderCartSaga),
    fork(ProductsCategorySaga),
    fork(ProductsCategorySizeSaga),
    fork(PaymentMethodsSaga),
    fork(CouponsDiscountsSaga),
    fork(CartSessionAPISaga),
    fork(PromotionSaga)

  ])
}
