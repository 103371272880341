import {
    CREATE_OR_UPDATE_ORDER,
    CREATE_OR_UPDATE_ORDER_SUCCESS,
    GET_ORDER,
    GET_ORDER_SUCCESS,
    CREATE_OR_UPDATE_ORDER_API_ERROR
} from "./actionTypes"


export const createOrUpdateOrder = (params) => {
    return {
        type: CREATE_OR_UPDATE_ORDER,
        payload: { params },
    }
}

export const createOrUpdateOrderSuccess = params => {
    return {
        type: CREATE_OR_UPDATE_ORDER_SUCCESS,
        payload: { params },
    }
}

export const getOrder = (params, history) => {
    return {
        type: GET_ORDER,
        payload: { params, history },
    }
}

export const getOrderSuccess = (params) => {
    return {
        type: GET_ORDER_SUCCESS,
        payload: params,
    }
}

export const apiCreateOrUpdateOrderError = error => {
    return {
        type: CREATE_OR_UPDATE_ORDER_API_ERROR,
        payload: error,
    }
}

