import {
    GET_PROMOTION_PRODUCT_BY_SLUG,
    GET_PROMOTION_PRODUCT_BY_SLUG_SUCCESS,
    GET_PROMOTIONS_PRODUCTS_BY_SECTION_CATEGORY,
    GET_PROMOTIONS_PRODUCTS_BY_SECTION_CATEGORY_SUCCESS,
    GET_PROMOTION_PRODUCTS_API_ERROR,
} from "./actionTypes"



export const getPromotionProductBySlug = data => {
    return {
        type: GET_PROMOTION_PRODUCT_BY_SLUG,
        payload: { data },
    }
}

export const getPromotionProductBySlugSuccess = results => {

    return {
        type: GET_PROMOTION_PRODUCT_BY_SLUG_SUCCESS,
        payload: results,
    }
}

export const getPromotionsProductsBySectionCategory = data => {
    return {
        type: GET_PROMOTIONS_PRODUCTS_BY_SECTION_CATEGORY,
        payload: { data },
    }
}

export const getPromotionsProductsBySectionCategorySuccess = results => {

    return {
        type: GET_PROMOTIONS_PRODUCTS_BY_SECTION_CATEGORY_SUCCESS,
        payload: results,
    }
}


export const apiGetPromotionsProductsError = error => {
    return {
        type: GET_PROMOTION_PRODUCTS_API_ERROR,
        payload: { error },
    }
}

