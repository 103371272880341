import {
    CREATE_OR_UPDATE_ORDER,
    CREATE_OR_UPDATE_ORDER_SUCCESS,
    GET_ORDER,
    GET_ORDER_SUCCESS,
    CREATE_OR_UPDATE_ORDER_API_ERROR
} from "./actionTypes"


const initialState = {
    loading: false,
    loading_form: false,
    refresh: false,
    error: false,
    message: '',


}

const createOrUpdateOrders = (state = initialState, action) => {

    switch (action.type) {
        case CREATE_OR_UPDATE_ORDER:
            state = {
                ...state,
                refresh: false,
                loading: true
            }
            break
        case CREATE_OR_UPDATE_ORDER_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                error: action.payload.error,
                refresh: false,
                loading: false
            }
            break
        case GET_ORDER:
            state = {
                ...state,
                refresh: false,
                loading: true
            }
            break
        case GET_ORDER_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                error: action.payload.error,
                data: action.payload.params.result,
                refresh: false,
                loading: false
            }
            break
        case CREATE_OR_UPDATE_ORDER_API_ERROR:
            state = { ...state, error: action.payload, loading: false, refresh: false }
            break
        default:
            state = { ...state }
            break
    }

    return state
}
export default createOrUpdateOrders
