import React, { Component } from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import SEO from "components/SEO/index"
import BodyAndBarApp from "../../../../components/BodyAndBarApp/Index"

class Index extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            cache: true
        }
    }


    render() {
        const { result } = this.props

        return (
            <BodyAndBarApp
                visibleCart={false}
                icon_back={"mdi-arrow-left"}
                title="Política de Pagamento"
            >
                <div className="pr-4 px-4">

                    <h1 className="mb-3 text-center">Política de Pagamento</h1>
                    <p><strong className="text-center">Cardápio Digital e Máquina de Pagamento</strong></p>
                    <p><strong>Pizzaria Fontanella</strong></p>
                    <p><strong>CNPJ: 43.612.624/0001-72</strong></p>
                    <p><strong>Telefone: (19) 99799-1650 / (19) 3500-3907</strong></p>
                    <p><strong>E-mail: <a href="mailto:sac@pizzariafontanella.com.br" target="_new">sac@pizzariafontanella.com.br</a></strong></p>
                    <p><strong>Última Atualização: 21/10/2023 00:32</strong></p>

                    <p>Esta política de pagamento descreve os termos e condições relacionados aos métodos de pagamento aceitos em nosso cardápio digital e máquina de pagamento. Ao efetuar um pagamento, você concorda com os termos e condições descritos nesta política.</p>

                    <p><strong>1. Métodos de Pagamento Aceitos</strong></p>

                    <p>Informamos os métodos de pagamento que aceitamos, como cartões de crédito, cartões de débito ou outros métodos específicos.</p>

                    <p><strong>2. Pagamento Seguro</strong></p>

                    <p>Nosso cardápio digital e máquina de pagamento utilizam medidas de segurança para proteger suas informações financeiras durante a transação.</p>

                    <p><strong>3. Recibo de Pagamento</strong></p>

                    <p>Você receberá um recibo de pagamento para cada transação concluída. Guarde o recibo para referência futura.</p>

                    <p><strong>4. Cancelamentos e Reembolsos</strong></p>

                    <p>Consulte nossa política de cancelamento e reembolso para obter informações sobre como lidamos com pedidos cancelados e reembolsos.</p>

                    <p><strong>5. Responsabilidade do Usuário</strong></p>

                    <p>É responsabilidade do usuário fornecer informações de pagamento precisas e garantir que haja fundos suficientes para cobrir o pagamento.</p>

                    <p><strong>6. Alterações na Política de Pagamento</strong></p>

                    <p>Nos reservamos o direito de atualizar esta política de pagamento periodicamente. As alterações serão publicadas em nosso cardápio digital.</p>
                </div>

            </BodyAndBarApp>
        )
    }
}

Index.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
    loading: PropTypes.bool,
    error: PropTypes.any,
    message: PropTypes.any,
    result: PropTypes.object,
    apiFindHomeProfileCompanyError: PropTypes.func,
    findHomeProfileCompany: PropTypes.func,
    pageSelector: PropTypes.func


}
const mapStateToProps = state => {

    return {}
}
export default withTranslation()(withRouter(connect(mapStateToProps, {
})(
    (Index)
)))
