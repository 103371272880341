import React from "react"
import { Button, Col, Input, Modal, Row } from "reactstrap"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { createOrUpdateOrder, apiFindProductsByCategoryError, findProductsByCategory } from "../../../store/actions"
import ImageURI from "components/ImageURI"
import { Stack } from "react-bootstrap"
import FormBuyCart from "pages/Menu/FormBuyCart"

/**
 * Modal para montagem do pedido lanches pizza que possui diversos complementos.
 */
class Index extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            qtd_items_cart: 0,
            merchant: null
        }


    }

    componentDidMount() {
        const query = new URLSearchParams(this.props.location.search);
        const q_search = query.get('m')

        this.setState({ merchant: q_search })


    }


    render() {


        return (
            <React.Fragment>
                <div className="container-box" id="app-view-cart">
                    <nav id="navbar_top"
                        className={"w-100 navbar navbar-expand-lg navbar-dark bg-white header-bar-top p-2 mob-tab-bar-top-home-shadow"}>
                        <Stack className="w-100" direction="horizontal" gap={3}>
                            <div className="text-start w-25">
                                <a onClick={() => {
                                    this.props.history.push('app')
                                }} id="app" className="btn header-item noti-icon">
                                    <i className="mdi mdi-24px mdi-home-search"></i>
                                </a>
                            </div>
                            <div className="text-center w-50">
                                <p className="font-got-blk-14-400h">Pizzaria Fontanella</p>

                            </div>
                            <div className="text-end  w-25">


                            </div>

                        </Stack>
                    </nav>
                    <FormBuyCart layout_merchant={this.state.merchant && this.state.merchant} />

                </div>
            </React.Fragment>
        )
    }
}

Index.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
    match: PropTypes.any,
    refresh: PropTypes.any,
    type: PropTypes.string,
    loading: PropTypes.any,
    toggleModal: PropTypes.func,
    paramsBody: PropTypes.any,
    size_height: PropTypes.any,
    row: PropTypes.any,
    history: PropTypes.object,
    findProductsByCategory: PropTypes.func,
    apiFindProductsByCategoryError: PropTypes.func,
    createOrUpdateOrder: PropTypes.func,

}
const mapStateToProps = state => {
    const {
        loading,
        row
    } = state.ProductsCategory
    return {
        loading,
        row
    }
}
export default withTranslation()(withRouter(connect(mapStateToProps, {
    apiFindProductsByCategoryError,
    findProductsByCategory,
    createOrUpdateOrder
})(
    (Index)
)))
