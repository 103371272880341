import React, {Component} from "react"
import {Card, CardBody, CardText, CardTitle, Col, Row} from "reactstrap"
import PropTypes from "prop-types"
import {withTranslation} from "react-i18next"
import {withRouter} from "react-router-dom"
import {connect} from "react-redux"
import {apiFindDiscountCouponsError, findDiscountCoupons} from "../../store/actions"
import UILoadingAnim from "../Mobile/Components/UILoadingAnim"

class UICoupomDiscountList extends Component {
    constructor(props) {
        super(props)
        this.state = {}


    }

    componentDidMount() {
        this.props.findDiscountCoupons()
    }


    render() {

        return (
            <Col sm={12}>
                <Row>
                    <Col sm={12}>
                        <div className="text-start mb-1">
                            <a onClick={this.props.previousPage}>
                                <b>
                                    <h4 className="card-title font-bl-12-400h text-danger">
                                        <i className="mdi mdi-arrow-left mdi-16px"/> Voltar
                                    </h4>
                                </b>
                            </a>
                        </div>
                    </Col>
                    <Col sm={12}>
                        <div className="text-center  mb-4">
                            <h4 className="card-title">Cupons de Descontos</h4>
                            <small>em Pizzaria Fontanella</small>
                        </div>
                    </Col>
                    <Col sm={6} className="mt-2">
                        <input className="form-control font-bl-13-700h " placeholder="Código de cupom"
                               type="text" id="number" name="number" onChange={this.handleFields}
                               value={this.state.number}/>

                    </Col>
                    <Col className="mt-2 mb-3">
                        <a>
                            <h4 className="font-bl-13-700h text-start text-dark mt-2">
                                <i className="mdi mdi-plus mdi-16px"/>
                                Adicionar
                            </h4>
                        </a>

                    </Col>
                    <Col sm={12}>
                        <div className="text-start">
                            <small>Selecione o cupom abaixo</small>
                            <hr/>
                        </div>

                    </Col>
                    {
                        this.props.loading
                            ?
                            <Col sm={12}>
                                <div style={{
                                    height: '700px !important',
                                    marginBottom: '100px'

                                }}>
                                    <UILoadingAnim
                                        url_anim={6}/>
                                </div>
                            </Col>
                            :
                            this.props.rows && this.props.rows.length > 0 &&
                            this.props.rows.map((item, index) => {
                                return (
                                    <Col sm={12} key={index}>
                                        <a onClick={() => this.props.funcParamCoupons(item)}>
                                            <Card className="border-2">

                                                <CardBody>
                                                    <CardTitle className="font-bl-13-700h">{item.qtd} Cupom
                                                        Disponivel</CardTitle>
                                                    <CardText className="font-bl-13-700h">expira
                                                        em {item.expiration}</CardText>
                                                    <hr/>
                                                    <CardTitle className="font-bl-13-700h">{item.percentage} % de
                                                        Desconto</CardTitle>
                                                    <p className="card-text font-bl-12-400h">{item.description}</p>
                                                </CardBody>
                                            </Card>
                                        </a>
                                    </Col>
                                )
                            })
                    }

                </Row>
            </Col>

        )
    }
}

UICoupomDiscountList.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
    refresh: PropTypes.any,
    loading: PropTypes.any,
    rows: PropTypes.array,
    funcParamCoupons: PropTypes.func,
    previousPage: PropTypes.func,
    apiFindDiscountCouponsError: PropTypes.func,
    findDiscountCoupons: PropTypes.func

}
const mapStateToProps = state => {
    const {
        loading,
        rows
    } = state.CouponsDiscounts
    return {
        loading,
        rows
    }
}
export default withTranslation()(withRouter(connect(mapStateToProps, {
    apiFindDiscountCouponsError,
    findDiscountCoupons

})(
    (UICoupomDiscountList)
)))
