export const CART_STORAGE_SERVICE_LOCAL = { index: 0, intent: 'CART_STORAGE_SERVICE_LOCAL' };
export const CART_STORAGE_ADDRESS_PICKUP_DELIVERY_LOCAL = { index: 0, intent: 'CART_STORAGE_ADDRESS_PICKUP_DELIVERY_LOCAL' };
export const CART_STORAGE_TYPE_PICKUP_DELIVERY_LOCAL = { index: 0, intent: 'CART_STORAGE_TYPE_PICKUP_DELIVERY_LOCAL' };
export const CART_STORAGE_RESPONSIBLE_LOCAL = { index: 0, intent: 'CART_STORAGE_RESPONSIBLE_LOCAL' };
export const CART_STORAGE_COUPOM_LOCAL = { index: 0, intent: 'CART_STORAGE_COUPOM_LOCAL' };
export const CART_STORAGE_TAB_FEE_DELIVERY_LOCAL = { index: 0, intent: 'CART_STORAGE_TAB_FEE_DELIVERY_LOCAL' };



export const CART_STORAGE_SERVICE_CACHE_API = { index: 1, intent: 'CART_STORAGE_SERVICE_CACHE_API' };
export const CART_STORAGE_SERVICE_CACHE_API_TIME_LAST_UPDATE = { index: 1, intent: 'CART_STORAGE_SERVICE_CACHE_API_TIME_LAST_UPDATE' };

export const PROFILE_STORAGE_SERVICE_LOCAL = { index: 0, intent: 'PROFILE_STORAGE_SERVICE_LOCAL' };

export const ACTION_CREATE_OR_UPDATE_ORDER = { index: 0, intent: 'ACTION_CREATE_OR_UPDATE_ORDER' };
export const ACTION_ADD_OR_UPDATE_PRODUCT_ORDER = { index: 0, intent: 'ACTION_ADD_OR_UPDATE_PRODUCT' };
export const ACTION_ADD_OR_UPDATE_ADDRESS_DELIVERY = { index: 0, intent: 'ACTION_ADD_OR_UPDATE_ADDRESS_DELIVERY' };
export const ACTION_ADD_OR_UPDATE_CUSTOMER = { index: 0, intent: 'ACTION_ADD_OR_UPDATE_CUSTOMER' };
export const ACTION_ADD_OR_UPDATE_PAYMENT_METHOD = { index: 0, intent: 'ACTION_ADD_OR_UPDATE_PAYMENT_METHOD' };
export const ACTION_ADD_OR_UPDATE_SHIPPING_FEE = { index: 0, intent: 'ACTION_ADD_OR_UPDATE_SHIPPING_FEE' };