import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"
import { NotificationManager } from "react-notifications";
import toast from "react-hot-toast"


const getFeeDeliveryByRadius = ({ company_id, distance }) => get(url.GET_FEE_DELIVERY_BY_RADIUS.concat('/').concat(company_id).concat('/').concat(distance))
    .catch(err => {
        const { title, message } = err.response.data;
        //NotificationManager.error(message, title);
        toast.error(message)
        throw err.response.data
    })

const getOrderById = data => get(url.GET_ORDER_BY_ID.concat('/').concat(data))
    .catch(err => {
        const { title, message } = err.response.data;
        //NotificationManager.error(message, title);
        toast.error(message)
        throw err.response.data
    })

const delOrder = data => del(url.DELETE_ORDER.concat('/').concat(data))
    .catch(err => {
        const { title, message } = err.response.data;
        toast.error(message)
        throw err.response.data
    })

const postCreateOrder = data => post(url.POST_CREATE_ORDER, data)
    .catch(err => {
        const { title, message } = err.response.data;
        toast.error(message)
        throw err.response.data
    })

const putEditOrder = data => put(url.PUT_EDIT_ORDER, data)
    .catch(err => {
        const { title, message } = err.response.data;
        //NotificationManager.error(message, title);
        toast.error(message)
        throw err.response.data
    })

const getOrdersPaginate = ({ page, limit }) => get(url.GET_ORDERS_PAGINATE, { params: { page, limit } })
    .catch(err => {
        const { title, message } = err.response.data;
        //NotificationManager.error(message, title);
        toast.error(message)
        throw err.response.data
    })

const getFilterOrdersPaginate = data => get(url.GET_ORDERS_PAGINATE_FILTERS, { params: { data } })
    .catch(err => {
        const { title, message } = err.response.data;
        //NotificationManager.error(message, title);
        toast.error(message)
        throw err.response.data
    })

const getSearchOrders = params => get(url.GET_SEARCH_ORDERS, { params: { query: params } })
    .catch(err => {
        throw err.response.data
    })
const getOrderCartSession = (action, key) => get(url.GET_CART_ORDERS.concat('/').concat(action).concat('/').concat(key))
    .catch(err => {
        throw err.response.data
    })
const postCreateOrderCartSession = data => post(url.POST_CREATE_ORDER_CART, data)
    .catch(err => {
        const { title, message } = err.response.data;
        toast.error(message)
        throw err.response.data
    })

export {
    getOrdersPaginate,
    getFilterOrdersPaginate,
    putEditOrder,
    postCreateOrder,
    delOrder,
    getOrderById,
    getSearchOrders,
    getFeeDeliveryByRadius,
    postCreateOrderCartSession,
    getOrderCartSession
}
