import {
    GEO_REVERSE_COORDINATES,
    GEO_REVERSE_COORDINATES_SUCCESS,
    GEO_DISTANCE_COORDINATES,
    GEO_DISTANCE_COORDINATES_SUCCESS,
    SEARCH_ADDRESS,
    SEARCH_ADDRESS_SUCCESS,
    API_ERROR,
} from "./actionTypes"


const initialState = {
    loading_map: false,
    address: '',
    duration: null, 
    distance: null, 
    coordinates: {lat: 0, lng: 0}
}

const location = (state = initialState, action) => {

    switch (action.type) {
        case GEO_DISTANCE_COORDINATES:
            state = {
                ...state,
                loading_map: true
            }
            break
        case GEO_DISTANCE_COORDINATES_SUCCESS:
            state = {
                ...state,
                duration: action.payload.params.duration,
                distance: action.payload.params.distance,
                loading_map: false
            }
            break
        case GEO_REVERSE_COORDINATES:
            state = {
                ...state,
                loading_map: true
            }
            break
        case GEO_REVERSE_COORDINATES_SUCCESS:
            state = {
                ...state,
                address: action.payload.address,
                coordinates: action.payload.coordinates,
                loading_map: false
            }
            break
        case SEARCH_ADDRESS:
            state = {...state, loading_map: true}
            break
        case SEARCH_ADDRESS_SUCCESS:
            state = {
                ...state,
                address: action.payload.address,
                coordinates: action.payload.coordinates,
                loading_map: false
            }
            break
        case API_ERROR:
            state = {...state, error: action.payload, loading_map: false}
            break
        default:
            state = {...state}
            break
    }

    return state
}
export default location
