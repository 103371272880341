import React, { Component } from "react"
import $ from "jquery";
import "jquery-ui-bundle/jquery-ui";
import "jquery-ui-bundle/jquery-ui.css";
import { Form } from "react-bootstrap";
import './index.css';

import { del, get, post, put } from "../../helpers/api_helper"

import { NotificationManager } from "react-notifications";
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"

/**
 * @param { id, label, value } inputProps
 * @param { função para setar o valor } onSourceSelect
 * { inputProps, onSourceSelect }
 */

class Autocomplete extends React.Component {

	constructor(props) {
		super(props);
		this.state = {}
		this.searchable = this.searchable.bind(this);
	}
	componentDidMount() {
		this.searchable()
	}

	searchable() {
		const { inputProps, onSourceSelect } = this.props;
		//const {_userFocusOutData} = this.state;
		try {

			$("#" + inputProps.id).autocomplete({
				delay: 2,
				autoFocus: true,
				autoFill: true,
				scrollHeight: 220,
				minLength: 2,
				source: function (request, response) {

					const dados = Object.entries(
						Object.assign({ query: request.term }, inputProps.data)
					).map(item => item.join('=')).join('&')

					try {

						get(inputProps.url + '?' + dados)
							.then(resp => {

								const { results } = resp;

								response(results.map(item => {
									const { geometry, formatted_address, address_components } = item
									const { bounds, location, viewport, location_type } = geometry
									
									let postal_code = "";
									let is_zone_rural = false
									if (address_components.length > 0) {
										address_components.forEach((element) => {
											
											if (element.types.length > 0) {
												element.types.forEach((type) => {
													if (type === "political" || type === "sublocality") {
														if (element.long_name === "Zona Rural") {
															is_zone_rural = true;
															
														}
													}

													if (type === "postal_code" || type === "postal_code_prefix") {
														postal_code = element.long_name
														
													}
												});
											}
										});
									}
									return {
										label: formatted_address,
										value: formatted_address,
										name: inputProps.id,
										coordinates: location,
										postal_code,
										is_zone_rural,
										data: item
									}
								}))

								$(`[name='${inputProps.id}']`).removeClass("ui-autocomplete-loading")

							})
							.catch(error => {

								if (error.code === 404) {
									NotificationManager.warning('Não existem registros para serem exibidos', 'Ops!', 3000);
								}

								if (error.code === 401) {
									NotificationManager.warning('Não Autenticado, faça o login novamente', 'Ops!', 3000);
								}

								$(`[name='${inputProps.id}']`).removeClass("ui-autocomplete-loading")

							}).then(response => {
							})

					} catch (error) {
						console.log('error', error)
					}

				},
				select: function (event, ui) {
					console.log("UI elect ", ui.item)
					onSourceSelect(ui.item)
				},
				change: function (event, ui) {
					console.log("UI Change ", ui.item)

					if (!ui.item) {

						onSourceSelect(ui.item)

					}

				},
				open: function () {
					$(this).removeClass("ui-corner-all").addClass("ui-corner-top");
				},
				close: function () {
					$(this).removeClass("ui-corner-top").addClass("ui-corner-all");
				}

			})

		} catch (error) {
			console.error('ERRROR ', error)
		}
	}




	render() {


		return (
			<Form.Group>
				<label htmlFor={this.props.inputProps.id} className="font-label">{this.props.inputProps.label}</label>
				<Form.Control
					type="text"
					className="form-control "
					placeholder={this.props.inputProps.placeholder}
					id={this.props.inputProps.id}
					name={this.props.inputProps.id}
					onChange={(e) => this.props.onSourceSelect({ value: e.target.value, name: this.props.inputProps.id })}
					value={this.props.inputProps.value} />
			</Form.Group>
		)
	}

}


Autocomplete.propTypes = {
	google: PropTypes.object,
	location: PropTypes.object,
	t: PropTypes.any,
	inputProps: PropTypes.object,
	onSourceSelect: PropTypes.func


}
const mapStateToProps = state => {
	return {}
}
export default withTranslation()(withRouter(connect(mapStateToProps, {

})(
	(Autocomplete)
)))
