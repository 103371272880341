import {
    FIND_HOME_PROFILE_COMPANY,
    FIND_HOME_PROFILE_COMPANY_SUCCESS,
    FIND_HOME_PROFILE_COMPANY_API_ERROR
} from "./actionTypes"
import { PROFILE_STORAGE_SERVICE_LOCAL } from "../../logic/keyStorageAPI"
import { checkInStorageCart, create_preference } from "../../logic/storageAPI"


const initialState = {
    loading: true,
    error: false,
    message: '',

    result: null,
    id: 0,
}

const profile_company = async (state = initialState, action) => {

    switch (action.type) {
        case FIND_HOME_PROFILE_COMPANY:
            state = {
                ...state,
                loading: true
            }
            break
        case FIND_HOME_PROFILE_COMPANY_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                error: action.payload.error,
                result: JSON.parse(atob(action.payload.data)),
                loading: false
            }

            break
        case FIND_HOME_PROFILE_COMPANY_API_ERROR:
            state = { ...state, error: action.payload, loading: true }
            break
        default:
            state = { ...state, loading: false }
            break
    }

    return state
}
export default profile_company
