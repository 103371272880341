import React, { Component } from "react"
import { Card, CardBody, Col, Row } from "reactstrap";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import NumberFormat from "../../../components/NumberFormat";
import { Button, Stack } from "react-bootstrap"
import ImageURI from "components/ImageURI";
import slugify from 'react-slugify';

class UICardViewImage extends Component {
    constructor(props) {
        super(props)
        this.state = {}

    }

    render() {

        return (
            <Col sm={12} lg={6} xl={6} className="mb-1">
                <a id={slugify((this.props.nameGroup.concat(" ").concat(this.props.prod.name)).toLowerCase())}
                    name={slugify((this.props.nameGroup.concat(" ").concat(this.props.prod.name)).toLowerCase())}
                    onClick={() => {
                        window.dataLayer.push({ event: slugify((this.props.nameGroup.concat(" ").concat(this.props.prod.name)).toLowerCase()) });
                        this.props.toggleproductsmodal()
                        this.props.getParamsModal(
                            {
                                price: this.props.prod.price,
                                id: this.props.prod.id,
                                description: this.props.desc,
                                title: this.props.prod.name,
                                prod_type: 3,
                                nameGroup: this.props.nameGroup,
                                idGroup: this.props.idGroup,
                                url_image: this.props.prod.url_image,
                                type: this.props.type,
                                price_type: this.props.price_type
                                /* size: {
                                     id: this.props.idGroup,
                                     price: this.props.prod.price
                                 }*/
                            })
                    }}>
                    <Stack direction="horizontal" gap={2} className="bg-white border border-1  rounded-2 p-3">
                        <Stack gap={3}>
                            <div><h1 className="text-start card-title font-got-blk-13-400h ">{this.props.prod.name}</h1></div>
                            <div><p className="text-start card-text font-bl-12-400h fw-bold">{this.props.prod.description}</p></div>
                            <div><p className={parseInt(this.props.price_type) === 0 ? "text-start mt-2 card-text font-bl-12-400h text-success fw-bold" : "text-start mt-2 card-text font-bl-12-400h text-danger fw-bold"}>{parseInt(this.props.price_type) === 0 ? <NumberFormat value={this.props.prod.price} /> : ''}</p></div>

                            <div className="text-start">
                                <Button
                                    onClick={(e) => {
                                        window.dataLayer.push({ event: slugify((this.props.nameGroup.concat(" ").concat(this.props.prod.name)).toLowerCase()) });
                                        this.props.toggleproductsmodal()
                                        this.props.getParamsModal(
                                            {
                                                price: this.props.prod.price,
                                                id: this.props.prod.id,
                                                description: this.props.desc,
                                                title: this.props.prod.name,
                                                prod_type: 3,
                                                nameGroup: this.props.nameGroup,
                                                idGroup: this.props.idGroup,
                                                url_image: this.props.prod.url_image,
                                                type: this.props.type,
                                                price_type: this.props.price_type
                                                /* size: {
                                                     id: this.props.idGroup,
                                                     price: this.props.prod.price
                                                 }*/
                                            })
                                    }}
                                    className={' btn  btn-animated font-got-lt-14-400h   text-center'} variant="outline-danger" size="sm">
                                    Personalizar
                                </Button>
                            </div>
                        </Stack>
                        <ImageURI
                            style_div="img-square-wrapper"
                            style_img="m-2 rounded thumbnail img-responsive img-card-list"
                            url={this.props.prod.url_image} alt={this.props.prod.name} />

                    </Stack>
                </a>
            </Col>
        )
    }
}

UICardViewImage.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
    refresh: PropTypes.any,
    loading: PropTypes.any,
    type: PropTypes.any,
    desc: PropTypes.any,
    toggleproductsmodal: PropTypes.func,
    getParamsModal: PropTypes.func,
    nameGroup: PropTypes.any,
    idGroup: PropTypes.any,
    prod: PropTypes.any,
    url_image: PropTypes.any,
    price_type: PropTypes.any


}
const mapStateToProps = state => {
    return {}
}
export default withTranslation()(withRouter(connect(mapStateToProps, {


})(
    (UICardViewImage)
)))
