import React, { Component } from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import SEO from "components/SEO/index"
import BodyAndBarApp from "../../../../../components/BodyAndBarApp/Index"

class Index extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            cache: true
        }
    }


    render() {
        const { result } = this.props

        return (
            <BodyAndBarApp
                visibleCart={false}
                icon_back={"mdi-arrow-left"}
                title="Política de Uso para Chat e Coleta de Dados"
            >
                <div className="pr-4 px-4">

                    <h1 className="mb-3 text-center">Política de Uso para Chat e Coleta de Dados</h1>
                    <p><strong className="text-center">Cardápio Digital</strong></p>
                    <p><strong>Pizzaria Fontanella</strong></p>
                    <p><strong>CNPJ: 43.612.624/0001-72</strong></p>
                    <p><strong>Telefone: (19) 99799-1650 / (19) 3500-3907</strong></p>
                    <p><strong>E-mail: <a href="mailto:sac@pizzariafontanella.com.br" target="_new">sac@pizzariafontanella.com.br</a></strong></p>
                    <p><strong>Última Atualização: 08/01/2024 10:36</strong></p>

                    <p>Esta política de de Uso para Chat descreve os termos e condições relacionados aos serviços de automação para os canais de atendimento. Ao iniciar um atendimento através dos canais de comunicaçao, você concorda com os termos e condições descritos nesta política.</p>

                    <p><strong>1. Objetivo:</strong></p>

                    <p>A Pizzaria Fontanella busca proporcionar uma experiência positiva aos seus clientes por meio de interações eficientes no chat e coleta de dados que visam melhorar nossos serviços.</p>

                    <p><strong>2. Informações Coletadas:</strong></p>

                    <p>Coletamos informações como nome, endereço, número de telefone e preferências de pizza para facilitar a entrega e personalizar a experiência do cliente.</p>

                    <p><strong>3. Segurança e Confidencialidade:</strong></p>

                    <p>Comprometemo-nos a garantir a segurança e confidencialidade das informações dos clientes, implementando medidas rigorosas de proteção de dados.</p>

                    <p><strong>4. Uso de Cookies e Tecnologias Similares:</strong></p>

                    <p>Utilizamos cookies para aprimorar a navegação em nosso site e aplicativo, fornecendo aos clientes a opção de gerenciar suas preferências de cookies.</p>

                    <p><strong>5. Acesso e Controle:</strong></p>

                    <p>Os clientes têm o direito de acessar, corrigir ou excluir suas informações pessoais. Para isso, basta entrar em contato conosco através dos canais designados.</p>

                    <p>Nos reservamos o direito de atualizar esta política periodicamente. As alterações serão publicadas em nosso cardápio digital.</p>
                </div>
            </BodyAndBarApp>
        )
    }
}

Index.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
    loading: PropTypes.bool,
    error: PropTypes.any,
    message: PropTypes.any,
    result: PropTypes.object,
    apiFindHomeProfileCompanyError: PropTypes.func,
    findHomeProfileCompany: PropTypes.func,
    pageSelector: PropTypes.func


}
const mapStateToProps = state => {

    return {}
}
export default withTranslation()(withRouter(connect(mapStateToProps, {
})(
    (Index)
)))
