import {
    SEARCH_FEE_DELIVERY,
    SEARCH_FEE_DELIVERY_SUCCESS,
    CREATE_ORDERS,
    CREATE_ORDERS_SUCCESS,
    CREATE_OR_EDIT_ORDERS_API_ERROR
} from "./actionTypes"


export const searchFeeDelivery = (params) => {
    return {
        type: SEARCH_FEE_DELIVERY,
        payload: { params },
    }
}

export const searchFeeDeliverySuccess = params => {
    return {
        type: SEARCH_FEE_DELIVERY_SUCCESS,
        payload: { params },
    }
}

export const createOrder = (params, history) => {
    return {
        type: CREATE_ORDERS,
        payload: { params, history },
    }
}

export const createOrderSuccess = (params) => {
    return {
        type: CREATE_ORDERS_SUCCESS,
        payload: params,
    }
}

export const apiCreateOrEditOrderError = error => {
    return {
        type: CREATE_OR_EDIT_ORDERS_API_ERROR,
        payload: error,
    }
}

