import React, { Component } from "react"

import ReactDrawer from 'react-drawer';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import 'react-js-stickynav/dist/index.css'

import { Card, CardBody, Col, Row } from "reactstrap"
import { ScrollMenu } from "react-horizontal-scrolling-menu"
import "./theme_desk.css"
import { apiFindHomeCompanyError, findHomeProfileCompany, createOrUpdateOrder, findHomeHashCompany, findHomeQueryCompany } from "../../store/actions"
import { checkInStorageCart, check_open_company, createCart, getInStorageCart, get_img_front_cover_company, get_img_logo_company, get_name_company, get_rows_menus_company } from "logic/storageAPI";
import { Button, Stack } from "react-bootstrap";
import UILoyalty from "./../../components/Loyalty/index"
import UIHeaderBoxCompany from "./../../components/BoxHeaderCompany/index"
import UIFormCartBox from "../Menu/FormBuyCart"
import { ACTION_CREATE_OR_UPDATE_ORDER, CART_STORAGE_SERVICE_CACHE_API, CART_STORAGE_SERVICE_LOCAL } from "logic/keyStorageAPI";
import UICardViewImage from "pages/Mobile/Components/UICardViewImage";
import UICardViewNoImage from "pages/Mobile/Components/UICardViewNoImage";
import PromotionSection from "./../Mobile/Components/Promotions/Index"
import ModalProductDesk from "./../../components/ModalProductDesk/index";
import UIFacadeDownAPP from "./../../components/FacadeDownAPP/index"
import UIFooterBodyDesk from "./../../components/FooterBodyDesk/index"
import UICarousel from "./../../components/CarouselBanner/index";

class Index extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            position: 'right',
            noOverlay: false,
            showing_search_view: false,

            openRatings: false,
            noOverlayRatings: false,

            positionCartNotify: 'bottom',
            openCartNotify: false,
            noOverlayCartNotify: false,

            modal: false,
            oPenProductsFactoryModal: false,
            modalParam: {},

            qtd_items_cart: 0,

            menus: []
        }


        this.toggleDrawer = this.toggleDrawer.bind(this);
        this.closeDrawer = this.closeDrawer.bind(this);
        this.onDrawerClose = this.onDrawerClose.bind(this);
        this.setPosition = this.setPosition.bind(this);
        this.setNoOverlay = this.setNoOverlay.bind(this);

        this.toggleDrawerRatings = this.toggleDrawerRatings.bind(this);
        this.closeDrawerRatings = this.closeDrawerRatings.bind(this);
        this.onDrawerRatingsClose = this.onDrawerRatingsClose.bind(this);
        this.setNoOverlayRatings = this.setNoOverlayRatings.bind(this);

        this.toggleproductsfactorymodal.bind(this);
        this.getParamsModal = this.getParamsModal.bind(this);


        this.toggleDrawerNotifyCartItems = this.toggleDrawerNotifyCartItems.bind(this);
        this.closeDrawerNotifyCartItems = this.closeDrawerNotifyCartItems.bind(this);
        this.onDrawerNotifyCartItemsClose = this.onDrawerNotifyCartItemsClose.bind(this);
        this.setNoOverlayNotifyCartItems = this.setNoOverlayNotifyCartItems.bind(this);


    }

    componentDidMount() {
        let rows_data = get_rows_menus_company()

        if (rows_data != undefined && rows_data.length > 0) {
            this.setState({ rows_data: rows_data })
        }

        const self = this

        if (!checkInStorageCart(CART_STORAGE_SERVICE_CACHE_API.intent)) {
            createCart(this.props, ACTION_CREATE_OR_UPDATE_ORDER.intent)
        }

        if (checkInStorageCart(CART_STORAGE_SERVICE_LOCAL.intent)) {
            let prods = getInStorageCart(CART_STORAGE_SERVICE_LOCAL.intent, true)
            // this.bandageNotifyCartItems()
            this.setState({ qtd_items_cart: prods.length })

        }

        window.addEventListener('storage', () => {

            if (checkInStorageCart(CART_STORAGE_SERVICE_LOCAL.intent)) {
                let prods = getInStorageCart(CART_STORAGE_SERVICE_LOCAL.intent, true)
                this.toggleDrawerNotifyCartItems()
                self.setState({ qtd_items_cart: prods.length })
            } else {
                this.toggleDrawerNotifyCartItems()
                self.setState({ qtd_items_cart: 0 })
            }
        })


        window.addEventListener('scroll', function () {

            if (window.scrollY > 10) {
                if (document.getElementById('navbar_top')) {
                    document.getElementById('navbar_top').classList.add('fixed-top')

                    // add padding top to show content behind navbar
                    var navbar_height = document.querySelector('.navbar').offsetHeight
                    document.body.style.paddingTop = navbar_height + 'px'
                    self.setState({ showing_search_view: true })
                }

            } else {
                if (document.getElementById('navbar_top')) {
                    document.getElementById('navbar_top').classList.remove('fixed-top')
                    // remove padding top from body
                    document.body.style.paddingTop = '0'
                    self.setState({ showing_search_view: false })
                }
            }

        })

        window.addEventListener('resize', function () {
            if (window.innerWidth <= 800) {
                window.location.reload(false);
            }
        })

    }

    toggleDrawerNotifyCartItems() {
        this.setState({ openCartNotify: !this.state.openCartNotify, open: false });
    }

    closeDrawerNotifyCartItems() {
        this.setState({ openCartNotify: false });
    }

    onDrawerNotifyCartItemsClose() {
        this.setState({ openCartNotify: false });
    }

    setNoOverlayNotifyCartItems(e) {
        this.setState({ noOverlayCartNotify: e.target.checked });
    }

    getParamsModal(params) {
        this.setState({ modalParam: params })
    }

    toggleproductsfactorymodal = () => {
        this.setState(prevState => ({
            oPenProductsFactoryModal: !prevState.oPenProductsFactoryModal,
        }))
    }

    setPosition(e) {
        this.setState({ position: e.target.value });
    }

    setNoOverlay(e) {
        this.setState({ noOverlay: e.target.checked });
    }

    toggleDrawer() {
        this.setState({ open: !this.state.open, openRatings: false });
    }

    closeDrawer() {
        this.setState({ open: false });
    }

    onDrawerClose() {
        this.setState({ open: false });
    }

    toggleDrawerRatings() {
        this.setState({ openRatings: !this.state.openRatings, open: false });
    }

    closeDrawerRatings() {
        this.setState({ openRatings: false });
    }

    onDrawerRatingsClose() {
        this.setState({ openRatings: false });
    }

    setNoOverlayRatings(e) {
        this.setState({ noOverlayRatings: e.target.checked });
    }



    render() {

        const { showing_search_view, navbarItems } = this.state
        let rows_data = [];
        if (this.state.rows_data && this.state.rows_data.length > 0) {
            rows_data = this.state.rows_data
        }
        if (this.state.refresh && this.props.rows && this.props.rows.length > 0) {
            rows_data = this.props.rows
            this.setState({ refresh: false, rows_data: rows_data })

        }

        return (
            <Row>
                <Col sm={12}>
                    <div className="body-app-desk">
                        <div className="app-header-desk ">
                            <div className="app-header-desk-a">
                                <div className="app-header-desk-e">
                                    <div className="app-header-desk-i">
                                        <Stack gap={2}>
                                            <div className="app-bg-home-cover"
                                                style={get_img_front_cover_company() ? { backgroundImage: `url(${get_img_front_cover_company()})` } : { backgroundImage: `url(${cover})` }}>
                                            </div>
                                            <Row>
                                                <Col sm={9}>
                                                    <Stack gap={2}>
                                                        <UIHeaderBoxCompany rows_data={rows_data} showing_search_view={this.state.showing_search_view} />

                                                        <div className="body-app-desk rounded-1">
                                                            <ScrollMenu>

                                                                {
                                                                    rows_data && rows_data.length > 0 &&
                                                                    rows_data.map((item, index) => (

                                                                        item.itens.length > 0 &&
                                                                        <button key={index} className={'  font-got-lt-14-400h   text-center m-2 app-categ-btn '}>

                                                                            <div className="category-menus-grid-item item-grid-size">
                                                                                <div id={item.slug} name={item.slug}
                                                                                    onClick={() => {
                                                                                        document.querySelector(`[id=${item.slug}]`).scrollIntoView()
                                                                                    }}
                                                                                    className="font-got-blk-13-400h p-2 text-center">{item.name.toUpperCase()}
                                                                                </div>
                                                                            </div>

                                                                        </button>

                                                                    ))}

                                                            </ScrollMenu>


                                                            <UICarousel />

                                                            <Row className="mt-2">
                                                                {
                                                                    rows_data && rows_data.length > 0 &&
                                                                    rows_data.map((item, index) => {

                                                                        return (
                                                                            <section key={index} name={item.slug} id={item.slug}>
                                                                                {
                                                                                    item.itens && item.itens.length > 0 &&
                                                                                    <div key={index} className={"mb-2 mt-1"}>

                                                                                        <div className="col-sm-6">
                                                                                            <h2 className="text-start  font-got-blk-14-400h">{item.name.toUpperCase()}
                                                                                                {item.type == 2 && item.promotions == 0 && <small className="text-danger font-got-lt-10-300h fw-bold">  *{item.description}</small>}</h2>
                                                                                        </div>
                                                                                        <Row>
                                                                                            {
                                                                                                item.promotions == 1
                                                                                                    ?
                                                                                                    item.itens && item.itens.length > 0 &&
                                                                                                    <PromotionSection rows={item.itens} />
                                                                                                    :
                                                                                                    item.itens && item.itens.length > 0 &&
                                                                                                    item.itens.map((p, index) => {
                                                                                                        return (
                                                                                                            <>
                                                                                                                {
                                                                                                                    item.type == 1 &&
                                                                                                                    <UICardViewNoImage
                                                                                                                        type={item.type}
                                                                                                                        desc={item.description}
                                                                                                                        nameGroup={item.name}
                                                                                                                        idGroup={item.id}
                                                                                                                        prod={p}
                                                                                                                        toggleproductsmodal={this.toggleproductsfactorymodal}
                                                                                                                        getParamsModal={this.getParamsModal}
                                                                                                                    />
                                                                                                                }
                                                                                                                {
                                                                                                                    item.type == 2 &&
                                                                                                                    <UICardViewImage
                                                                                                                        type={item.type}
                                                                                                                        desc={item.description}
                                                                                                                        url_image={item.url_image}
                                                                                                                        nameGroup={item.name}
                                                                                                                        idGroup={item.id}
                                                                                                                        price_type={item.price_type}
                                                                                                                        prod={p}
                                                                                                                        toggleproductsmodal={this.toggleproductsfactorymodal}
                                                                                                                        getParamsModal={this.getParamsModal}
                                                                                                                    />
                                                                                                                }
                                                                                                            </>
                                                                                                        )
                                                                                                    })
                                                                                            }
                                                                                        </Row>

                                                                                    </div>
                                                                                }
                                                                            </section>


                                                                        )
                                                                    })

                                                                }
                                                            </Row>
                                                        </div>

                                                    </Stack>
                                                </Col>
                                                <Col sm={3}>
                                                    <Stack gap={2} className="mt-3">
                                                        <div className="rounded-1 bg-white border border-1"><UILoyalty /></div>
                                                        <div className="rounded-1 bg-white border border-1"><UIFormCartBox /></div>

                                                    </Stack>
                                                </Col>

                                            </Row>
                                        </Stack>


                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <ModalProductDesk
                        params={this.state.modalParam}
                        oPenProductsFactoryModal={this.state.oPenProductsFactoryModal}
                        toggleModal={this.toggleproductsfactorymodal}
                    />
                </Col>
                <Col sm={12}>
                    <footer className="box-footer-desk">
                        {/*<UIFacadeDownAPP />*/}

                        <UIFooterBodyDesk />

                    </footer>
                </Col>
            </Row>

        )
    }

}

Index.propTypes = {
    rows: PropTypes.any,
    loading: PropTypes.any,
    apiFindHomeCompanyError: PropTypes.func,
    findHomeHashCompany: PropTypes.func,
    findHomeQueryCompany: PropTypes.func,
    openCart: PropTypes.bool,
    closeCart1: PropTypes.func,
    itens_cart: PropTypes.any,
    rows: PropTypes.any,
    createOrUpdateOrder: PropTypes.func,
    findHomeProfileCompany: PropTypes.func,
}

const mapStateToProps = state => {
    const {
        loading,
        rows
    } = state.Catalog
    return {
        loading,
        rows
    }
}

export default withRouter(connect(mapStateToProps, {
    findHomeProfileCompany,
    findHomeHashCompany,
    findHomeQueryCompany,
    apiFindHomeCompanyError,
    createOrUpdateOrder
})(Index)
)
