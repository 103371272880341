
//HOME
export const GET_COMPANIES_BY_HASH = "/catalog/home"
export const GET_COMPANIES_BY_QUERY = "/search/home"
export const GET_PROFILE_COMPANIES = "/catalog/config"

export const GET_PRODUCTS_BY_CATEGORY_ID = "/catalog/products"

export const GET_PAYMENT_METHODS = "/payments/methods"
export const GET_COUPONS_DISCOUNTS = "/payments/coupons"

//GEO
export const GET_GEO_SEARCH_ADDRESS = "/geo/search/map/address"
export const GET_GEO_REVERSE = "/geo/search/map/reverse"
export const GET_GEO_DISTANCE = "/geo/search/map/directions"

//ADDRESS
export const GET_ADDRESS_DELIVERY = "/catalog/customers/address/paginate"
export const POST_CREATE_ADDRESS_DELIVERY = "/catalog/customers/address"

// ORDER
export const GET_SEARCH_ORDERS = "/orders"
export const GET_ORDERS_PAGINATE_FILTERS = "/orders"
export const GET_ORDERS_PAGINATE = "/orders"
export const PUT_EDIT_ORDER = "/orders"
export const POST_CREATE_ORDER = "/orders/create"
export const DELETE_ORDER = "/orders"
export const GET_ORDER_BY_ID = "/orders"

// CART
export const POST_CREATE_ORDER_CART = "/carts"
export const GET_CART_ORDERS = "/carts"

export const GET_FEE_DELIVERY_BY_RADIUS = "/catalog/delivery-fee"

//Promotions
export const GET_PROMOTION_PRODUCT_BY_SLUG = "/promotions/product"
export const GET_PROMOTIONS_PRODUCTS_BY_SECTION_CATEGORY= "/promotions/section"

