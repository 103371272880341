import React from 'react'
import { Card, CardBody, CardHeader, CardImg, CardTitle, Col, Container, Row, TabContent, TabPane } from "reactstrap"
import { Link, withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { NavItem, NavLink } from "reactstrap"
import classnames from "classnames"
//Import Scrollbar
import PerfectScrollbar from "react-perfect-scrollbar"
import NumberFormat from "../../components/NumberFormat"
import EmptyCart from "../../assets/img/empty_buy_cart.png"
import UICardEmptyCart from "./UICardEmptyCart"
import UIBuyCartViewOrder from "./UIBuyCartViewOrder"
import UITypePickupDelivery from "./UITypePickupDelivery"
import UIResponsiblePickupDelivery from "./UIResponsiblePickupDelivery"
import { NotificationManager } from "react-notifications"
import UIAddressSearchPickupDelivery from "./UIAddressSearchPickupDelivery"
import UICoupomDiscountList from "./UICoupomDiscountList"
import UIPaymentMethodsList from "./UIPaymentMethodsList"
import UIStatusOrderProgress from "./UIStatusOrderProgress"
import PropTypes from "prop-types";
import {
    apiCreateOrEditOrderError,
    createOrUpdateOrder,
    createOrder,
    searchFeeDelivery,
    distanceCoordinates
} from "../../store/actions"
import { ACTION_ADD_OR_UPDATE_ADDRESS_DELIVERY, ACTION_ADD_OR_UPDATE_CUSTOMER, ACTION_ADD_OR_UPDATE_PAYMENT_METHOD, ACTION_ADD_OR_UPDATE_PRODUCT_ORDER, ACTION_ADD_OR_UPDATE_SHIPPING_FEE, ACTION_CREATE_OR_UPDATE_ORDER, CART_STORAGE_ADDRESS_PICKUP_DELIVERY_LOCAL, CART_STORAGE_COUPOM_LOCAL, CART_STORAGE_RESPONSIBLE_LOCAL, CART_STORAGE_SERVICE_CACHE_API, CART_STORAGE_SERVICE_LOCAL, CART_STORAGE_TAB_FEE_DELIVERY_LOCAL, CART_STORAGE_TYPE_PICKUP_DELIVERY_LOCAL, PROFILE_STORAGE_SERVICE_LOCAL } from 'logic/keyStorageAPI'
import { checkInStorageCart, createCartWithData, createOrUpdateCoupom, createOrUpdatePickupDelivery, createOrUpdateResponsible, createOrUpdateTypeDelivery, getInStorageCart, getInStorageCartNot64, get_address_company, get_id_app_company } from 'logic/storageAPI'

class FormBuyCart extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            responsible: null,
            deliveryAddress: null,
            coupomDiscount: null,
            paymentMethod: null,
            typeDelivery: 0,
            rows: [],
            page: 1,
            channel_id: 8,
            valueSubTot: 0.0,
            valueTot: 0.0,
            rateDelivery: 0.0,
            discountValue: 0.0,
            shipping_fee: 0.0,
            load_get_fee_delivery: false,
            createOrder: false
        }

        this.paramPickupDelivery = this.paramPickupDelivery.bind(this)
        this.paramResponsibleDelivery = this.paramResponsibleDelivery.bind(this)
        this.nextPage = this.nextPage.bind(this)
        this.previousPage = this.previousPage.bind(this)
        this.cartPage = this.cartPage.bind(this)
        this.paramAddressPickupDelivery = this.paramAddressPickupDelivery.bind(this)
        this.paramCouponsDiscounts = this.paramCouponsDiscounts.bind(this)
        this.paymentMethodsPage = this.paymentMethodsPage.bind(this)
        this.paramPaymentMethods = this.paramPaymentMethods.bind(this)
        this.paramTotalization = this.paramTotalization.bind(this)
        this.mountCartPreSubmission = this.mountCartPreSubmission.bind(this)
        this.nextPageSelected = this.nextPageSelected.bind(this)
    }


    componentDidMount() {
        if (checkInStorageCart(CART_STORAGE_SERVICE_LOCAL.intent)) {
            const rows = getInStorageCart(CART_STORAGE_SERVICE_LOCAL.intent, true)
            this.setState({ rows: rows })
        }

        if (checkInStorageCart(CART_STORAGE_COUPOM_LOCAL.intent)) {
            const { coupomDiscount } = getInStorageCart(CART_STORAGE_COUPOM_LOCAL.intent, true)
            this.setState({ coupomDiscount: coupomDiscount })
        }

        if (checkInStorageCart(CART_STORAGE_ADDRESS_PICKUP_DELIVERY_LOCAL.intent)) {
            const { deliveryAddress } = getInStorageCart(CART_STORAGE_ADDRESS_PICKUP_DELIVERY_LOCAL.intent, true)
            this.setState({ deliveryAddress: deliveryAddress })

            if (checkInStorageCart(CART_STORAGE_SERVICE_CACHE_API.intent)) {
                createCartWithData(this.props, {
                    ACTION_FACTORY: ACTION_ADD_OR_UPDATE_ADDRESS_DELIVERY.intent,
                    key: getInStorageCartNot64(CART_STORAGE_SERVICE_CACHE_API.intent),
                    delivery_address: deliveryAddress
                })
            }

            if (checkInStorageCart(PROFILE_STORAGE_SERVICE_LOCAL.intent)) {

                const { lat, lng } = get_address_company()
                const { coordinates } = deliveryAddress

                let coords_origin = coordinates.lat + ',' + coordinates.lng
                let coords_destination = lat + ',' + lng
                this.props.distanceCoordinates({
                    latlng_origin: coords_origin,
                    latlng_destination: coords_destination
                })
                this.setState({ load_get_fee_delivery: true })
            }
        }

        if (checkInStorageCart(CART_STORAGE_TYPE_PICKUP_DELIVERY_LOCAL.intent)) {
            const { typeDelivery } = getInStorageCart(CART_STORAGE_TYPE_PICKUP_DELIVERY_LOCAL.intent, true)
            this.setState({ typeDelivery: typeDelivery })
        }

        if (checkInStorageCart(CART_STORAGE_RESPONSIBLE_LOCAL.intent)) {
            const { responsible } = getInStorageCart(CART_STORAGE_RESPONSIBLE_LOCAL.intent, true)
            this.setState({ responsible: responsible })
        }
        window.addEventListener('storage', () => {

            if (checkInStorageCart(CART_STORAGE_SERVICE_LOCAL.intent)) {
                const rows = getInStorageCart(CART_STORAGE_SERVICE_LOCAL.intent, true)
                this.setState({ rows: rows })
            }

            if (checkInStorageCart(CART_STORAGE_COUPOM_LOCAL.intent)) {
                const { coupomDiscount } = getInStorageCart(CART_STORAGE_COUPOM_LOCAL.intent, true)
                this.setState({ coupomDiscount: coupomDiscount })
            }

            if (checkInStorageCart(CART_STORAGE_TAB_FEE_DELIVERY_LOCAL.intent)) {
                let tab_fee = getInStorageCart(CART_STORAGE_TAB_FEE_DELIVERY_LOCAL.intent, true)
                if (checkInStorageCart(CART_STORAGE_SERVICE_CACHE_API.intent)) {
                    createCartWithData(this.props, {
                        ACTION_FACTORY: ACTION_ADD_OR_UPDATE_SHIPPING_FEE.intent,
                        key: getInStorageCartNot64(CART_STORAGE_SERVICE_CACHE_API.intent),
                        shipping_fee: tab_fee.price,
                        shipping_fee_list: tab_fee
                    })
                }
            }

            if (checkInStorageCart(CART_STORAGE_ADDRESS_PICKUP_DELIVERY_LOCAL.intent)) {
                const { deliveryAddress } = getInStorageCart(CART_STORAGE_ADDRESS_PICKUP_DELIVERY_LOCAL.intent, true)
                this.setState({ deliveryAddress: deliveryAddress })

                if (checkInStorageCart(CART_STORAGE_SERVICE_CACHE_API.intent)) {
                    createCartWithData(this.props, {
                        ACTION_FACTORY: ACTION_ADD_OR_UPDATE_ADDRESS_DELIVERY.intent,
                        key: getInStorageCartNot64(CART_STORAGE_SERVICE_CACHE_API.intent),
                        delivery_address: deliveryAddress
                    })
                }

                if (checkInStorageCart(PROFILE_STORAGE_SERVICE_LOCAL.intent)) {

                    const { lat, lng } = get_address_company()
                    const { coordinates } = deliveryAddress

                    let coords_origin = coordinates.lat + ',' + coordinates.lng
                    let coords_destination = lat + ',' + lng
                    this.props.distanceCoordinates({
                        latlng_origin: coords_origin,
                        latlng_destination: coords_destination
                    })
                    this.setState({ load_get_fee_delivery: true })
                }
            }


            if (checkInStorageCart(CART_STORAGE_TYPE_PICKUP_DELIVERY_LOCAL.intent)) {
                const { typeDelivery } = getInStorageCart(CART_STORAGE_TYPE_PICKUP_DELIVERY_LOCAL.intent, true)
                this.setState({ typeDelivery: typeDelivery })
            }

            if (checkInStorageCart(CART_STORAGE_RESPONSIBLE_LOCAL.intent)) {
                const { responsible } = getInStorageCart(CART_STORAGE_RESPONSIBLE_LOCAL.intent, true)
                this.setState({ responsible: responsible })
            }
        })

    }

    async mountCartPreSubmission() {

        let params_cart = {
            company_id: 1,
            customer: this.state.responsible,
            channel_id: this.state.channel_id,
            delivery_address: parseInt(this.state.typeDelivery) === 1 ? this.state.deliveryAddress : null,
            type_delivery: parseInt(this.state.typeDelivery),
            delivery_time: this.props.fee_delivery.time_wait,
            products: this.state.rows,
            payment: {
                method: this.state.paymentMethod,
                coupom_discount: this.state.coupomDiscount,
                subtotal: this.state.valueSubTot,
                total: this.state.valueTot,
                rate: this.state.rateDelivery,
                shipping_fee: this.props.fee_delivery.price,
                discount: this.state.discountValue,
                shipping_fee_list: this.props.fee_delivery


            }

        }


        this.props.createOrder(params_cart, this.props.history)

        if (typeof this.props.closeLayoutDrawer === 'function') {
            this.props.closeLayoutDrawer()
        }

    }

    paymentMethodsPage() {
        if (this.state.page >= 1 && this.state.page <= 5) {
            this.setState({ page: 6 })
        }
    }

    cartPage() {

        if (this.state.page === 6) {
            this.setState({ page: 4 })
        }

    }


    nextPageSelected(page) {
        if (page >= 1 && page <= 9) {
            this.setState({ page: page })
        }

    }

    nextPage() {

        if (this.state.page >= 1 && this.state.page <= 5) {
            this.setState({ page: this.state.page + 1 })
        }

    }

    previousPage() {
        if (this.state.page <= 6) {
            this.setState({ page: this.state.page - 1 })
        }

    }
    paramTotalization(item) {

        if (checkInStorageCart(CART_STORAGE_SERVICE_CACHE_API.intent)) {
            createCartWithData(this.props, {
                ACTION_FACTORY: ACTION_ADD_OR_UPDATE_PAYMENT_METHOD.intent,
                key: getInStorageCartNot64(CART_STORAGE_SERVICE_CACHE_API.intent),
                payment: {
                    subtotal: item.valueSubTot,
                    total: item.valueTot,
                    rate: item.rateDelivery,
                    discount: item.discountValue,
                    shipping_fee: this.props.fee_delivery.price,
                    shipping_fee_list: this.props.fee_delivery
                }
            })
        }

        this.setState({
            rateDelivery: item.rateDelivery,
            discountValue: item.discountValue,
            valueTot: item.valueTot,
            valueSubTot: item.valueSubTot,
        })
    }
    async paramPaymentMethods(item) {

        await this.setState({ page: 4, createOrder: true, paymentMethod: item })
        if (checkInStorageCart(CART_STORAGE_SERVICE_CACHE_API.intent)) {
            createCartWithData(this.props, {
                ACTION_FACTORY: ACTION_ADD_OR_UPDATE_PAYMENT_METHOD.intent,
                key: getInStorageCartNot64(CART_STORAGE_SERVICE_CACHE_API.intent),
                payment: { method: item }
            })
        }

        await this.mountCartPreSubmission()

    }

    async paramCouponsDiscounts(item) {
        await this.setState({ coupomDiscount: item })
        createOrUpdateCoupom({ coupomDiscount: item })
        this.previousPage()
    }

    paramResponsibleDelivery(event, values) {
        if (values.veritas === true) {
            let phone = values.phone
            if (phone.substring(0, 2) != '55') {
                phone = ('55'.concat(values.phone)).replace(/[^0-9]/g, '')
            }

            let customer = {
                name: values.name,
                email: values.email,
                phone: phone,
                veritas: values.veritas,
            }

            createOrUpdateResponsible({ responsible: customer })

            // atualiza o carrinho na sessão do servidor.

            if (checkInStorageCart(CART_STORAGE_SERVICE_CACHE_API.intent)) {
                createCartWithData(this.props, {
                    ACTION_FACTORY: ACTION_ADD_OR_UPDATE_CUSTOMER.intent,
                    key: getInStorageCartNot64(CART_STORAGE_SERVICE_CACHE_API.intent),
                    customer: customer
                })
            }
            this.setState({
                responsible: customer
            })
            this.nextPage(values)
        } else {
            NotificationManager.danger("Marque a opção de informações verdadeiras", 'Aviso')
        }

    }

    paramPickupDelivery(item) {
        this.setState({ typeDelivery: item.id })
        // atualiza o carrinho na sessão do servidor.
        if (checkInStorageCart(CART_STORAGE_SERVICE_CACHE_API.intent)) {
            createCartWithData(this.props, {
                ACTION_FACTORY: ACTION_CREATE_OR_UPDATE_ORDER.intent,
                key: getInStorageCartNot64(CART_STORAGE_SERVICE_CACHE_API.intent),
                type_delivery: item.id
            })
        }

        createOrUpdateTypeDelivery({ typeDelivery: item.id })
        if (item.id === 1) {
            this.nextPage()
        } else {
            this.setState({ page: 3 })
        }

    }

    paramAddressPickupDelivery(item) {
        this.setState({ deliveryAddress: item })
        createOrUpdatePickupDelivery({ deliveryAddress: item })

        if (checkInStorageCart(CART_STORAGE_SERVICE_CACHE_API.intent)) {
            createCartWithData(this.props, {
                ACTION_FACTORY: ACTION_ADD_OR_UPDATE_ADDRESS_DELIVERY.intent,
                key: getInStorageCartNot64(CART_STORAGE_SERVICE_CACHE_API.intent),
                delivery_address: item
            })
        }

        if (checkInStorageCart(PROFILE_STORAGE_SERVICE_LOCAL.intent)) {

            const { lat, lng } = get_address_company()
            const { coordinates } = item

            let coords_origin = coordinates.lat + ',' + coordinates.lng
            let coords_destination = lat + ',' + lng
            this.props.distanceCoordinates({
                latlng_origin: coords_origin,
                latlng_destination: coords_destination
            })
            this.setState({ load_get_fee_delivery: true })
        }

        this.nextPage()
    }

    renderPage() {
        const { page, load_get_fee_delivery, typeDelivery } = this.state
        const { duration, distance } = this.props

        if (parseInt(typeDelivery) === 1, distance !== null && distance.value !== null && load_get_fee_delivery === true && parseInt(distance.value) > 0) {
            this.props.searchFeeDelivery({
                company_id: get_id_app_company(),
                distance: (distance.value / 1000) <= 1000 ? (Math.ceil((distance.value / 1000)) * 1000) : 1000
            })
            this.setState({ load_get_fee_delivery: false })
        }

        if (page === 1) {
            window.dataLayer.push({ event: 'view-page-type-pickup-delivery' });
            return <UITypePickupDelivery merchant={!this.props.layout_merchant} funcParamPickup={this.paramPickupDelivery} />
        }
        if (page === 2) {
            return <UIAddressSearchPickupDelivery
                merchant={!this.props.layout_merchant}
                data={this.state.deliveryAddress}
                funcParamAddress={this.paramAddressPickupDelivery}
                previousPage={this.previousPage}
            />
        }
        if (page === 3) {
            window.dataLayer.push({ event: 'view-page-responsible-pickup-delivery' });
            return <UIResponsiblePickupDelivery
                merchant={!this.props.layout_merchant}
                previousPage={this.previousPage}
                data={this.state.responsible}
                funcParamResponsible={this.paramResponsibleDelivery} />
        }
        if (page === 4) {
            return <UIBuyCartViewOrder
                merchant={!this.props.layout_merchant}
                funcParamTotalization={this.paramTotalization}
                deliveryAddress={this.state.deliveryAddress}
                typeDelivery={this.state.typeDelivery}
                fee_delivery={this.props.fee_delivery}
                coupomDiscount={this.state.coupomDiscount}
                openPaymentoMethodsPage={this.paymentMethodsPage}
                nextPage={this.nextPage}
                nextPageSelected={this.nextPageSelected}
                createOrder={this.state.createOrder}
                previousPage={this.previousPage}
                rows={this.state.rows} />
        }
        if (page === 5) {
            window.dataLayer.push({ event: 'view-page-coupons-discounts' });
            return <UICoupomDiscountList
                merchant={!this.props.layout_merchant}
                funcParamCoupons={this.paramCouponsDiscounts}
                nextPage={this.nextPage}
                previousPage={this.previousPage}
                rows={this.state.rows} />
        }//
        if (page === 6) {
            window.dataLayer.push({ event: 'view-page-payment-methods' });
            return <UIPaymentMethodsList
                merchant={!this.props.layout_merchant}
                funcParamPaymentMethods={this.paramPaymentMethods}
                nextPage={this.nextPage}
                previousPage={this.cartPage} />
        }
        if (page === 9) {
            return <UIStatusOrderProgress
                merchant={!this.props.layout_merchant}
                error={this.props.error}
                message={this.props.message}
                nextPage={this.nextPageSelected} />
        }
    }


    render() {

        return (
            <PerfectScrollbar>
                <Card className="react-scroll-mob">
                    <CardBody>
                        <Row className="align-items-md-center  mb-3" style={{ height: '100% !important' }}>
                            {
                                !this.props.layout_merchant &&
                                <Col>
                                    <div className="text-end">
                                        <a onClick={this.props.closeLayoutDrawer}><i
                                            className="mdi mdi-close-thick mdi-18px" /></a>
                                    </div>
                                </Col>
                            }



                            {
                                this.state.rows && !this.state.rows.length > 0
                                    ?

                                    <UICardEmptyCart />
                                    :


                                    this.renderPage()


                            }


                        </Row>
                    </CardBody>
                </Card>
            </PerfectScrollbar>
        )
    }


}

FormBuyCart.propTypes = {
    t: PropTypes.any,
    history: PropTypes.any,
    loading_form: PropTypes.any,
    loading_map: PropTypes.bool,
    loading: PropTypes.any,
    error: PropTypes.any,
    message: PropTypes.any,
    layout_merchant: PropTypes.any,

    duration: PropTypes.any,
    distance: PropTypes.any,
    data_company: PropTypes.any,
    refresh: PropTypes.any,
    fee_delivery: PropTypes.any,
    closeLayoutDrawer: PropTypes.func,
    createOrder: PropTypes.func,
    distanceCoordinates: PropTypes.func,
    searchFeeDelivery: PropTypes.func,
    createOrUpdateOrder: PropTypes.func,
    history: PropTypes.any,


}
const mapStateToProps = state => {
    const {
        loading,
        loading_form,
        error,
        message,
        fee_delivery
    } = state.OrderCart
    const { duration, distance, loading_map } = state.GeoLocation

    return {
        fee_delivery,
        loading,
        loading_map,
        loading_form,
        error,
        message,
        duration,
        distance
    }
}
export default withTranslation()(withRouter(connect(mapStateToProps, {
    createOrder,
    searchFeeDelivery,
    distanceCoordinates,
    createOrUpdateOrder
})(
    (FormBuyCart)
)))
