import {takeEvery, put, call, takeLatest} from "redux-saga/effects"

import {
    FIND_COUPOM_DISCOUNT,
} from "./actionTypes"


import {
    findDiscountCouponsSuccess,
    apiFindDiscountCouponsError
} from "./actions"


import {
    findDiscountCouponsAPI
} from "../../../helpers/discount_coupom_helper"

function* findDiscountCoupons() {
    try {

        const response = yield call(findDiscountCouponsAPI)
        const {data} = response;
        yield put(findDiscountCouponsSuccess(data))

    } catch (error) {
        yield put(apiFindDiscountCouponsError(error))
    }
}


function* CatalogDiscountCouponsSaga() {
    yield takeEvery(FIND_COUPOM_DISCOUNT, findDiscountCoupons)
}

export default CatalogDiscountCouponsSaga
