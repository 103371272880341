import React, { Component } from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import SEO from "components/SEO/index"
import { Stack } from "react-bootstrap"

class Index extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }


    render() {
        const { children, icon_back, visibleCart } = this.props

        return (
            <React.Fragment>
                <div className="container-box">
                    <nav id="navbar_top"
                        className={"w-100 navbar navbar-expand-lg navbar-dark bg-white header-bar-top p-2 mob-tab-bar-top-home-shadow"}>
                        <Stack className="w-100" direction="horizontal" gap={3}>
                            <div className="text-start w-25">
                                <a onClick={() => {
                                    this.props.history.goBack()
                                }} id="app" className="btn header-item noti-icon">

                                    {
                                        icon_back ?
                                            <i className={"mdi mdi-24px  " + icon_back}></i>

                                            :
                                            <i className="mdi mdi-24px mdi-home-search"></i>

                                    }
                                </a>
                            </div>
                            <div className="text-center w-50">
                                <p className="font-got-blk-14-400h">{this.props.title}</p>

                            </div>
                            <div className="text-end  w-25">
                                <a onClick={() => {
                                    this.props.history.push('cart?m=open')
                                }} className="btn header-item noti-icon">
                                    {
                                        visibleCart &&
                                        <>
                                            <i className="mdi mdi-24px mdi-cart-outline" />
                                            <span className="badge bg-danger rounded-pill">{this.state.qtd_items_cart}</span>
                                        </>
                                    }

                                </a>

                            </div>

                        </Stack>
                    </nav>
                    {children}
                </div>
            </React.Fragment>
        )
    }
}

Index.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
    children: PropTypes.any,
    title: PropTypes.any,
    goback: PropTypes.func,
    optionsmenu: PropTypes.func,
    history: PropTypes.object,
    icon_back: PropTypes.any,
    visibleCart: PropTypes.any,

}
const mapStateToProps = state => {

    return {}
}
export default withTranslation()(withRouter(connect(mapStateToProps, {

})(
    (Index)
)))
