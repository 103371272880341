import TagManager from 'react-gtm-module';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';

export function startInitializeGA() {
    ReactGA.initialize('UA-000000-01');
}
export function startInitializeGTM() {
    const tagManagerArgs = {
        gtmId: 'GTM-MX6G69J'
      }
      TagManager.initialize(tagManagerArgs)
}
 
export function startInitializePixelFacebook() {
   // ReactPixel.init('354399660609837');
}
