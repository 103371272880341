import {
    FIND_HOME_PROFILE_COMPANY,
    FIND_HOME_PROFILE_COMPANY_SUCCESS,
    FIND_HOME_PROFILE_COMPANY_API_ERROR
} from "./actionTypes"



export const findHomeProfileCompany = data => {
    return {
        type: FIND_HOME_PROFILE_COMPANY,
        payload: {data},
    }
}

export const findHomeProfileCompanySuccess = results => {

    return {
        type: FIND_HOME_PROFILE_COMPANY_SUCCESS,
        payload: results,
    }
}


export const apiFindHomeProfileCompanyError = error => {
    return {
        type: FIND_HOME_PROFILE_COMPANY_API_ERROR,
        payload: {error},
    }
}

