import {
    CREATE_ORDERS,
    CREATE_ORDERS_SUCCESS,
    SEARCH_FEE_DELIVERY,
    SEARCH_FEE_DELIVERY_SUCCESS,
    CREATE_OR_EDIT_ORDERS_API_ERROR
} from "./actionTypes"


const initialState = {
    loading: false,
    loading_form: false,
    refresh: false,
    error: false,
    message: '',

    name: '',
    sku: '',
    description: '',
    image: '',
    status: 1,
    menu_category_id: 0,
    fee_delivery: {
        id: 0,
        price: 0.0,
        radius: 0,
        time_wait: 0
    },

    id: 0,
    product_id: 0,
}

const createOrEditOrders = (state = initialState, action) => {

    switch (action.type) {
        case CREATE_ORDERS:
            state = {
                ...state,
                refresh: false,
                loading: true
            }
            break
        case CREATE_ORDERS_SUCCESS:
            console.log('error 2 ', action.payload)
            state = {
                ...state,
                message: action.payload.message,
                error: action.payload.error,
                id: action.payload.id,
                product_id: action.payload.id,
                refresh: false,
                loading: false
            }
            break
        case SEARCH_FEE_DELIVERY:
            state = {
                ...state,
                refresh: false,
                loading: true
            }
            break
        case SEARCH_FEE_DELIVERY_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                error: action.payload.error,
                fee_delivery: action.payload.params.result,
                refresh: false,
                loading: false
            }
            break    
        case CREATE_OR_EDIT_ORDERS_API_ERROR:
            state = {...state, error: action.payload, loading: false,  refresh: false}
            break
        default:
            state = {...state}
            break
    }

    return state
}
export default createOrEditOrders
