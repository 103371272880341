import React, { Component } from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import SEO from "components/SEO/index"
import BodyAndBarApp from "../../../../components/BodyAndBarApp/Index"

class Index extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            cache: true
        }
    }


    render() {
        const { result } = this.props

        return (
            <BodyAndBarApp
                visibleCart={false}
                icon_back={"mdi-arrow-left"}
                title="Política de Privacidade"
            >
                <div className="pr-4 px-4">

                    <h1 className="mb-3 text-center">Política de Privacidade</h1>
                    <p><strong className="text-center">Cardápio Digital</strong></p>
                    <p><strong>Pizzaria Fontanella</strong></p>
                    <p><strong>CNPJ: 43.612.624/0001-72</strong></p>
                    <p><strong>Telefone: (19) 99799-1650 / (19) 3500-3907</strong></p>
                    <p><strong>E-mail: <a href="mailto:sac@pizzariafontanella.com.br" target="_new">sac@pizzariafontanella.com.br</a></strong></p>
                    <p><strong>Última Atualização: 21/10/2023 00:32</strong></p>

                    <p>A Pizzaria Fontanella (doravante referida como "nós", "nosso" ou "do cardápio digital") valoriza a sua privacidade e está comprometida em proteger suas informações pessoais. Esta política de privacidade descreve como coletamos, usamos e protegemos as informações que você nos fornece ao usar nosso cardápio digital.</p>
                    <p><strong>Informações que Coletamos</strong></p>
                    <ul><li><p><strong>Informações de Contato:</strong> Para processar pedidos e fornecer atualizações sobre o status do pedido, coletamos informações de contato, como nome, endereço de e-mail e número de telefone.</p></li><li><p><strong>Informações de Pagamento:</strong> Para processar transações, coletamos informações financeiras, como números de cartão de crédito ou outras informações de pagamento.</p></li><li><p><strong>Informações do Pedido:</strong> Coletamos detalhes dos pedidos que você faz em nosso cardápio digital, incluindo itens pedidos, data e hora do pedido.</p></li><li><p><strong>Informações de Localização:</strong> Se você optar por compartilhar sua localização, coletamos informações de localização para fornecer serviços com base na sua localização.</p></li></ul>
                    <p><strong>Como Usamos as Informações</strong></p>
                    <ul><li><p>Usamos as informações que coletamos para processar pedidos, fornecer serviços personalizados e aprimorar a experiência do usuário.</p></li><li><p>Podemos usar informações de contato para enviar atualizações sobre pedidos, promoções ou informações importantes.</p></li><li><p>As informações de pagamento são usadas exclusivamente para processar transações.</p></li><li><p>As informações do pedido nos ajudam a preparar e entregar os pedidos de forma eficiente.</p></li></ul>
                    <p><strong>Proteção de Dados</strong></p>
                    <ul><li><p>Mantemos medidas de segurança para proteger as informações dos usuários contra acesso não autorizado, uso indevido ou divulgação.</p></li><li><p>Não vendemos, alugamos ou compartilhamos informações pessoais com terceiros sem o seu consentimento.</p></li></ul>
                    <p><strong>Cookies e Tecnologias de Rastreamento</strong></p>
                    <ul><li><p>Usamos cookies e tecnologias semelhantes para melhorar a funcionalidade do cardápio digital e coletar informações de uso.</p></li><li><p>Você pode controlar o uso de cookies nas configurações do seu navegador.</p></li></ul>
                    <p><strong>Alterações na Política de Privacidade</strong></p>
                    <ul><li>Reservamo-nos o direito de atualizar esta política de privacidade periodicamente. Qualquer alteração será publicada em nosso cardápio digital.</li></ul>
                    <p><strong>Seus Direitos</strong></p>
                    <ul><li>Você tem o direito de acessar, corrigir e excluir suas informações pessoais. Entre em contato conosco para exercer esses direitos.</li></ul>
                    <p><strong>Entre em Contato Conosco</strong></p>
                    <p>Se você tiver dúvidas ou preocupações sobre esta política de privacidade ou sobre suas informações pessoais, entre em contato conosco pelo e-mail <a href="mailto:sac@pizzariafontanella.com.br" target="_new">sac@pizzariafontanella.com.br</a>.</p>


                </div>
            </BodyAndBarApp>
        )
    }
}

Index.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
    loading: PropTypes.bool,
    error: PropTypes.any,
    message: PropTypes.any,
    result: PropTypes.object,
    apiFindHomeProfileCompanyError: PropTypes.func,
    findHomeProfileCompany: PropTypes.func,
    pageSelector: PropTypes.func


}
const mapStateToProps = state => {

    return {}
}
export default withTranslation()(withRouter(connect(mapStateToProps, {
})(
    (Index)
)))
