import React from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { createOrUpdateOrder, findHomeProfileCompany, apiGetPromotionsProductsError, getPromotionProductBySlug } from "../../../store/actions"
import ImageURI from "components/ImageURI"
import Generics from "./Generics"
import { Stack } from "react-bootstrap"
import Pizza from "./Pizza"
import SEO from "components/SEO/index"
import UILoadingAnim from "../Components/UILoadingAnim"
import BodyAndBarApp from "../../../components/BodyAndBarApp/Index"
import { checkInStorageCart, createCart, createCartWithCompany, getInStorageCart, get_id_app_company, get_name_company, get_slug_app_company } from "logic/storageAPI"
import { ACTION_CREATE_OR_UPDATE_ORDER, CART_STORAGE_SERVICE_CACHE_API, CART_STORAGE_SERVICE_LOCAL, PROFILE_STORAGE_SERVICE_LOCAL } from "logic/keyStorageAPI"


/**
* Montagem do pedido lanches  que possui diversos complementos.
*/
class Index extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            qtd_items_cart: 0,
            name_company: null,
            company_id: null,
            load_url_entity: false,
            utm_source: null,
            utm_medium: null,
            utm_campaign: null,
            show_page: false
        }
        this.shared = this.shared.bind(this)

    }


    componentDidMount() {
        const query = new URLSearchParams(this.props.location.search);
        const q_search = query.get('q')
        let utm_source = query.get('utm_source')
        let utm_medium = query.get('utm_medium')
        let utm_campaign = query.get('utm_campaign')
        let entiy_company = query.get('etn_app')

        this.setState({ utm_source, utm_medium, utm_campaign })

        if (entiy_company) {
            let entity = JSON.parse(window.atob(entiy_company))

            const { name_company, company_id, load_url_entity } = entity
            this.setState({ show_page: true, name_company, company_id, load_url_entity })

            if (!checkInStorageCart(CART_STORAGE_SERVICE_CACHE_API.intent)) {
                createCartWithCompany(this.props, ACTION_CREATE_OR_UPDATE_ORDER.intent, company_id)
            }
        }

        window.dataLayer.push({ event: 'view-page-promotion' });
        if (checkInStorageCart(CART_STORAGE_SERVICE_LOCAL.intent)) {
            let prods = getInStorageCart(CART_STORAGE_SERVICE_LOCAL.intent, true)
            this.setState({ qtd_items_cart: prods.length })
        } else {
            this.setState({ qtd_items_cart: 0 })
        }


        window.addEventListener('storage', () => {
            if (checkInStorageCart(CART_STORAGE_SERVICE_LOCAL.intent)) {
                let prods = getInStorageCart(CART_STORAGE_SERVICE_LOCAL.intent, true)
                this.setState({ qtd_items_cart: prods.length })
            } else {
                this.setState({ qtd_items_cart: 0 })
            }

            if (checkInStorageCart(PROFILE_STORAGE_SERVICE_LOCAL.intent)) {
                this.setState({ show_page: checkInStorageCart(PROFILE_STORAGE_SERVICE_LOCAL.intent) })
            }
        });
        new Promise(async (resolve, reject) => {
            if (checkInStorageCart(PROFILE_STORAGE_SERVICE_LOCAL.intent) === false) {
                this.props.findHomeProfileCompany(get_slug_app_company())
            }
            resolve({ succes: true });
        }).then((succes) => {
            this.props.getPromotionProductBySlug(q_search)
            this.setState({ loading: false, cache: false })

        });


    }

    async shared(event) {
        const data_share = {
            title: document.title,
            text: this.props.row.description,
            url: window.location,
        }

        if (navigator.share) {
            await navigator
                .share(data_share).then(() => {
                    console.log("Successfully shared");
                })
                .catch((error) => {
                    console.error("Something went wrong", error);
                });
        }
    }



    render() {
        const { show_page, name_company, company_id, load_url_entity, utm_source, utm_medium, utm_campaign } = this.state

        return (
            <React.Fragment>
                {this.props.loading
                    ?
                    <div style={{
                        height: '700px !important',
                        marginBottom: '400px'

                    }}>
                        <UILoadingAnim
                            url_anim={7} />
                    </div>
                    :
                    show_page && this.props.row &&
                    <BodyAndBarApp
                        icon_back="mdi-arrow-left"
                        visibleCart={true}
                        title={load_url_entity ? name_company : get_name_company()}>


                        <ImageURI
                            style_div="container-img-box text-center"
                            style_img="thumbnail img-responsive img-promo-mchant "
                            url={this.props.row.url_image}
                            alt={this.props.row.name} />


                        <div className="container-top-float bg-white border boder-1 w-100">

                            {
                                this.props.row.type == 2
                                    ?
                                    <Generics
                                        name_company={load_url_entity ? name_company : get_name_company()}
                                        company_id={load_url_entity ? company_id : get_id_app_company()}
                                        load_url_entity={load_url_entity}
                                        utm_source={utm_source}
                                        utm_medium={utm_medium}
                                        utm_campaign={utm_campaign}
                                        is_promotion={true}
                                        sharedArticle={(event) => this.shared(event)}
                                        row={this.props.row}
                                        size_height={window.innerHeight}
                                    />

                                    :
                                    <Pizza
                                        name_company={load_url_entity ? name_company : get_name_company()}
                                        company_id={load_url_entity ? company_id : get_id_app_company()}
                                        load_url_entity={load_url_entity}
                                        utm_source={utm_source}
                                        utm_medium={utm_medium}
                                        utm_campaign={utm_campaign}
                                        is_promotion={true}
                                        sharedArticle={(event) => this.shared(event)}
                                        row={this.props.row}
                                        size_height={window.innerHeight}
                                    />
                            }

                        </div>


                    </BodyAndBarApp>
                    /*<>
                        <SEO
                            title={this.props.result.fantasy_name + ' | ' + this.props.row.category.name + ' | ' + this.props.row.name}
                            description={this.props.row.category.name + ' | ' + this.props.row.name + ' | ' + this.props.row.description}
                            name={this.props.result.fantasy_name}
                            image={this.props.row.url_image}
                            app_id="570936618349861"
                            type='article' />

                        <div className="container-box">
                            <nav id="navbar_top"
                                className={"w-100 navbar navbar-expand-lg navbar-dark bg-white header-bar-top p-2 mob-tab-bar-top-home-shadow"}>
                                <Stack className="w-100" direction="horizontal" gap={3}>
                                    <div className="text-start w-25">
                                        <a onClick={() => {
                                            this.props.history.push('app')
                                        }} id="app" className="btn header-item noti-icon">
                                            <i className="mdi mdi-24px mdi-home-search"></i>
                                        </a>
                                    </div>
                                    <div className="text-center w-50">
                                        <p className="font-got-blk-14-400h">{this.props.result.fantasy_name}</p>

                                    </div>
                                    <div className="text-end  w-25">
                                        <a onClick={() => {
                                            this.props.history.push('cart?m=open')
                                        }} className="btn header-item noti-icon">
                                            <i className="mdi mdi-24px mdi-cart-outline" />
                                            <span className="badge bg-danger rounded-pill">{this.state.qtd_items_cart}</span>
                                        </a>

                                    </div>

                                </Stack>
                            </nav>
                            <div className={this.props.row.category.model_menu_id == 2 ? "img_front_both_1" : "img_front_both_2"}>
                                <ImageURI
                                    style_div="img-square-wrapper text-center w-100"
                                    style_img="thumbnail img-responsive  "
                                    url={this.props.row.url_image}
                                    alt={this.props.row.name} />


                                <div className="container-top-float">

                                    {
                                        this.props.row.category.model_menu_id != 1
                                            ?

                                            <Generics
                                                sharedArticle={(event) => this.shared(event)}
                                                row={this.props.row}
                                                size_height={window.innerHeight}
                                            />
                                            :
                                            <Pizza
                                                sharedArticle={(event) => this.shared(event)}
                                                row={this.props.row}
                                                size_height={window.innerHeight}
                                            />
                                    }
                                </div>
                            </div>

                        </div>
                    </>*/
                }

            </React.Fragment>
        )
    }
}

Index.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
    match: PropTypes.any,
    refresh: PropTypes.any,
    type: PropTypes.string,
    loading: PropTypes.any,
    toggleModal: PropTypes.func,
    paramsBody: PropTypes.any,
    size_height: PropTypes.any,
    row: PropTypes.any,
    result: PropTypes.any,
    history: PropTypes.object,
    getPromotionProductBySlug: PropTypes.func,
    apiGetPromotionsProductsError: PropTypes.func,
    createOrUpdateOrder: PropTypes.func,
    findHomeProfileCompany: PropTypes.func,

}
const mapStateToProps = state => {
    const {
        error,
        message,
        result
    } = state.ProfileCompany
    const {
        loading,
        row
    } = state.Promotion
    return {
        loading,
        row,
        result
    }
}
export default withTranslation()(withRouter(connect(mapStateToProps, {
    apiGetPromotionsProductsError,
    getPromotionProductBySlug,
    createOrUpdateOrder,
    findHomeProfileCompany
})(
    (Index)
)))
