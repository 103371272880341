import {
    FIND_PRODUCTS_BY_ID_CATEGORY,
    FIND_PRODUCTS_BY_ID_CATEGORY_SUCCESS,
    FIND_PRODUCTS_BY_ID_CATEGORY_API_ERROR,
} from "./actionTypes"


export const findProductsByCategory = query => {
    return {
        type: FIND_PRODUCTS_BY_ID_CATEGORY,
        payload: {query},
    }
}

export const findProductsByCategorySuccess = data => {
    return {
        type: FIND_PRODUCTS_BY_ID_CATEGORY_SUCCESS,
        payload: {data},
    }
}

export const apiFindProductsByCategoryError = error => {
    return {
        type: FIND_PRODUCTS_BY_ID_CATEGORY_API_ERROR,
        payload: {error},
    }
}

