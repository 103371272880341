import React from "react"

import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { apiFindHomeCompanyError, findHomeProfileCompany, createOrUpdateOrder, findHomeHashCompany, findHomeQueryCompany } from "../../../store/actions"
import PropTypes from "prop-types"
import UICardViewNoImage from "../Components/UICardViewNoImage"
import UICardViewImage from "../Components/UICardViewImage"
import ModalProductsFactory from "../Components/ModalProductsFactory"
import { ScrollMenu } from "react-horizontal-scrolling-menu"
import ReactDrawer from "react-drawer"
import FormBuyCart from "../../Menu/FormBuyCart"
import PullToRefresh from 'react-simple-pull-to-refresh'
import cover from "./../../../assets/img/placeholder_pizzaria.jpg"
import UILoadingAnim from "../Components/UILoadingAnim"
import ImageURI from "components/ImageURI"
import { Stack } from "react-bootstrap"
import { Progress } from "reactstrap"
import PromotionSection from "./../Components/Promotions/Index"

import { check_open_company, get_rows_menus_company, createCart, checkInStorageCart, get_img_logo_company, get_name_company, get_img_front_cover_company, getInStorageCart, get_slug_app_company, get_id_app_company } from "../../../logic/storageAPI"
import { CART_STORAGE_SERVICE_LOCAL, CART_STORAGE_SERVICE_CACHE_API, ACTION_CREATE_OR_UPDATE_ORDER } from "../../../logic/keyStorageAPI"
import productsCategory from "store/catalog/productsByCategory/reducer"
import UICarousel from "../../../components/CarouselBanner/index"

class Index extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            open: false,
            position: 'right',
            noOverlay: false,

            openRatings: false,
            noOverlayRatings: false,

            positionCartNotify: 'bottom',
            openCartNotify: false,
            noOverlayCartNotify: false,

            modal: false,
            oPenProductsFactoryModal: false,
            modalParam: {},

            openCartItens: false,
            qtd_items_cart: 0,
            showing_search_view: false,
            navbarItems: [],
            menus: [],
            rows_data: [],

        }

        // this.toggleproductsfactorymodal.bind(this)
        this.toggleDrawer = this.toggleDrawer.bind(this)
        this.toggleDrawer = this.toggleDrawer.bind(this)
        this.closeDrawer = this.closeDrawer.bind(this)
        this.onDrawerClose = this.onDrawerClose.bind(this)
        this.getParamsModal = this.getParamsModal.bind(this)

        this.toggleDrawerNotifyCartItems = this.toggleDrawerNotifyCartItems.bind(this)
        this.closeDrawerNotifyCartItems = this.closeDrawerNotifyCartItems.bind(this)
        this.onDrawerNotifyCartItemsClose = this.onDrawerNotifyCartItemsClose.bind(this)
        this.setNoOverlayNotifyCartItems = this.setNoOverlayNotifyCartItems.bind(this)

        this.bandageNotifyCartItems = this.bandageNotifyCartItems.bind(this)
        this.onCartClose = this.onCartClose.bind(this)

    }

    componentDidMount() {
        let rows_data = get_rows_menus_company()

        if (rows_data != undefined && rows_data.length > 0) {
            this.setState({ rows_data: rows_data })
        }

        const self = this

        if (!checkInStorageCart(CART_STORAGE_SERVICE_CACHE_API.intent)) {
            createCart(this.props, ACTION_CREATE_OR_UPDATE_ORDER.intent)
        }

        if (checkInStorageCart(CART_STORAGE_SERVICE_LOCAL.intent)) {
            let prods = getInStorageCart(CART_STORAGE_SERVICE_LOCAL.intent, true)
            this.bandageNotifyCartItems()
            this.setState({ qtd_items_cart: prods.length })

        }

        window.addEventListener('storage', () => {

            if (checkInStorageCart(CART_STORAGE_SERVICE_LOCAL.intent)) {
                let prods = getInStorageCart(CART_STORAGE_SERVICE_LOCAL.intent, true)
                this.toggleDrawerNotifyCartItems()
                self.setState({ qtd_items_cart: prods.length })
            } else {
                this.toggleDrawerNotifyCartItems()
                self.setState({ qtd_items_cart: 0 })
            }
        })


        window.addEventListener('scroll', function () {

            if (window.scrollY > 10) {
                if (document.getElementById('navbar_top')) {
                    document.getElementById('navbar_top').classList.add('fixed-top')

                    // add padding top to show content behind navbar
                    var navbar_height = document.querySelector('.navbar').offsetHeight
                    document.body.style.paddingTop = navbar_height + 'px'
                    self.setState({ showing_search_view: true })
                }

            } else {
                if (document.getElementById('navbar_top')) {
                    document.getElementById('navbar_top').classList.remove('fixed-top')
                    // remove padding top from body
                    document.body.style.paddingTop = '0'
                    self.setState({ showing_search_view: false })
                }
            }

        })
        window.addEventListener('resize', function () {
            if (window.innerWidth > 800) {
                window.location.reload(false);
            }

        })
    }

    bandageNotifyCartItems() {

    }

    onCartClose() {
        this.setState({ openCartItens: false })
    }

    toggleDrawer() {
        this.setState({ open: !this.state.open, openRatings: false })
    }

    closeDrawer() {
        this.setState({ open: false })
    }

    onDrawerClose() {
        this.setState({ open: false })

    }

    toggleDrawerNotifyCartItems() {
        this.setState({ openCartNotify: !this.state.openCartNotify, open: false })
    }

    closeDrawerNotifyCartItems() {
        this.setState({ openCartNotify: false })
    }

    onDrawerNotifyCartItemsClose() {
        this.setState({ openCartNotify: false })
    }

    setNoOverlayNotifyCartItems(e) {
        this.setState({ noOverlayCartNotify: e.target.checked })
    }

    getParamsModal(params) {
        this.setState({ modalParam: params })
    }


    toggleproductsfactorymodal = () => {
        this.setState(prevState => ({
            oPenProductsFactoryModal: !prevState.oPenProductsFactoryModal,
        }))
    }

    handleRefresh() {
        this.props.findHomeProfileCompany(get_slug_app_company())

    }

    render() {
        const { showing_search_view, navbarItems } = this.state
        let rows_data = [];
        if (this.state.rows_data && this.state.rows_data.length > 0) {
            rows_data = this.state.rows_data
        }
        if (this.state.refresh && this.props.rows && this.props.rows.length > 0) {
            rows_data = this.props.rows
            this.setState({ refresh: false, rows_data: rows_data })

        }


        return (
            <React.Fragment>

                <header>

                    {
                        !showing_search_view &&
                        <div className="bg-home-cover text-center "
                            style={get_img_front_cover_company() ? { backgroundImage: `url(${get_img_front_cover_company()})` } : { backgroundImage: `url(${cover})` }}>
                        </div>
                    }

                    <nav id="navbar_top"
                        className={!showing_search_view ? "navbar navbar-expand-lg navbar-dark bg-white bg-top-menu-home-cover mob-tab-bar-top-home-shadow" : "navbar navbar-expand-lg navbar-dark bg-white mob-tab-bar-top-home-shadow"}>

                        <div className="mob-tab-bar-top-home">

                            {
                                !showing_search_view &&
                                <>
                                    <div className=" p-4">
                                        <Row>
                                            <Col >
                                                <Row>
                                                    <Col sm={12}>
                                                        <p>
                                                            <span
                                                                className="font-got-blk-14-400h mt-2">{get_name_company()}</span><br />
                                                            {
                                                                check_open_company() === 0
                                                                    ?
                                                                    <span
                                                                        className="badge rounded-pill bg-danger mt-2">Estamos Fechados </span>
                                                                    :
                                                                    <span
                                                                        className="badge rounded-pill bg-success mt-2">Estamos Abertos </span>
                                                            }

                                                        </p>
                                                    </Col>
                                                    <Col sm={12}>
                                                        <p className="text-start card-text font-bl-12-400h">
                                                            (Estamos  em desenvolvimento!)
                                                        </p>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col>
                                                <ImageURI
                                                    style_div="img-square-wrapper rounded-2"
                                                    style_img="float-end rounded-2 img-home-logo img-fluid"
                                                    url={get_img_logo_company()}
                                                    alt={get_name_company()} />

                                            </Col>
                                        </Row>

                                    </div>

                                </>
                            }


                            <Row style={{ backgroundColor: '#fff' }}>
                                <Col sm={12}>
                                    {
                                        showing_search_view &&
                                        <div className="input-container mb-3">
                                            <i className="mdi mdi-24px mdi-database-search-outline m-1"></i>
                                            <input className="input-field rounded-2 " type="text"
                                                placeholder="Buscar no cardápio"
                                                onChange={(e) => {
                                                    const { name, value } = e.target

                                                    if (value.length >= 3) {
                                                        this.props.findHomeQueryCompany({
                                                            id: get_id_app_company(),
                                                            value: value
                                                        })
                                                    }

                                                    if (value === '') {
                                                        this.props.findHomeProfileCompany(get_slug_app_company())
                                                    }


                                                }} />

                                        </div>
                                    }

                                </Col>
                                <Col sm={12}>
                                    <ScrollMenu>

                                        {
                                            rows_data && rows_data.length > 0 &&
                                            rows_data.map((item, index) => (

                                                item.itens.length > 0 &&
                                                <div key={index} className="category-menus-grid">
                                                    <div key={index}
                                                        className="category-menus-grid-item item-grid-size">
                                                        <div id={item.slug} name={item.slug}
                                                            onClick={() => {
                                                                document.querySelector(`[id=${item.slug}]`).scrollIntoView()
                                                            }}
                                                            className="font-got-blk-13-400h p-2 text-center">{item.name.toUpperCase()}
                                                        </div>
                                                    </div>
                                                </div>


                                            ))}

                                    </ScrollMenu>
                                </Col>

                            </Row>

                        </div>

                    </nav>
                    <hr />
                </header>

                <div className="m-2">
                    <UICarousel />
                </div>


                <div className={"mob-bg-top-banner text-center p-2 "}
                    style={showing_search_view ? { marginTop: "45px" } : { marginTop: "0px" }}>


                    <PullToRefresh onRefresh={() => {
                        this.setState({ refresh: true })
                        this.props.findHomeProfileCompany(get_slug_app_company())
                    }}
                        canFetchMore={rows_data && rows_data.length > 0}
                        isPullable={rows_data && rows_data.length > 0}>

                        <Row className={this.props.itens_cart > 0 ? "m-b-110" : "mb-5"}>

                            {
                                rows_data && rows_data.length > 0 &&
                                rows_data.map((item, index) => {

                                    return (
                                        <section key={index} name={item.slug} id={item.slug}>
                                            {
                                                item.itens && item.itens.length > 0 &&
                                                <div key={index} className={"mb-2 mt-1"}>

                                                    <div className="col-sm-6">
                                                        <h2 className="text-start  font-got-blk-14-400h">{item.name.toUpperCase()}
                                                            {item.type == 2 && item.promotions == 0 && <small className="text-danger font-got-lt-10-300h fw-bold">  *{item.description}</small>}</h2>
                                                    </div>
                                                    <Row>
                                                        {
                                                            item.promotions == 1
                                                                ?
                                                                item.itens && item.itens.length > 0 &&
                                                                <PromotionSection rows={item.itens} />
                                                                :
                                                                item.itens && item.itens.length > 0 &&
                                                                item.itens.map((p, index) => {
                                                                    return (
                                                                        <>
                                                                            {
                                                                                item.type == 1 &&
                                                                                <UICardViewNoImage
                                                                                    type={item.type}
                                                                                    desc={item.description}
                                                                                    nameGroup={item.name}
                                                                                    idGroup={item.id}
                                                                                    prod={p}
                                                                                    toggleproductsmodal={this.toggleproductsfactorymodal}
                                                                                    getParamsModal={this.getParamsModal}
                                                                                />
                                                                            }
                                                                            {
                                                                                item.type == 2 &&
                                                                                <UICardViewImage
                                                                                    type={item.type}
                                                                                    desc={item.description}
                                                                                    url_image={item.url_image}
                                                                                    nameGroup={item.name}
                                                                                    idGroup={item.id}
                                                                                    price_type={item.price_type}
                                                                                    prod={p}
                                                                                    toggleproductsmodal={this.toggleproductsfactorymodal}
                                                                                    getParamsModal={this.getParamsModal}
                                                                                />
                                                                            }
                                                                        </>
                                                                    )
                                                                })
                                                        }
                                                    </Row>

                                                </div>
                                            }
                                        </section>


                                    )
                                })

                            }
                        </Row>
                    </PullToRefresh>
                </div>

                <ReactDrawer
                    open={this.state.open}//this.state.open ||
                    position={this.state.position}
                    onClose={this.onDrawerClose}
                    noOverlay={this.state.noOverlay}>

                    <FormBuyCart
                        rows={this.props.rows}
                        closeLayoutDrawer={this.closeDrawer}
                    />
                </ReactDrawer>
                <ModalProductsFactory
                    params={this.state.modalParam}
                    oPenProductsFactoryModal={this.state.oPenProductsFactoryModal}
                    toggleModal={this.toggleproductsfactorymodal}
                />

                {
                    this.state.qtd_items_cart > 0 &&
                    <a onClick={() => {
                        this.setState({ open: !this.state.open })
                    }} className="bg-dark box-cart-footer-align_with_progress">

                        <Stack gap={2}>
                            {/*<div className="bg-white border-0">
                                <span className="p-3 text-danger m-2 font-got-blk-13-400h">Ganhe frete grátis com mais R$ 15,00</span>
                                <div className="bg-danger">
                                    <Progress className=" custom-progess progress-lg border-0" color="danger" value={25} />
                                </div>
                            </div>*/}
                            <div><Stack direction="horizontal" gap={3}>
                                <div className="w-25">
                                    <a className="btn header-item noti-icon">
                                        <i className="mdi mdi-24px text-light mdi-cart-outline" />
                                        <span
                                            className="badge bg-danger rounded-pill">{this.state.qtd_items_cart}</span>
                                    </a>
                                </div>
                                <div className="w-50">
                                    <h1 className="text-center text-light font-got-blk-13-400h mb-2">{this.state.qtd_items_cart > 0 ? 'Há itens na sacola' : 'Selecione os itens e adicione na sacola'}</h1>
                                </div>
                                <div className="w-25">
                                    <a className="btn header-item noti-icon mx-4">
                                        <i className="mdi mdi-24px text-light mdi-arrow-right" />

                                    </a>
                                </div>
                            </Stack></div>
                        </Stack>
                    </a>
                }



            </React.Fragment>


        )
    }
}

Index.propTypes = {
    rows: PropTypes.any,
    loading: PropTypes.any,
    apiFindHomeCompanyError: PropTypes.func,
    findHomeHashCompany: PropTypes.func,
    findHomeQueryCompany: PropTypes.func,
    openCart: PropTypes.bool,
    closeCart1: PropTypes.func,
    itens_cart: PropTypes.any,
    rows: PropTypes.any,
    createOrUpdateOrder: PropTypes.func,
    findHomeProfileCompany: PropTypes.func,
}

const mapStateToProps = state => {
    const {
        loading,
        rows
    } = state.Catalog
    return {
        loading,
        rows
    }
}

export default withRouter(connect(mapStateToProps, {
    findHomeProfileCompany,
    findHomeHashCompany,
    findHomeQueryCompany,
    apiFindHomeCompanyError,
    createOrUpdateOrder
})(Index)
)
