import React from "react";
import { Modal } from "reactstrap";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ModalContentType1 from "./ModalContentType1";
import ModalContentType2 from "./ModalContentType2";
import ModalContentType3 from "./ModalContentType3";
import slugify from 'react-slugify';

/**
 * Modal para montagem do pedido.
 */
class ModalProductsFactory extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}


    }

    componentDidMount() {
        window.dataLayer.push({ event: slugify('view-page-product-'.concat(this.props.params.nameGroup)) });
    }

    render() {

        return <Modal
            isOpen={this.props.oPenProductsFactoryModal}
            role="dialog"
            autoFocus={true}
            data-toggle="modal"
            modalTransition={{ timeout: 100 }}
            centered
            size={100}
            fullscreen="lg"
            toggle={this.props.toggleModal}
        >
            <div className="modal-content"> 
                <div className="modal-header border-bottom-0 mob-tab-bar-top-home mb-2">
                    <span className=" text-center font-got-lt-14-300h">{this.props.params.type == 2 ? this.props.params.title : 'Monte o seu pedido'}</span>

                    <button type="button" className="btn-close" onClick={this.props.toggleModal} data-bs-dismiss="modal"
                        aria-label="Close"></button>

                </div>

                {
                    this.props.params.type == 1 &&
                        this.props.params.prod_type === 1
                        ?
                        <ModalContentType3
                            key={1}
                            size_height={window.innerHeight}
                            paramsBody={this.props.params}
                            toggleModal={this.props.toggleModal}
                        />
                        :
                        this.props.params.type == 2
                            ?
                            <ModalContentType2
                                key={2}
                                size_height={window.innerHeight}
                                paramsBody={this.props.params}
                                toggleModal={this.props.toggleModal}
                            />
                            :
                            <ModalContentType1
                                key={1}
                                size_height={window.innerHeight}
                                paramsBody={this.props.params}
                                toggleModal={this.props.toggleModal}
                            />
                }


            </div>
        </Modal>
    }
}

ModalProductsFactory.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
    refresh: PropTypes.any,
    type: PropTypes.string,
    loading: PropTypes.any,
    toggleModal: PropTypes.func,
    oPenProductsFactoryModal: PropTypes.bool,
    params: PropTypes.any


}
const mapStateToProps = state => {
    return {}
}
export default withTranslation()(withRouter(connect(mapStateToProps, {})(
    (ModalProductsFactory)
)))
