import React from "react"
import { Button, Col, Input, Modal, Row } from "reactstrap"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { createOrUpdateOrder, apiFindProductsByCategoryError, findProductsByCategory } from "../../store/actions"
import PerfectScrollbar from "react-perfect-scrollbar"
import NumberFormat from "../../components/NumberFormat"
import UICardViewComplementSingle from "./UICardViewComplementSingle"
import UICardViewComplementMultiple from "./UICardViewComplementMultiple"
import UILoadingAnim from "../Mobile/Components/UILoadingAnim"
import toast from "react-hot-toast"
import ImageURI from "components/ImageURI"
import { Stack } from "react-bootstrap"

/**
 * Modal para montagem do pedido lanches pizza que possui diversos complementos.
 */
class ModalContentType2 extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            observation: '',
            valueTot: 0.0,
            qtd: 0,
            refresh: false,
            countSelected: 0,
            product_selected: false,
            complement_selected: false
        }

        this.handleSub = this.handleSub.bind(this)
        this.handleAdd = this.handleAdd.bind(this)
        this.sub = this.sub.bind(this)
        this.add = this.add.bind(this)

        this.handleRadioButtomProducts = this.handleRadioButtomProducts.bind(this)
        this.handleSumValueItems = this.handleSumValueItems.bind(this)
        this.handleAddToCart = this.handleAddToCart.bind(this)
        this.handleFields = this.handleFields.bind(this)

    }

    componentDidMount() {
        this.props.findProductsByCategory(this.props.paramsBody.id)
    }
    handleValidatorFields() {
        const { product_selected, complement_selected, countSelected } = this.state;
        let validator = true;

        if (!product_selected) {
            toast.error('Informe a quantidade')
            validator = false
        }
        if (countSelected > 0) {
            if (!complement_selected) {
                toast.error('Escolha o complemento')
                validator = false
            }
        }


        return validator
    }
    handleAddToCart() {
        if (this.handleValidatorFields()) {
            const { complements, id, name, description, type } = this.props.row
            let cart = (window.localStorage.getItem("cart") ? JSON.parse(atob(window.localStorage.getItem("cart"))) : [])

            //  console.log("cart 1",this.props.row)
            let cart_obg = {}
            if (cart.length > 0) {
                cart.forEach(group => {
                    if (parseInt(group.idGroup) === parseInt(this.props.paramsBody.idGroup)) {
                        cart_obg = group
                    }
                })
            }
            // console.log("cart cart_obg ", cart_obg)
            if (Object.keys(cart_obg).length === 0) {

                cart_obg = {
                    id: this.props.paramsBody.id,
                    idGroup: this.props.paramsBody.idGroup,
                    prod_type: this.props.paramsBody.prod_type,
                    groupName: this.props.paramsBody.nameGroup,
                    price: this.props.paramsBody.price,
                    qtd: this.state.qtd,
                    type: 2,
                    items: [],
                    /* items: [{
                         id: id,
                         name: name,
                         description: description,// this.props.paramsBody.description,
                         qtd: this.state.qtd,
                         price: this.props.paramsBody.price,
                         //total: (parseInt(product.qtd) * parseFloat(product.price))
                         complements: [],
                     }],*/
                    observation: this.state.observation
                }

            }


            if (complements.length > 0) { // se existir complementos
                complements.forEach(complement => {

                    let complement_product_selected = []
                    complement.products.forEach(product => {

                        if (cart_obg.items.length > 0) { // se existir itens dentro do grupo, então atualize
                            cart_obg.items.map((item, index0) => {

                                if (parseInt(item.id) === parseInt(id)) {
                                    item.qtd = (item.qtd + this.state.qtd)
                                }

                                //  console.log('PROD Select ', product.isSelected)
                                if (product.isSelected === true || parseInt(product.qtd) > 0) {
                                    //   console.log('PROD Select ', product.name)
                                    //   console.log('PROD Select ', (cart_obg.items[index0].complements.length > 0))

                                    if (cart_obg.items[index0].complements.length > 0) {
                                        cart_obg.items[index0].complements.map((complement, index) => {

                                            if (parseInt(complement.id) === parseInt(product.id)) {
                                                cart_obg.items[index0].complements[index].qtd = cart_obg.items[0].complements[index].qtd + 1
                                            } else {
                                                complement_product_selected.push({
                                                    id: product.id,
                                                    name: product.name,
                                                    prod_type: this.props.paramsBody.prod_type,
                                                    description: `${product.qtd}x ${product.name}`,
                                                    qtd: product.qtd,
                                                    price: product.price
                                                })
                                                /* cart_obg.items[index0].complements.push({
                                                     id: product.id,
                                                     name: product.name,
                                                     qtd: product.qtd,
                                                     price: product.price
                                                 })*/
                                            }
                                        })
                                    } else {
                                        complement_product_selected.push({
                                            id: product.id,
                                            name: product.name,
                                            prod_type: this.props.paramsBody.prod_type,
                                            description: `${product.qtd}x ${product.name}`,
                                            qtd: product.qtd,
                                            price: product.price
                                        })
                                        /*cart_obg.items[index0].complements.push({
                                            id: product.id,
                                            name: product.name,
                                            qtd: product.qtd,
                                            price: product.price
                                        })*/
                                    }
                                    // console.log('complement_product_selected ', complement_product_selected)
                                }
                            })
                        } else {
                            if (product.isSelected === true || parseInt(product.qtd) > 0) {
                                complement_product_selected.push({
                                    id: product.id,
                                    name: product.name,
                                    prod_type: this.props.paramsBody.prod_type,
                                    description: `${product.qtd}x ${product.name}`,
                                    qtd: product.qtd,
                                    price: product.price
                                })
                            }
                        }


                    })

                    // console.log('CART 1 ',  cart_obg)
                    cart_obg.items.push({
                        id: id,
                        name: name,
                        prod_type: this.props.paramsBody.prod_type,
                        description: `${this.state.qtd}x ${name}`,
                        qtd: this.state.qtd,
                        price: this.props.paramsBody.price,
                        complements: complement_product_selected,
                        //total: (parseInt(product.qtd) * parseFloat(product.price))
                    })

                })
            } else {
                cart_obg.items.push({
                    id: id,
                    name: name,
                    prod_type: this.props.paramsBody.prod_type,
                    description: `${this.state.qtd}x ${name}`,
                    qtd: this.state.qtd,
                    price: this.props.paramsBody.price,
                    complements: [],
                    //total: (parseInt(product.qtd) * parseFloat(product.price))
                })
            }

            //  console.log('CART 2 ',  cart_obg)
            //cart_obg.qtd = this.state.qtd
            let searchable_group_cart = false
            if (cart.length > 0) {
                cart.map((group, index) => {
                    if (parseInt(group.idGroup) === parseInt(cart_obg.idGroup)) {
                        cart[index] = cart_obg
                        //console.log('CART GROUP ', cart[index])
                        searchable_group_cart = true
                        return
                    }
                })

            }
            if (!searchable_group_cart) {
                cart.push(cart_obg)
            }

            // atualiza o carrinho na sessão do servidor.
            if (window.localStorage.hasOwnProperty('pr_key')) {
                const key = window.localStorage.getItem('pr_key');
                this.props.createOrUpdateOrder({
                    ACTION_FACTORY: 'ACTION_ADD_OR_UPDATE_PRODUCT',
                    key: key,
                    product: cart_obg
                })
            }
            // cart.push(cart_obg)
            // console.log('CART ', cart)
            window.localStorage.setItem("cart", btoa(JSON.stringify(cart)))
            window.dispatchEvent(new Event("storage"))
            this.props.toggleModal()
        }
    }

    handleAdd(idx1, idx2) {
        this.props.row.complements.forEach(element => {
            element.products.forEach(element1 => {
                element1.isSelected = false

            })
        })

        this.props.row.complements[idx1].products[idx2]['qtd'] = parseInt(this.props.row.complements[idx1].products[idx2].qtd ? this.props.row.complements[idx1].products[idx2].qtd : 0) + 1
        this.props.row.complements[idx1].products[idx2]['isSelected'] = !this.props.row.complements[idx1].products[idx2]['isSelected']

        if (parseInt(this.props.row.complements[idx1].products[idx2]['mandatory']) === 1) {
            this.setState({ countSelected: (this.state.countSelected + 1), refresh: true, complement_selected: !this.state.complement_selected })

        } else {
            this.setState({ countSelected: (this.state.countSelected + 1), refresh: true })

        }

    }

    handleSub(idx1, idx2) {
        this.props.row.complements.forEach(element => {
            element.products.forEach(element1 => {
                element1.isSelected = false
            })
        })
        if (parseInt(this.props.row.complements[idx1].products[idx2].qtd) > 1) {
            this.props.row.complements[idx1].products[idx2].qtd = parseInt(this.props.row.complements[idx1].products[idx2].qtd) - 1
            if (this.state.countSelected > 1) {
                this.setState({ countSelected: (this.state.countSelected - 1), refresh: true, complement_selected: !this.state.complement_selected })
            }

        } else {
            this.props.row.complements[idx1].products[idx2].qtd = 0
            this.setState({ countSelected: 0, refresh: true, complement_selected: false })
        }
        this.props.row.complements[idx1].products[idx2]['isSelected'] = !this.props.row.complements[idx1].products[idx2]['isSelected']


    }

    handleFields(event) {
        const { name, value } = event.target
        this.setState({ observation: value })
    }

    handleRadioButtomProducts(event, idx1) {
        //    console.log('SELECIONOU TARGET ',  event.target.value)
        //  console.log('SELECIONOU IDEX  ', idx1)
        this.props.row.complements.forEach(element => {
            element.products.forEach(element1 => {
                element1.isSelected = false

            })
        })
        /* this.props.row.complements[idx1].products.forEach(element => {
             element.isSelected = false
             //element.qtd = 0
         })*/
        this.props.row.complements[idx1].products[parseInt(event.target.value)]['isSelected'] = !this.props.row.complements[idx1].products[parseInt(event.target.value)]['isSelected']
        this.props.row.complements[idx1].products[parseInt(event.target.value)]['qtd'] = 1

        // console.log('SELECIONOU PROD  ', this.props.row.complements[idx1].products[parseInt(event.target.value)])
        if (parseInt(this.props.row.complements[idx1].products[parseInt(event.target.value)]['mandatory']) === 1) {
            this.setState({ refresh: true, complement_selected: !this.state.complement_selected })
        } else {
            this.setState({ refresh: true })
        }


    }

    handleSumValueItems() {
        const { complements } = this.props.row
        let valueTot = this.props.paramsBody.price
        //console.log('row.price ', this.props.paramsBody.price)

        complements.forEach(element => {
            element.products.forEach(el => {
                //  console.log('max_select 1 ', element.max_select)
                if (parseInt(element.max_select) === 1) {
                    if (el.isSelected && parseFloat(el.price) > 0.0) {
                        valueTot += parseFloat(el.price)
                    }
                }
                if (parseInt(element.max_select) > 1) {
                    //console.log('max_select 2 ', element.max_select)
                    if (parseInt(el.qtd) > 0) {
                        if (parseFloat(el.price) > 0.0) {
                            valueTot += (parseFloat(el.price) * parseInt(el.qtd))
                        }
                    }
                }

            })

        })

        valueTot *= this.state.qtd

        //console.log('soma ', valueTot)
        this.setState({ valueTot: valueTot, refresh: true })
        this.forceUpdate()
    }

    add() {
        this.setState({ qtd: (this.state.qtd + 1), refresh: true, product_selected: !this.state.product_selected })
    }

    sub() {
        if (this.state.qtd > 0) {
            this.setState({ qtd: (this.state.qtd - 1), refresh: true })
        } else {
            this.setState({ qtd: 0, refresh: true, product_selected: false })
        }
    }

    render() {
        const { valueTot, refresh } = this.state
        const { row } = this.props

        if (refresh) {
            this.handleSumValueItems()
            this.setState({ refresh: false })
        }

        return (
            <React.Fragment>
                {
                    this.props.loading
                        ?
                        <div style={{
                            height: '700px !important',
                            marginBottom: '400px'

                        }}>
                            <UILoadingAnim
                                url_anim={7} />
                        </div>
                        :
                        <Row>
                            <Col sm={6}>
                                <div className="mx-3">
                                    <ImageURI
                                        style_div="img-square-wrapper text-center mb-3"
                                        style_img="rounded thumbnail img-responsive img-card-desc"
                                        url={this.props.paramsBody.url_image}
                                        alt="" />
                                    {/*<div className="img-square-wrapper text-center">
                                        <img className="rounded thumbnail img-responsive img-card-desc"
                                            src={this.props.paramsBody.url_image ? this.props.paramsBody.url_image : PlaceHolder}
                                            alt=" " />
                                    </div>*/}
                                </div>
                            </Col>
                            <Col sm={6}>
                                <PerfectScrollbar style={{ height: this.props.size_height }}>
                                    {

                                        (typeof this.props.paramsBody.nameGroup !== 'undefined') &&
                                        <>
                                            <h5 className=" text-start card-title text-muted">{this.props.paramsBody.nameGroup}</h5>
                                            <h5 className=" text-start card-title font-got-blk-14-400h text-dark mt-2">{row.name}</h5>
                                            <p><small>{row.description}</small></p>

                                            {
                                                parseInt(this.props.paramsBody.price_type) === 0 &&
                                                <p className="text-start mt-2 card-text font-bl-12-400h text-success mt-2">
                                                    <b>{
                                                        <NumberFormat value={this.props.paramsBody.price} />}</b></p>
                                            }
                                        </>
                                    }

                                    {
                                        row &&
                                        row.complements && row.complements.length > 0 &&
                                        row.complements.map((item, index1) => {

                                            return (
                                                <div key={index1}>
                                                    <Row className="bg-gray-light mt-2">
                                                        <Col sm={6}>
                                                            <h4 className="card-title font-bl-13-700h">Escolha
                                                                um {item.name}</h4>
                                                            <small>Escolha {item.min_select} Opção </small>
                                                        </Col>
                                                        <Col sm={6} className="text-end pr-2">
                                                            <span
                                                                className="badge bg-dark mt-2 px-2">{item.mandatory === 1 ? 'OBRIGATÓRIO' : 'OPCIONAL'}</span>
                                                        </Col>
                                                    </Row>
                                                    <div className="modal-body"
                                                        onChange={(e) => (parseInt(row.complements[index1].max_select) === 1 ? this.handleRadioButtomProducts(e, index1) : {})}>

                                                        <Row >
                                                            {
                                                                item.products && item.products.length > 0 &&
                                                                item.products.map((itemProd, index2, item) => {
                                                                    return (
                                                                        <div key={index2}>
                                                                            {//
                                                                                parseInt(row.complements[index1].max_select) === 1 ?
                                                                                    <UICardViewComplementSingle
                                                                                        key={index2}
                                                                                        name={itemProd.name}
                                                                                        description={itemProd.description}
                                                                                        price={itemProd.price}
                                                                                        index={index2}
                                                                                    />

                                                                                    :

                                                                                    <UICardViewComplementMultiple
                                                                                        key={index2}
                                                                                        name={itemProd.name}
                                                                                        description={itemProd.description}
                                                                                        price={itemProd.price}
                                                                                        index2={index2}
                                                                                        index1={index1}
                                                                                        disable={false}// implementar regra futuramente this.state.countSelected === parseInt(item.max_select)}
                                                                                        qtd={this.props.row.complements[index1].products[index2].qtd ? this.props.row.complements[index1].products[index2].qtd : 0}
                                                                                        add={this.handleAdd}
                                                                                        sub={this.handleSub}
                                                                                    />
                                                                            }
                                                                        </div>
                                                                    )
                                                                })


                                                            }
                                                        </Row>

                                                    </div>
                                                </div>
                                            )
                                        })
                                    }

                                    <div className="modal-body">
                                        <Row>
                                            <Col sm={12}>
                                                <div className="form-group  mb-5 mg-bottom-bar">
                                                    <label htmlFor=""
                                                        className="control-label">Alguma Observação?</label>
                                                    <textarea className="form-control"
                                                        id="observation"
                                                        rows="3"
                                                        onChange={this.handleFields}
                                                        value={this.state.observation} />

                                                </div>
                                                <Row>
                                                    <Col>
                                                        <div
                                                            className="rounded border border-dark text-center">
                                                            <a onClick={this.sub}
                                                                className="p-2"><i className="bx bx-message-alt-minus"></i></a>
                                                            <label
                                                                className="p-1">{this.state.qtd}</label>
                                                            <a onClick={this.add}
                                                                className="p-2"><i className="bx bx-plus"></i></a>
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        <button className="btn btn-lg  btn-dark w-100" onClick={() => this.handleAddToCart()}>
                                                            <h4 className="text-center text-light card-title font-bl-13-700h">
                                                                + ADD {<NumberFormat value={valueTot} />}
                                                            </h4>
                                                        </button>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </PerfectScrollbar>

                            </Col>
                            <Col sm={12}>

                            </Col>
                        </Row>

                }
            </React.Fragment>
        )
    }
}

ModalContentType2.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
    refresh: PropTypes.any,
    type: PropTypes.string,
    loading: PropTypes.any,
    toggleModal: PropTypes.func,
    paramsBody: PropTypes.any,
    size_height: PropTypes.any,
    row: PropTypes.any,
    findProductsByCategory: PropTypes.func,
    apiFindProductsByCategoryError: PropTypes.func,
    createOrUpdateOrder: PropTypes.func,

}
const mapStateToProps = state => {
    const {
        loading,
        row
    } = state.ProductsCategory
    return {
        loading,
        row
    }
}
export default withTranslation()(withRouter(connect(mapStateToProps, {
    apiFindProductsByCategoryError,
    findProductsByCategory,
    createOrUpdateOrder
})(
    (ModalContentType2)
)))
