import React, { Component } from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { apiFindHomeProfileCompanyError, findHomeProfileCompany } from "../../../store/company/actions"
import HomeMobile from "../Home/Index"
import HomePC from "../../Desktop/index"
import UILoadingAnim from "../Components/UILoadingAnim"

import { BrowserView, MobileView } from 'react-device-detect';
import { PROFILE_STORAGE_SERVICE_LOCAL } from "logic/keyStorageAPI"
import { checkInStorageCart } from "logic/storageAPI"


class Index extends Component {
    constructor(props) {
        super(props)
        this.state = {
            go_home: false
        }
    }


    componentDidMount() {
        this.props.findHomeProfileCompany('pizzaria-fontanella-ltda')
        this.setState({ go_home: checkInStorageCart(PROFILE_STORAGE_SERVICE_LOCAL.intent) })
        window.addEventListener('storage', () => {

            if (checkInStorageCart(PROFILE_STORAGE_SERVICE_LOCAL.intent)) {
                this.setState({ go_home: checkInStorageCart(PROFILE_STORAGE_SERVICE_LOCAL.intent) })
            }
        });
    }

    render() {

        return (
            <React.Fragment className="text-center text-light">
                {
                    this.state.go_home
                        ?
                        <>
                            <BrowserView>
                                {
                                    window.innerWidth > 800
                                        ?
                                        <HomePC />
                                        :
                                        <HomeMobile />
                                }

                            </BrowserView>
                            <MobileView>
                                <HomeMobile />
                            </MobileView>
                        </>

                        :
                        <UILoadingAnim opening={true} url_anim={9} />


                }

            </React.Fragment>
        )
    }
}

Index.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
    loading: PropTypes.bool,
    error: PropTypes.any,
    message: PropTypes.any,
    result: PropTypes.object,
    apiFindHomeProfileCompanyError: PropTypes.func,
    findHomeProfileCompany: PropTypes.func,
    pageSelector: PropTypes.func,
    history: PropTypes.object,


}
const mapStateToProps = state => {
    const {
        loading,
        error,
        message,
        result
    } = state.ProfileCompany
    return {
        loading,
        error,
        message,
        result
    }
}
export default withTranslation()(withRouter(connect(mapStateToProps, {
    findHomeProfileCompany,
    apiFindHomeProfileCompanyError
})(
    (Index)
)))
